import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import errorImg from '../../../assets/error.png';
import { Link, useNavigate } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Loader from '../../../components/loader';
import communication from '../../../communication';

import { formatTwoDecimals } from '../../../functions/format';
import { ObjectKeys } from '@/types/objectKeys';
// import ErrorDescription from './errorDescription';

const PaymentError: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const navigate = useNavigate();

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const urlParams = new URLSearchParams(window.location.search);

    const [user_id, setUser_id] = useState<string>('');

    const [cart, setCart] = useState<any>({});
    const [failed, setFailed] = useState<boolean>(false);

    const params: {
        [key: string]: string | string[];
    } = {};
    urlParams.forEach((value, key) => {
        params[key] = value;
    });

    const transactionDetails = {
        cart_id: urlParams.get('ShoppingCartID') || '',
        order_id: urlParams.get('WsPayOrderId') || '',
        date_time: urlParams.get('DateTime') || '',
        approval_code: urlParams.get('ApprovalCode') || null,
        amount: urlParams.get('Amount') || '',
    };

    const handleUserPaymentResponse = async (id: string): Promise<void> => {
        setLoaderVisible(true);
        communication
            .sendPaymentResponse({ ...params, userId: id })
            .then((response: any) => {
                if (response.status === 200) {
                    setLoaderVisible(false);
                }
            })
            .catch((error: any) => {
                console.error('Payment response failed', error);
                setLoaderVisible(false);
            });
    };

    const handleGuestPaymentResponse = async (): Promise<void> => {
        setLoaderVisible(true);
    };

    useEffect(() => {
        if (Object.keys(params).length > 0) {
            getCart(transactionDetails.cart_id)
                .then((cart_res: ObjectKeys) => {
                    if (cart_res.status === 'completed') {
                        navigate(`/payment-success/?ShoppingCartID=${transactionDetails.cart_id}`);
                    } else {
                        if (cart_res.user_id) {
                            setUser_id(cart_res.user_id);
                            handleUserPaymentResponse(user_id);
                        } else {
                            handleGuestPaymentResponse();
                        }
                    }
                })
                .catch((err: any) => {
                    console.error('err', err);
                });
        }
    }, []);

    const transformDateTimeFormat = (dateString: string): string => {
        // Parsing the string into date components
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        const hour = dateString.slice(8, 10);
        const minute = dateString.slice(10, 12);
        const second = dateString.slice(12, 14);

        // Creating a new Date object

        // Formatting the date and time
        const formattedDate = `${day}.${month}.${year}.`;
        const formattedTime = `${hour}:${minute}:${second}`;

        return `${formattedDate} ${formattedTime}`;
    };

    const getCart = async (id: string): Promise<any> => {
        setLoaderVisible(true);
        try {
            const res: ObjectKeys = await communication.getCartById(id);
            if (res.data.data.status === 'completed') {
                setLoaderVisible(false);
                return res.data.data;
            } else {
                setFailed(true);
                setLoaderVisible(false);
                setCart(res.data.data);
                return res.data.data;
            }
        } catch (err: any) {
            setLoaderVisible(false);
            console.error('err', err);
            throw err;
        }
    };

    return (
        <>
            {loaderVisible && <Loader />}
            {cart && failed && (
                <Container>
                    <ErrorText>{t('pages.payments.error.title').text}</ErrorText>
                    <ErrorImage src={errorImg} alt="error" />
                    <InfoText>{t('pages.payments.error.description').text}</InfoText>
                    {transactionDetails ? (
                        <div className="params">
                            <h3>{t('pages.payments.error.details').text}</h3>
                            <div className="param">
                                <p>{t('modal.error.title').text}:</p>
                                <p> {params.ErrorMessage}</p>
                            </div>
                            <div className="error">
                                <p>{t('pages.payments.error.subDetails').text}</p>
                            </div>
                            <div className="param">
                                <p>{t('pages.payments.success.order').text}:</p>
                                <p>{transactionDetails.cart_id}</p>
                            </div>
                            <div className="param">
                                <p>{t('pages.payments.success.dateTime').text}:</p>
                                <p>{transformDateTimeFormat(transactionDetails.date_time)}</p>
                            </div>
                            <div className="param">
                                <p>{t('pages.payments.success.amount').text}:</p>
                                <p>{formatTwoDecimals(transactionDetails.amount)} RSD</p>
                            </div>
                        </div>
                    ) : null}

                    <Link to="/settings/subscription/1">
                        <span className="link">{t('pages.payments.error.button').text}</span>
                    </Link>
                </Container>
            )}
        </>
    );
};

// Styled components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    .link {
        font-size: 1rem;
        cursor: pointer;
    }
    .params {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .error {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            color: var(--danger);
            p {
                font-size: 1rem;
            }
        }
        .param {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            p {
                font-size: 1rem;
            }
        }
    }
`;

const ErrorText = styled.h1`
    font-size: 2rem;
    color: var(--danger);
    margin-bottom: 20px;
`;

const ErrorImage = styled.img`
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
`;

const InfoText = styled.p`
    font-size: 1rem;
    margin-bottom: 20px;
`;

// const Link = styled.a`
//     color: blue;
//     text-decoration: underline;
//     cursor: pointer;
// `;

export default PaymentError;
