import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import TextArea from '../../../../../components/input/textarea';
import Input, { InputComponentType } from '../../../../../components/input';
import colors from '../../../../../global/colors';
import Button, { ButtonVariant } from '../../../../../components/button';
import Checkbox from '../../../../../components/checkbox';
import Modal from '../../../../../components/modal';
import ConfirmModal from '../../../../../components/confirmModal';
import { ObjectKeys } from '@/types/objectKeys';
import ErrorModal from '../../../../../components/errorModal';
import useTranslations from '../../../../../hooks/useTranslation';
import useLanguageStore from '../../../../../store/language';

interface ServiceFormProps {
    initialData?: ObjectKeys;
    onSave: (data: any, id?: string) => void;
    onCancel: () => void;
    isOpen: boolean;
    type: string;
    id?: string;
}

const ServiceForm: FunctionComponent<ServiceFormProps> = ({ initialData, onSave, onCancel, isOpen, type, id }) => {
    const [renderKey, setRenderKey] = useState(0);
    const [showError, setShowError] = useState(false);
    const [questionModal, setQuestionModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [formData, setFormData] = useState({
        name_rs: initialData ? initialData.name_rs : '',
        name_en: initialData ? initialData.name_en : '',
        name_ru: initialData ? initialData.name_ru : '',
        description_rs: initialData ? initialData.description_rs : '',
        description_en: initialData ? initialData.description_en : '',
        description_ru: initialData ? initialData.description_ru : '',
        price: initialData ? initialData.price : 0,
        is_courier: initialData ? initialData.is_courier : false,
        is_consultation: initialData ? initialData.is_consultation : false,
        questions: [
            {
                question_1_rs: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_1_rs : null,
                question_1_en: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_1_en : null,
                question_1_ru: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_1_ru : null,
                question_2_rs: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_2_rs : null,
                question_2_en: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_2_en : null,
                question_2_ru: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_2_ru : null,
                question_3_rs: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_3_rs : null,
                question_3_en: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_3_en : null,
                question_3_ru: initialData?.questions?.length > 0 ? initialData?.questions[0]?.question_3_ru : null,
            },
        ],
    });

    const [expandedSections, setExpandedSections] = useState<
        Record<'question_1' | 'question_2' | 'question_3', boolean>
    >({
        question_1:
            !!formData.questions[0].question_1_rs ||
            !!formData.questions[0].question_1_en ||
            !!formData.questions[0].question_1_ru,
        question_2:
            !!formData.questions[0].question_2_rs ||
            !!formData.questions[0].question_2_en ||
            !!formData.questions[0].question_2_ru,
        question_3:
            !!formData.questions[0].question_3_rs ||
            !!formData.questions[0].question_3_en ||
            !!formData.questions[0].question_3_ru,
    });

    const [requiredValidation, setRequiredValidation] = useState<boolean>(false);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const toggleSection = (key: 'question_1' | 'question_2' | 'question_3') => {
        setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const questionKeys: Array<'question_1' | 'question_2' | 'question_3'> = ['question_1', 'question_2', 'question_3'];

    const resetForm = (): void => {
        setFormData({
            name_rs: '',
            name_en: '',
            name_ru: '',
            description_rs: '',
            description_en: '',
            description_ru: '',
            price: 0,
            is_courier: false,
            is_consultation: false,
            questions: [
                {
                    question_1_rs: null,
                    question_1_en: null,
                    question_1_ru: null,
                    question_2_rs: null,
                    question_2_en: null,
                    question_2_ru: null,
                    question_3_rs: null,
                    question_3_en: null,
                    question_3_ru: null,
                },
            ],
        });
        setShowError(false);
        setRenderKey(renderKey + 1);
    };

    const handlevalidation = (): void => {
        if (
            formData.name_rs === '' ||
            formData.name_en === '' ||
            formData.name_ru === '' ||
            formData.description_rs === '' ||
            formData.description_en === '' ||
            formData.description_ru === '' ||
            formData.price === 0 ||
            formData.price === undefined
        ) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            if (type === 'create') {
                const areQuestionsInvalid = handleQuestionValidation();
                if (areQuestionsInvalid) {
                    return;
                }
                setModalMessage('Da li ste sigurni da želite da dodate uslugu?');
                setQuestionModal(true);
            } else {
                setModalMessage('Da li ste sigurni da želite da izmenite uslugu?');
                setQuestionModal(true);
            }
        }
    };

    const handleQuestionValidation = () => {
        let shouldTriggerModal = false;

        formData.questions.forEach((question) => {
            if (
                (question.question_1_rs || question.question_1_en || question.question_1_ru) &&
                (!question.question_1_rs || !question.question_1_en || !question.question_1_ru)
            ) {
                shouldTriggerModal = true;
            }

            if (
                (question.question_2_rs || question.question_2_en || question.question_2_ru) &&
                (!question.question_2_rs || !question.question_2_en || !question.question_2_ru)
            ) {
                shouldTriggerModal = true;
            }

            if (
                (question.question_3_rs || question.question_3_en || question.question_3_ru) &&
                (!question.question_3_rs || !question.question_3_en || !question.question_3_ru)
            ) {
                shouldTriggerModal = true;
            }
        });

        if (shouldTriggerModal) {
            setRequiredValidation(true);
        }

        return shouldTriggerModal;
    };

    return (
        <>
            {questionModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setQuestionModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setQuestionModal(false);
                            onSave(formData, id);
                            resetForm();
                        }}
                        close={() => {
                            setQuestionModal(false);
                        }}
                        active={questionModal}
                        message={modalMessage}
                    />
                </Modal>
            )}
            {requiredValidation && (
                <Modal modalVisible={requiredValidation} closeModal={() => setRequiredValidation(!requiredValidation)}>
                    <ErrorModal
                        t={t}
                        errorMessage="Pitanja vezana za uslugu su opciona, medjutim, ukoliko odlučite da unesete pitanje, to morate uraditi za sva 3 jezika."
                        setOpenModal={setRequiredValidation}
                    />
                </Modal>
            )}
            <Form className={isOpen ? 'open' : ''}>
                <div className="type">
                    <h2>{type === 'create' ? 'Dodaj uslugu' : 'Izmeni uslugu'}</h2>
                </div>
                <div>
                    <div>
                        <p className="bold">Naziv usluge:</p>
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_rs}
                            label={'Srpski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_rs: value,
                                });
                            }}
                            className={'input'}
                            maxLength={190}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_en}
                            label={'Engleski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_en: value,
                                });
                            }}
                            className={'input'}
                            maxLength={190}
                        />
                        <Input
                            type={InputComponentType.Text}
                            value={formData.name_ru}
                            label={'Ruski'}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    name_ru: value,
                                });
                            }}
                            className={'input'}
                            maxLength={190}
                        />
                    </div>
                    <div>
                        <p className="bold">Cena (RSD):</p>
                        <Input
                            type={InputComponentType.Amount}
                            value={formData.price.toString()}
                            onChange={(value: any) => {
                                setFormData({
                                    ...formData,
                                    price: value,
                                });
                            }}
                            className={'input'}
                        />
                    </div>
                    <div>
                        <p className="bold">Opis:</p>
                        <TextArea
                            label={'Srpski'}
                            value={formData.description_rs}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_rs: value,
                                });
                            }}
                            height={'50px'}
                            maxLength={190}
                        />
                        <TextArea
                            label={'Engleski'}
                            value={formData.description_en}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_en: value,
                                });
                            }}
                            height={'50px'}
                            maxLength={190}
                        />
                        <TextArea
                            label={'Ruski'}
                            value={formData.description_ru}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    description_ru: value,
                                });
                            }}
                            height={'50px'}
                            maxLength={190}
                        />
                    </div>
                    <div>
                        <div className="courier">
                            <p>Kurirska usluga:</p>
                            <Checkbox
                                defaultChecked={formData.is_courier}
                                onChange={(e: boolean) => {
                                    setFormData({
                                        ...formData,
                                        is_courier: e,
                                        is_consultation: e ? false : formData.is_consultation,
                                    });
                                }}
                            />
                        </div>
                        <div className="courier">
                            <p>Konsultacije:</p>
                            <Checkbox
                                defaultChecked={formData.is_consultation}
                                onChange={(e: boolean) => {
                                    setFormData({
                                        ...formData,
                                        is_consultation: e,
                                        is_courier: e ? false : formData.is_courier,
                                    });
                                }}
                            />
                        </div>
                        <p
                            style={{
                                margin: '20px 0 10px 0',
                                borderBottom: '1px solid var(--purple)',
                                paddingBottom: '20px',
                            }}
                        >
                            Dodaj pitanje uz uslugu:
                        </p>
                        <div>
                            {questionKeys.map((questionKey, index) => (
                                <div key={questionKey} className="question-section">
                                    {!expandedSections[questionKey] ? (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
                                                <p className="bold">{`Pitanje ${index + 1}:`}</p>
                                                <button
                                                    type="button"
                                                    className="expand-button"
                                                    onClick={() => toggleSection(questionKey)}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="question-header">
                                                <p className="bold">{`Pitanje ${index + 1}:`}</p>
                                                <button
                                                    type="button"
                                                    className="collapse-button"
                                                    onClick={() => toggleSection(questionKey)}
                                                >
                                                    -
                                                </button>
                                            </div>
                                            <TextArea
                                                label={'Srpski'}
                                                value={formData.questions[0][`${questionKey}_rs`]}
                                                onChange={(value: string) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        questions: prev.questions.map((q, i) =>
                                                            i === 0 ? { ...q, [`${questionKey}_rs`]: value } : q,
                                                        ),
                                                    }));
                                                }}
                                                height={'50px'}
                                                maxLength={190}
                                            />
                                            <TextArea
                                                label={'Engleski'}
                                                value={formData.questions[0][`${questionKey}_en`]}
                                                onChange={(value: string) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        questions: prev.questions.map((q, i) =>
                                                            i === 0 ? { ...q, [`${questionKey}_en`]: value } : q,
                                                        ),
                                                    }));
                                                }}
                                                height={'50px'}
                                                maxLength={190}
                                            />
                                            <TextArea
                                                label={'Ruski'}
                                                value={formData.questions[0][`${questionKey}_ru`]}
                                                onChange={(value: string) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        questions: prev.questions.map((q, i) =>
                                                            i === 0 ? { ...q, [`${questionKey}_ru`]: value } : q,
                                                        ),
                                                    }));
                                                }}
                                                height={'50px'}
                                                maxLength={190}
                                            />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    {showError ? <span className="error">Neophodno je popuniti sva polja za unos usluge!</span> : null}
                </div>
                <div className="button">
                    <div className="button-2">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'60%'}
                            onClick={() => handlevalidation()}
                        >
                            <FontAwesomeIcon icon={faSave} className="fa-icon-save" />
                            Sačuvaj
                        </Button>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'60%'}
                            onClick={() => {
                                setShowError(false);
                                onCancel();
                            }}
                        >
                            <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon-save" />
                            Odustani
                        </Button>
                    </div>
                    <Button variant={ButtonVariant.solid} color={colors.danger} size={'20%'} onClick={resetForm}>
                        <FontAwesomeIcon icon={faTrash} className="fa-icon-save" />
                        Poništi unos
                    </Button>
                </div>
            </Form>
        </>
    );
};
const Form = styled.div`
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;

    &.open {
        border: 2px solid var(--purple);
        margin-top: 20px;
        padding: 20px;
        opacity: 1;
        max-height: 100%;
        min-height: 690px;
        .bold {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .button {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
        .button-2 {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        .fa-icon-save {
            margin-right: 10px;
            color: var(--white);
        }
        .error {
            color: var(--danger);
            font-size: 14px;
            margin-top: 10px;
            animation: blink 1.5s infinite;
        }
        @keyframes blink {
            50% {
                opacity: 0;
            }
        }
        .type {
            margin-bottom: 20px;
            h2 {
                font-size: 20px;
                font-weight: bold;
                color: var(--purple);
            }
            text-align: center;
        }
    }
    .courier {
        gap: 14px;
        display: flex;
        margin-top: 20px;
    }
    .collapse-button,
    .expand-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: var(--purple);
        margin-bottom: 10px;
        cursor: pointer;
    }

    .question-header {
        display: flex;
        align-items: center;
        gap: 20px;
        /* justify-content: space-between; */
        width: 100%;
    }

    .question-section {
        margin: 5px 0;
        /* border-top: 1px solid var(--purple); */
        border-bottom: 1px solid var(--purple);
    }
`;

export default ServiceForm;
