import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import NotificationCard from './notificationCard';
import Pagination from '../../../../components/pagination';
import Search from '../../../../components/input/search';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import { toast } from 'react-toastify';
import Loader from '../../../../components/loader';
import NotificationSkeleton from './notificationCard/skeleton';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { ObjectKeys } from '../../../../types/objectKeys';

import { useNavigate } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface NotificationCardProps {
    id: string;
    title_of_alert_rs: string;
    title_of_alert_en: string;
    title_of_alert_ru: string;
    description_rs: string;
    description_en: string;
    description_ru: string;
    updated_at: string;
    total_alerts: number;
    read_alerts: number;
    status: string;
}

export const NotificationsHistory = () => {
    const [notifications, setNotifications] = useState<NotificationCardProps[]>([]);

    const navigate = useNavigate();

    const [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
    const [searchDateTo, setSearchDateTo] = useState<Date | null>(null);

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [sendModalVisible, setSendModalVisible] = useState<boolean>(false);

    const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [notificationsData, setNotificationsData] = useState<ObjectKeys>([]);

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        search: 'type:admin',
        searchFields: 'type:like',
        searchJoin: 'and',
        orderBy: 'status;updated_at',
        sortedBy: 'asc;desc',
    });

    const handleEdit = (id: string): void => {
        navigate(`/admin/notifications/create/${id}`);
    };

    const handleDelete = (id: string): void => {
        setSelectedNotificationId(id);
        setDeleteModalVisible(true);
    };

    const handleSend = (id: string): void => {
        setSelectedNotificationId(id);
        setSendModalVisible(true);
    };

    const sendNotification = async (): Promise<void> => {
        setLoaderVisible(true);
        if (!selectedNotificationId) return;
        communication
            .sendNotification(selectedNotificationId)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    toast.success('Notifikacija je uspešno poslata.');
                    setSelectedNotificationId(null);
                    setSendModalVisible(false);
                    getAllNotifications();
                }
            })
            .catch((err: any) => {
                setLoaderVisible(false);
                toast.error(err.response.data.message);
            });
    };

    const deleteNotification = async (): Promise<void> => {
        setLoaderVisible(true);
        if (!selectedNotificationId) return;
        const res = await communication.deleteNotification(selectedNotificationId);
        if (res.status === 204) {
            setLoaderVisible(false);
            toast.success('Notifikacija je uspešno obrisana');
            setSelectedNotificationId(null);
            setDeleteModalVisible(false);
            getAllNotifications();
        } else {
            setLoaderVisible(false);
            setSelectedNotificationId(null);
            toast.error('Error in deleting notification');
        }
    };

    const handlePageClick = (pageSelected: number): void => {
        setParams({ ...params, page: pageSelected });
    };

    const getAllNotifications = async (): Promise<void> => {
        setLoaderVisible(false);
        setShowSkeleton(true);
        const res = await communication.getNotifications(params);
        if (res.status === 200) {
            setShowSkeleton(false);
            setLoaderVisible(false);
            setNotificationsData(res.data);
            setNotifications(res.data.data);
        } else {
            setShowSkeleton(false);
            setLoaderVisible(false);
            toast.error('Error in fetching notifications');
        }
    };

    const formatDateFromForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    const formatDateToForQuery = (dateString: Date | null): string => {
        if (!dateString) return '';
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1); // Add one day
        const year = date.getFullYear(); // Use local year
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Use local month
        const day = String(date.getDate()).padStart(2, '0'); // Use local day
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        getAllNotifications();
    }, [params]);

    const updateSearchParams = (fromDate: Date | null, toDate: Date | null): void => {
        if (fromDate && toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: `type:admin;created_at:${formatDateFromForQuery(fromDate)},${formatDateToForQuery(toDate)}`,
                searchFields: 'type:like;created_at:between',
            }));
        } else if (!fromDate && !toDate) {
            setParams((prevParams) => ({
                ...prevParams,
                page: 1,
                search: 'type:admin',
                searchFields: 'type:like',
            }));
        }
    };

    const searchByTerm = (term: string): void => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            setParams((prevParams) => {
                // Remove previous `title_of_alert_rs` and `type` filters from search
                const newSearch = [
                    ...prevParams.search
                        .split(';')
                        .filter((s) => !s.startsWith('title_of_alert_rs:') && !s.startsWith('type:')),
                    'type:admin',
                    `title_of_alert_rs:${term}`,
                ].join(';');

                // Remove previous `title_of_alert_rs` and `type` filters from searchFields
                const newSearchFields = [
                    ...prevParams.searchFields
                        .split(';')
                        .filter((s) => !s.startsWith('title_of_alert_rs:') && !s.startsWith('type:')),
                    'type:like',
                    'title_of_alert_rs:like',
                ].join(';');

                return {
                    ...prevParams,
                    search: newSearch,
                    searchFields: newSearchFields,
                };
            });
        }, 500);
    };

    return (
        <>
            {loaderVisible && <Loader />}
            {sendModalVisible && (
                <Modal modalVisible={sendModalVisible} closeModal={() => setSendModalVisible(false)}>
                    <ConfirmModal
                        action={async () => {
                            setSendModalVisible(false);
                            sendNotification();
                        }}
                        close={() => setSendModalVisible(false)}
                        active={sendModalVisible}
                        message={'Da li sigurno želiš da pošalješ notifikaciju korisnicima?'}
                    />
                </Modal>
            )}
            {deleteModalVisible && (
                <Modal modalVisible={deleteModalVisible} closeModal={() => setDeleteModalVisible(!deleteModalVisible)}>
                    <ConfirmModal
                        action={async () => {
                            setDeleteModalVisible(false);
                            deleteNotification();
                        }}
                        close={() => setDeleteModalVisible(false)}
                        active={deleteModalVisible}
                        message={'Da li sigurno želiš da obrišeš notifikaciju u pripremi?'}
                    />
                </Modal>
            )}
            <div>
                <Filters>
                    <div className="date-picker">
                        <label htmlFor="date-from">Od:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-from"
                            isClearable
                            date={searchDateFrom}
                            onChange={(date: Date) => {
                                setSearchDateFrom(date);
                                updateSearchParams(date, searchDateTo);
                            }}
                        />
                    </div>
                    <div className="date-picker">
                        <label htmlFor="date-to">Do:</label>
                        <Input
                            type={InputComponentType.Date}
                            id="date-to"
                            isClearable
                            date={searchDateTo}
                            onChange={(date: Date) => {
                                setSearchDateTo(date);
                                updateSearchParams(searchDateFrom, date);
                            }}
                        />
                    </div>
                    <div className="filter-bottom">
                        <div className="filter-search">
                            <Search
                                placeholder={'Pretraži po naslovu'}
                                onChange={(value: string) => {
                                    searchByTerm(value);
                                }}
                                noteColor={'#fff'}
                                className="select-search-filter"
                            />
                        </div>
                    </div>
                </Filters>
                <ListContainer>
                    {showSkeleton ? (
                        Array.from({ length: 10 }).map((_, index) => <NotificationSkeleton key={index} />)
                    ) : (
                        <>
                            {notifications.length > 0 && (
                                <>
                                    {notifications.map((service: NotificationCardProps) => (
                                        <NotificationCard
                                            key={service.id}
                                            t={t}
                                            service={service}
                                            onEdit={handleEdit}
                                            onDelete={handleDelete}
                                            onSend={handleSend}
                                        />
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </ListContainer>
                {notificationsData?.data?.length > 0 && (
                    <Pagination
                        pageCount={notificationsData.meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => {
                            handlePageClick(e.selected + 1);
                        }}
                        nextLabel={`${
                            notificationsData.meta?.pagination?.current_page ===
                            notificationsData.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${notificationsData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={notificationsData.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};

const ListContainer = styled.div`
    padding-bottom: 20px;
    .toolkit {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        p {
            margin-left: 10px;
            cursor: pointer;
            &:hover {
                color: var(--purple);
            }
        }
    }
    .items {
        display: flex;
        justify-content: space-between;
    }
    .items-price {
        display: flex;
        justify-content: space-between;
        margin-left: 200px;
    }
    .items-action {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
`;
const Filters = styled.div`
    display: flex;
    gap: 2rem;
    width: 100%;
    padding: 20px 0;

    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .filter-search {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: inline-block;
        }
    }
    .date-picker {
        display: flex;
        align-items: center;
        gap: 1rem;
        .datepicker-input-wrapper {
            background: var(--white);
            border: 1px solid var(--border-color);
            margin-top: 5px;
            svg {
                margin-right: 5px;
            }
        }
    }
`;
