import {
    faEnvelope,
    faEye,
    faPlus,
    faPrint,
    faSave,
    faTrashCan,
    faA,
    faDownload,
    faFile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../../../components/button';
import colors from '../../../../../global/colors';
import { Header, MenuButton } from '../../style';
import BackLink from '../../../../../components/backLink';
import { ObjectKeys } from '../../../../../types/objectKeys';

interface Props {
    t: Function;
    pathname: string;
    setPreviewModal: Function;
    setSendModal: Function;
    setMenuVisible: Function;
    menuVisible: boolean;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    changeHappened: boolean;
    handleInvoiceUpdate: Function;
    invoice: ObjectKeys;
    newInvoice?: boolean;
    title?: string;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
    handleCreateAdvanceFromProforma?: Function;
    handleChangeHappened?: Function;
    handleViewInvoicePDF?: Function;
    issueInvoice?: Function;
}

const WebHeader: FunctionComponent<Props> = ({
    t,
    setPreviewModal,
    setSendModal,
    setMenuVisible,
    menuVisible,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    invoice,
    newInvoice,
    title,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
    handleCreateAdvanceFromProforma,
    handleViewInvoicePDF,
    issueInvoice,
}) => {
    const role = localStorage.getItem('role');
    const navigate = useNavigate();

    return (
        <>
            <Header>
                <div className="left">
                    <BackLink
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                    <h1>
                        {(newInvoice &&
                            (title === 'invoice'
                                ? t('pages.newInvoice.title').text
                                : title === 'proforma'
                                ? t('pages.newProforma.title').text
                                : t('pages.newAdvanceInvoice.title').text)) ||
                            (title === 'invoice'
                                ? t('pages.editInvoice.title').text
                                : title === 'proforma'
                                ? t('pages.editProforma.title').text
                                : t('pages.editAdvanceInvoice.title').text)}
                    </h1>
                </div>
                <div className="right">
                    {role !== 'admin' ? (
                        <>
                            <Button
                                onClick={() => handleDownloadInvoice()}
                                variant={ButtonVariant.outlined}
                                color={colors.lightBlue}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.lightBlue} icon={faDownload} />
                                {t('pages.invoiceCard.dropdownItems.download').text}
                            </Button>
                            <Button
                                onClick={() => setSendModal(true)}
                                variant={ButtonVariant.solid}
                                color={colors.blue}
                                icon={true}
                                size={'auto'}
                                className="btn-send"
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                                {t('pages.editInvoice.send').text}
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                    <Button
                        onClick={() => setMenuVisible(!menuVisible)}
                        variant={ButtonVariant.outlined}
                        color={colors.purple}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPlus} />
                    </Button>
                    {menuVisible && (
                        <MenuButton>
                            <Button
                                onClick={() => handleUpdateInvoice()}
                                variant={ButtonVariant.solid}
                                color={colors.green}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                                {t('pages.editInvoice.save').text}
                            </Button>
                            <Button
                                onClick={() => handleViewInvoicePDF && handleViewInvoicePDF()}
                                variant={ButtonVariant.solid}
                                color={colors.gray}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPrint} />
                                <span>{t('pages.editInvoice.print').text}</span>
                            </Button>
                            {invoice?.status === 'open' && invoice.object !== 'Quotation' && (
                                <Button
                                    onClick={() => issueInvoice && issueInvoice()}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    icon={true}
                                    size={'auto'}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faFile} />
                                    <span>{t('buttons.issued').text}</span>
                                </Button>
                            )}
                            {title === 'proforma' && !newInvoice ? (
                                <>
                                    <Button
                                        onClick={() =>
                                            handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()
                                        }
                                        variant={ButtonVariant.solid}
                                        icon={true}
                                        color={colors.green}
                                        size={'auto'}
                                    >
                                        <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                        {t('pages.invoiceCard.dropdownItems.invoice').text}
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleCreateAdvanceFromProforma && handleCreateAdvanceFromProforma()
                                        }
                                        variant={ButtonVariant.solid}
                                        icon={true}
                                        color={colors.brown}
                                        size={'auto'}
                                    >
                                        <FontAwesomeIcon className="icon" color={colors.white} icon={faA} />
                                        {t('pages.advanceInvoices.single').text}
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                            {role !== 'admin' ? (
                                <Button
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => setPreviewModal(true)}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                                    {t('pages.editInvoice.preview').text}
                                </Button>
                            ) : (
                                <></>
                            )}
                            {invoice?.id ? (
                                <Button
                                    variant={ButtonVariant.outlined}
                                    color={colors.danger}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => setDeleteInvoiceModal(true)}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                    {t('pages.editInvoice.delete').text}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </MenuButton>
                    )}
                </div>
            </Header>
        </>
    );
};

export default WebHeader;
