import styled from 'styled-components';
import { NotificationsController } from './NotificationsController';
import { UserSettingsController } from './UserSettingsController';
import DarkThemeButton from '../themeToggleButton';
import { useRef, useState } from 'react';
import useTheme from '../../hooks/useTheme';
import useCartStore from '../../store/cart';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../global/colors';
import Modal from '../modal';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import ConfirmModal from '../confirmModal';
import { useNavigate } from 'react-router-dom';

export const TopBar = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const isDarkTheme = useTheme();
    const role = localStorage.getItem('role');
    const isAdmin = role === 'admin';
    const navbarRef = useRef<HTMLDivElement>(null);
    const [cartModal, setCardModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const { cartData } = useCartStore();

    const handleCart = () => {
        if (cartData?.items?.length === 0) {
            setCardModal(true);
        } else {
            navigate('/services/3');
        }
    };

    return (
        <>
            {cartModal && (
                <Modal modalVisible={cartModal} closeModal={() => setCardModal(!cartModal)}>
                    <ConfirmModal
                        action={() => {
                            setCardModal(false);
                            navigate('/services/1');
                        }}
                        close={() => setCardModal(false)}
                        active={cartModal}
                        message={t('pages.agency.subscription.startShoppingCart').text}
                        confirmText={t('pages.agency.subscription.goToServices').text}
                        rejectText={t('suggestion.close').text}
                    />
                </Modal>
            )}
            <TopBarContainer isDarkTheme={isDarkTheme} ref={navbarRef}>
                <DarkThemeButton />
                {!isAdmin ? (
                    <CartItemsContainer onClick={handleCart}>
                        <FontAwesomeIcon
                            className="shoppingCartIcon"
                            icon={faShoppingCart}
                            style={{ color: colors.purple }}
                        />
                        {cartData?.items?.length > 0 && <span className="cartItems">{cartData?.items?.length}</span>}
                    </CartItemsContainer>
                ) : null}

                {!isAdmin ? <NotificationsController /> : null}
                <UserSettingsController />
            </TopBarContainer>
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme: boolean;
}
const TopBarContainer = styled.div<DarkThemeProps>`
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--body-gray)')};
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    padding: 1.07rem 50px;
    height: 55px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .bell-icon {
        font-size: 1.8rem;
    }
    .user-icon {
        font-size: 1.6rem;
    }
`;

const CartItemsContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 90%;
    .shoppingCartIcon {
        height: 90%;
    }
    .cartItems {
        position: absolute;
        bottom: -2px;
        right: -5px;
        width: 15px;
        height: 15px;
        background-color: var(--toastify-color-error);
        color: white;
        font-size: 12px;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: translate(25%, 25%);
    }
`;
