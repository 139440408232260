import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';

const getAdvances = (setAdvances: any, setCoreAdvances: any, clientId: string, currency?: string): void => {
    communication
        .getInvoices({
            limit: '100',
            page: 1,
            search: `is_prepaid:1;client_id:${clientId};currency:${currency};status:sent,paid,partially,issued;`,
            searchFields: 'is_prepaid:=;client_id:=;currency:=;status:in;',
            searchJoin: 'and',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setAdvances(res.data.data);
                setCoreAdvances(res.data.data);
            }
        });
};
export default getAdvances;
