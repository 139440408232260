import { create } from 'zustand';
import communication from '../communication';
import { ObjectKeys } from '../types/objectKeys';
import useAgencyStore from '../store/agency';

interface CartState {
    cartData: ObjectKeys;
    servicesDiscount: number;
    servicesData: ObjectKeys[];
    servicesWithoutConsultation: ObjectKeys[];
    consultationServices: ObjectKeys[];
    packageServices: ObjectKeys[];
    updateCart: (cart: ObjectKeys) => Promise<void>;
    setServices: (services: ObjectKeys[]) => void;
    setServicesWithoutConsultation: (services: ObjectKeys[]) => void;
    setConsultationServices: (services: ObjectKeys[]) => void;
    setPackageServices: (services: ObjectKeys[]) => void;
    fetchCart: (cartId: string) => Promise<void>;
    cartValidation: (cart: ObjectKeys) => ObjectKeys | void;
    clearCart: () => void;
    reset: () => void;
    helperNote: Record<string, { question: string; answer: string }[]>;
    setHelperNote: (note: Record<string, { question: string; answer: string }[]>) => void;
}

const initialCartData = {
    user_id: '',
    status: '',
    total_price: 0,
    type: 'service',
    note: '',
    items: [],
    discount: 0,
    id: '',
};

const useCartStore = create<CartState>((set, get) => ({
    cartData: { ...initialCartData },
    servicesDiscount: 0,
    servicesData: [],
    servicesWithoutConsultation: [],
    consultationServices: [],
    packageServices: [],

    cartValidation: (cart) => {
        if (!cart) return;

        const { agency } = useAgencyStore.getState();
        const isTrialOrInactive = agency?.user?.data?.trial || !agency?.user?.data?.active;
        const servicesDiscount = isTrialOrInactive
            ? 0
            : agency?.user?.data?.payment_package?.data?.services_discount || 0;
        const freeServices = agency?.user?.data?.payment_package?.data?.services?.data || [];

        const freeServiceIds = new Set(freeServices.map((service: ObjectKeys) => service.id));

        const itemsArray = Array.isArray(cart.items?.data) ? cart.items.data : cart.items || [];

        const itemMap = new Map();

        itemsArray.forEach((item: any) => {
            const id = item.service?.data?.id ?? item.id;
            const isFree = freeServiceIds.has(id);
            if (itemMap.has(id)) {
                const existingItem = itemMap.get(id);
                existingItem.quantity += item.quantity;
            } else {
                itemMap.set(id, {
                    id,
                    price: isFree ? 0 : item.price,
                    is_courier: item.service?.data?.is_courier ?? 0,
                    is_consultation: item.service?.data?.is_consultation ?? 0,
                    created_at: item.created_at,
                    updated_at: item.updated_at,
                    quantity: item.quantity,
                    type: item.type,
                    questions: item?.service ? item?.service?.data?.questions : item?.questions,
                });
            }
        });

        const updatedItems = Array.from(itemMap.values());

        const totalPrice = updatedItems.reduce(
            (total: number, item: ObjectKeys) => total + item.price * item.quantity,
            0,
        );

        const discountAmount = (totalPrice * servicesDiscount) / 100;
        const finalPrice = totalPrice - discountAmount;

        return {
            ...cart,
            total_price: finalPrice,
            note: cart.note || '',
            items: updatedItems,
            discount: discountAmount,
            user_id: cart.user_id ? cart.user_id : agency?.user?.data?.id,
            type: 'service',
        };
    },

    fetchCart: async (cartId: string) => {
        try {
            const response = await communication.getCartById(cartId);
            if (response?.data?.data) {
                if (response.data.data.status === 'pending') {
                    const cart = response.data.data;
                    const validatedCart = get().cartValidation(cart);
                    if (!validatedCart) return;
                    set({ cartData: validatedCart });
                } else {
                    set({ cartData: {} });
                }
            }
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    },

    setServices: (services) => {
        set({ servicesData: services });
    },
    setServicesWithoutConsultation: (services) => {
        set({ servicesWithoutConsultation: services });
    },
    setConsultationServices: (services) => {
        set({ consultationServices: services });
    },
    setPackageServices: (services) => {
        set({ packageServices: services });
    },
    helperNote: {},

    setHelperNote: (note) => {
        set({ helperNote: note });
    },

    updateCart: async (cart) => {
        if (!cart) return;

        const validatedCart = get().cartValidation(cart);
        if (!validatedCart) return;

        const currentCart = get().cartData;
        if (JSON.stringify(currentCart) === JSON.stringify(validatedCart)) return;

        set({ cartData: validatedCart });

        try {
            if (cart.id) {
                await communication.updateCart(cart.id, validatedCart);
            } else {
                const res = await communication.createCart(validatedCart);
                if (res?.data?.data) {
                    set({ cartData: { ...validatedCart, id: res.data.data.id } });
                }
            }
        } catch (error) {
            console.error('Error updating/creating cart:', error);
        }
    },
    clearCart: () => {
        set({
            cartData: { ...initialCartData },
            helperNote: {},
        });
    },
    reset: () => {
        set({
            cartData: { ...initialCartData },
            servicesDiscount: 0,
            servicesData: [],
            servicesWithoutConsultation: [],
            consultationServices: [],
            packageServices: [],
            helperNote: {},
        });
    },
}));

export default useCartStore;
