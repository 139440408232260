import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../global/colors';
import { ObjectKeys } from '../../../types/objectKeys';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import Checkbox from '../../../components/checkbox';
import { formatTwoDecimals } from '../../../functions/format';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import Input, { InputComponentType } from '../../../components/input';
import useAgencyStore from '../../../store/agency';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';
import ServiceCard from './serviceCard';
import ConsultationsCard from './consultationsCard';
import useTheme from '../../../hooks/useTheme';
import useCartStore from '../../../store/cart';
import { toast } from 'react-toastify';
import Button, { ButtonVariant } from '../../../components/button';

const StepOne = () => {
    const { currentLang } = useLanguageStore();
    const lang = getCurrentLanguage();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const [searchParams, setSearchParams] = useSearchParams();

    const { agency } = useAgencyStore();

    const isActiveAccount = agency.user.data.active;
    const isTrial = agency.user.data.trial;
    const userPackage = agency.user.data.payment_package.data;

    const servicesDiscount = isTrial || !isActiveAccount ? 0 : agency.user.data.payment_package.data.services_discount;

    const servicesWithoutConsultation = useCartStore((data: any) => data.servicesWithoutConsultation);

    const [searchQuery, setSearchQuery] = useState('');
    const [expandedServiceId, setExpandedServiceId] = useState('');

    const [validationError, setValidationError] = useState<boolean>(false);

    const [filteredServices, setFilteredServices] = useState<any[]>([]);

    const [renderKey, setRenderKey] = useState(0);

    const [servicePackageValidationErrorId, setServicePackageValidationErrorId] = useState<string>('');
    const [consultationPackageValidationErrorId, setConsultationPackageValidationErrorId] = useState<string>('');

    const [chosenPackage_id, setChosenPackage_id] = useState<string>('');
    const [chosenConsultaionPackage_id, setChosenConsultaionPackage_id] = useState<string>('');

    const isDarkTheme = useTheme();

    const cart = useCartStore((data: any) => data.cartData);
    const updateCart = useCartStore((data: any) => data.updateCart);

    const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

    const [service_pre_cart_items, setServicePreCartItems] = useState<any[]>([]);
    const [consultation_pre_cart_items, setConsultationPreCartItems] = useState<any[]>([]);
    const [plainServicesPreCart, setPlainServicesPreCart] = useState<any[]>([]);

    const [resetSignal, setResetSignal] = useState<number>(0);

    const toggleSelectAll = (): void => {
        const newCheckedState = !selectAllChecked;
        setSelectAllChecked(newCheckedState);

        if (newCheckedState) {
            setPlainServicesPreCart(filteredServices.map((item) => ({ ...item, quantity: 1 })));
        } else {
            setPlainServicesPreCart([]);
        }
        setRenderKey((prevRenderKey) => prevRenderKey + 1);
    };

    const getQuantityForService = (row: ObjectKeys): number => {
        const rowId = row.id;
        const preCartItem = plainServicesPreCart.find((item) => item.id === rowId);
        if (preCartItem) return preCartItem.quantity;
        else return 0;
    };

    const getCheckedForService = (row: ObjectKeys): boolean => {
        return plainServicesPreCart.some((item) => item.id === row.id);
    };

    const handleCheckbox = (service: ObjectKeys): void => {
        setSelectAllChecked(false);
        const serviceId = service.id;
        const updatedPreCart = [...plainServicesPreCart];
        const foundIndex = updatedPreCart.findIndex((item) => item.id === serviceId);

        if (foundIndex !== -1) {
            updatedPreCart.splice(foundIndex, 1); // Remove if already selected
        } else {
            updatedPreCart.push({ ...service, quantity: 1 }); // Add with quantity = 1
        }

        setPlainServicesPreCart(updatedPreCart);
    };

    const handleCounterIncrease = (service: ObjectKeys): void => {
        const serviceId = service.id;
        const existingItemIndex = plainServicesPreCart.findIndex((item: ObjectKeys) => item.id === serviceId);
        const updatedPreCart = [...plainServicesPreCart];

        if (existingItemIndex !== -1) {
            const existingItem = updatedPreCart[existingItemIndex];
            if (existingItem.quantity < 20) {
                updatedPreCart[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity + 1,
                };
            }
        } else {
            updatedPreCart.push({ ...service, quantity: 1 });
        }

        setPlainServicesPreCart(updatedPreCart);
    };

    const handleCounterDecrease = (service: ObjectKeys): void => {
        const serviceId = service.id;
        const existingItemIndex = plainServicesPreCart.findIndex((item: ObjectKeys) => item.id === serviceId);
        if (existingItemIndex !== -1) {
            const updatedPreCart = [...plainServicesPreCart];
            const existingItem = updatedPreCart[existingItemIndex];

            if (existingItem.quantity > 1) {
                updatedPreCart[existingItemIndex] = {
                    ...existingItem,
                    quantity: existingItem.quantity - 1,
                };
            } else {
                updatedPreCart.splice(existingItemIndex, 1);
            }
            setPlainServicesPreCart(updatedPreCart);
        }
    };

    const movePreCartToCart = (): void => {
        if (plainServicesPreCart.length === 0) {
            toast.error(t('pages.agency.subscription.noItemsChoosen').text, { toastId: 'debug-toast' });
            return;
        }
        updateCart({ ...cart, items: [...cart.items, ...plainServicesPreCart] });
        resetPreCarts();
        setRenderKey((prevRenderKey) => prevRenderKey + 1);
        toast.success(
            <div>
                {t('pages.agency.subscription.serviceAddedToCart').text}
                <button
                    onClick={() =>
                        navigate('/services/3', {
                            state: { fromTab: 'packages', tabIndex: 0 }, // Passing state
                        })
                    }
                    style={{
                        marginLeft: '10px',
                        background: 'transparent',
                        border: 'none',
                        color: 'var(--purple)',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    }}
                >
                    {t('pages.agency.subscription.goToCart').text}
                </button>
            </div>,
            { autoClose: 5000 }, // Keeps the toast visible for 5s
        );
    };

    const discountedPrice = (price: number): string => {
        const discounted = price - price * (servicesDiscount / 100);
        return formatTwoDecimals(discounted.toString());
    };

    const handleServiceClick = (serviceId: any): void => {
        setExpandedServiceId((prev) => (prev === serviceId ? null : serviceId));
    };

    useEffect(() => {
        if (servicesWithoutConsultation.length > 0) {
            const updatedServices = servicesWithoutConsultation.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );

            setFilteredServices(updatedServices);
        }
    }, [servicesWithoutConsultation]);

    useEffect(() => {
        if (servicesWithoutConsultation.length > 0) {
            const updatedServices = servicesWithoutConsultation.filter((row: any) =>
                row[`name_${lang}`].toLowerCase().includes(searchQuery.toLowerCase()),
            );

            setFilteredServices(updatedServices);
        }
    }, [searchQuery, lang]);

    // *************** Service packages section ***************

    const [tabIndex, setTabIndex] = useState<number>(0);
    const tabParam = searchParams.get('tab');
    const tabs = [
        t('pages.agency.extra_services.packageServices').text,
        t('pages.agency.extra_services.consultationsPackages').text,
        t('pages.agency.extra_services.separateServices').text,
    ];

    const servicePackagesArray = useCartStore((data: any) => data.packageServices);

    // *************** Consultation Service packages section ***************

    const consultationServices = useCartStore((data: any) => data.consultationServices);

    const resetIds = (): void => {
        setServicePackageValidationErrorId('');
        setConsultationPackageValidationErrorId('');
        setChosenPackage_id('');
        setChosenConsultaionPackage_id('');
    };

    const updateCartAdd = (chosenServices: ObjectKeys[], chosenPackageId: string): void => {
        setChosenPackage_id(chosenPackageId);

        const updated_cart = (() => {
            const existingItems = Array.isArray(cart.items) ? cart.items : [];
            const updatedItems = [...existingItems];

            chosenServices.forEach((newService) => {
                const existingItem = updatedItems.find((item) => item.id === newService.id);
                if (existingItem) {
                    existingItem.quantity += newService.quantity;
                } else {
                    updatedItems.push(newService);
                }
            });

            return {
                ...cart,
                items: updatedItems,
            };
        })();

        updateCart(updated_cart);
    };

    const resetPreCarts = (): void => {
        setServicePreCartItems([]);
        setConsultationPreCartItems([]);
        setPlainServicesPreCart([]);
    };

    const resetAllRadioButtons = (): void => {
        setResetSignal(Date.now()); // triggers update in all children
    };

    useEffect(() => {
        resetIds();
        setValidationError(false);
        resetPreCarts();
    }, [tabIndex]);

    useEffect(() => {
        if (tabParam === 'packages') {
            setTabIndex(0);
        } else if (tabParam === 'consultations') {
            setTabIndex(1);
        } else if (tabParam === 'allPackages') {
            setTabIndex(2);
        }
    }, [tabParam]);

    useEffect(() => {
        if (state && state.tabIndex) {
            setTabIndex(state.tabIndex);
        }
    }, [state]);

    useEffect(() => {
        setRenderKey((prevRenderKey) => prevRenderKey + 1);
    }, [plainServicesPreCart]);

    return (
        <>
            <Content isDarkTheme={isDarkTheme}>
                <ChooseFeature isDarkTheme={isDarkTheme}>
                    <div className="notes">
                        <div className="search-services">
                            <div className="tabs">
                                {tabs.map((tab, index) => (
                                    <span
                                        className={`serviceHeader ${
                                            tabIndex === index && 'itemSelected'
                                        } smallerPadding`}
                                        key={index}
                                        onClick={() => {
                                            setTabIndex(index);
                                            if (index === 0) {
                                                setSearchParams({ tab: 'packages' });
                                            } else if (index === 1) {
                                                setSearchParams({ tab: 'consultations' });
                                            } else if (index === 2) {
                                                setSearchParams({ tab: 'allPackages' });
                                            }
                                        }}
                                    >
                                        <p>{tab}</p>
                                    </span>
                                ))}
                            </div>
                            {tabIndex === 2 && (
                                <div className="search-input">
                                    <FontAwesomeIcon icon={faSearch} />
                                    <Input
                                        type={InputComponentType.Text}
                                        onChange={(value: string) => {
                                            setSearchQuery(value);
                                        }}
                                        value={searchQuery}
                                        maxLength={50}
                                        placeholder={t('pages.agency.extra_services.search').text}
                                    />
                                </div>
                            )}
                        </div>
                        {tabIndex === 0 && (
                            <MainServicesWrapper>
                                <h1 className="header">{t('pages.agency.extra_services.packageServicesTitle').text}</h1>
                                <div className="cardWrapper">
                                    {servicePackagesArray.map((separatePackage: any, index: number) => (
                                        <ServiceCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys[], chosenPackageId: string) => {
                                                setServicePreCartItems((prev) => ({
                                                    ...prev,
                                                    [chosenPackageId]: chosenServices, // Add or replace services for this package
                                                }));
                                            }}
                                            removePackage={(chosenServices: ObjectKeys[], chosenPackageId: any) => {
                                                setServicePreCartItems((prev) => {
                                                    const updatedItems = { ...prev };
                                                    delete updatedItems[chosenPackageId]; // Remove services for this package
                                                    return updatedItems;
                                                });
                                            }}
                                            nextStep={(package_id: string, selectedOption: string) => {
                                                if (selectedOption === '') {
                                                    setServicePackageValidationErrorId(package_id);
                                                    return;
                                                }

                                                const itemNames = Object.keys(service_pre_cart_items);

                                                const filteredId: string | undefined = itemNames.find(
                                                    (item: string) => item === package_id,
                                                );
                                                resetPreCarts();
                                                resetAllRadioButtons();
                                                if (filteredId) {
                                                    const services = (service_pre_cart_items as Record<string, any>)[
                                                        filteredId
                                                    ];
                                                    if (services) {
                                                        updateCartAdd(services, filteredId);
                                                    }

                                                    toast.success(
                                                        <div>
                                                            {t('pages.agency.subscription.serviceAddedToCart').text}
                                                            <button
                                                                onClick={() => {
                                                                    resetPreCarts();
                                                                    navigate('/services/3', {
                                                                        state: { fromTab: 'packages', tabIndex: 0 },
                                                                    });
                                                                }}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color: 'var(--purple)',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {t('pages.agency.subscription.goToCart').text}
                                                            </button>
                                                        </div>,
                                                        { autoClose: 5000 },
                                                    );
                                                } else {
                                                    setServicePackageValidationErrorId(package_id);
                                                }
                                            }}
                                            userPackage={isTrial || !isActiveAccount ? false : userPackage}
                                            emptyCartError={servicePackageValidationErrorId === separatePackage.id}
                                            resetSelectionSignal={resetSignal}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 1 && (
                            <MainServicesWrapper>
                                <h1 className="header">
                                    {t('pages.agency.extra_services.consultationsPackagesTitle').text}
                                </h1>
                                <div className="cardWrapper">
                                    {consultationServices?.map((separatePackage: any, index: number) => (
                                        <ConsultationsCard
                                            key={index}
                                            data={separatePackage}
                                            orderPackage={(chosenServices: ObjectKeys[], chosenPackageId: string) => {
                                                setConsultationPreCartItems((prev) => ({
                                                    ...prev,
                                                    [chosenPackageId]: chosenServices, // Add or replace services for this package
                                                }));
                                            }}
                                            removePackage={(chosenServices: ObjectKeys[], chosenPackageId: any) => {
                                                setConsultationPreCartItems((prev) => {
                                                    const updatedItems = { ...prev };
                                                    delete updatedItems[chosenPackageId]; // Remove services for this package
                                                    return updatedItems;
                                                });
                                            }}
                                            nextStep={(consultationServiceID: string, selectedOtion: string) => {
                                                if (selectedOtion === '') {
                                                    setConsultationPackageValidationErrorId(consultationServiceID);
                                                    return;
                                                }

                                                const itemNames = Object.keys(consultation_pre_cart_items);

                                                const filteredId = itemNames.find(
                                                    (item: string) => item === consultationServiceID,
                                                );
                                                resetPreCarts();
                                                resetAllRadioButtons();
                                                if (filteredId) {
                                                    const services = (
                                                        consultation_pre_cart_items as Record<string, any>
                                                    )[filteredId];
                                                    if (services) {
                                                        updateCartAdd(services, filteredId);
                                                    }

                                                    setConsultationPackageValidationErrorId('');

                                                    toast.success(
                                                        <div>
                                                            {t('pages.agency.subscription.serviceAddedToCart').text}
                                                            <button
                                                                onClick={() => {
                                                                    resetPreCarts();
                                                                    navigate('/services/3', {
                                                                        state: { fromTab: 'packages', tabIndex: 0 }, // Passing state
                                                                    });
                                                                }}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    background: 'transparent',
                                                                    border: 'none',
                                                                    color: 'var(--purple)',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {t('pages.agency.subscription.goToCart').text}
                                                            </button>
                                                        </div>,
                                                        { autoClose: 5000 }, // Keeps the toast visible for 5s
                                                    );
                                                } else {
                                                    setConsultationPackageValidationErrorId(consultationServiceID);
                                                }
                                            }}
                                            userPackage={isTrial || !isActiveAccount ? false : userPackage}
                                            emptyCartError={consultationPackageValidationErrorId === separatePackage.id}
                                            resetSelectionSignal={resetSignal}
                                        />
                                    ))}
                                </div>
                            </MainServicesWrapper>
                        )}
                        {tabIndex === 2 && servicesWithoutConsultation && filteredServices?.length > 0 ? (
                            <>
                                <MainServicesWrapper>
                                    <h1 className="header">
                                        {t('pages.agency.extra_services.separateServicesTitle').text}
                                    </h1>
                                    <div className="select-all-wrapper">
                                        <div className="select-all">
                                            <Checkbox defaultChecked={selectAllChecked} onChange={toggleSelectAll} />
                                            <p>{t('pages.agency.extra_services.select_all').text}</p>
                                        </div>
                                    </div>
                                    <FilteredContainer isDarkTheme={isDarkTheme}>
                                        {filteredServices.map((row: any) => {
                                            return (
                                                <>
                                                    <div
                                                        key={row.id}
                                                        className="rowHover"
                                                        onClick={() => handleServiceClick(row.id)}
                                                        style={{
                                                            backgroundColor:
                                                                expandedServiceId === row.id
                                                                    ? isDarkTheme
                                                                        ? 'var(--purple)'
                                                                        : '#cebcff'
                                                                    : isDarkTheme
                                                                    ? ''
                                                                    : '',
                                                        }}
                                                    >
                                                        <span className="left" key={renderKey}>
                                                            <Checkbox
                                                                defaultChecked={getCheckedForService(row)}
                                                                onChange={() => {
                                                                    handleCheckbox(row);
                                                                }}
                                                            />
                                                            <span>{row[`name_${lang}`]}</span>
                                                        </span>
                                                        <div className="counter-parent">
                                                            <span className="right">
                                                                {isTrial ||
                                                                !isActiveAccount ||
                                                                row.price === 0 ? null : (
                                                                    <span className="crosedText">
                                                                        {formatTwoDecimals(row.price.toString())} RSD
                                                                    </span>
                                                                )}
                                                                <span>{discountedPrice(row.price)} RSD</span>
                                                            </span>
                                                            <div className="counter">
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterDecrease(row);
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                                <span>{getQuantityForService(row)}</span>
                                                                <button
                                                                    className="counterBtn"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleCounterIncrease(row);
                                                                    }}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {expandedServiceId === row.id && (
                                                        <div className="description">{row[`description_${lang}`]}</div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </FilteredContainer>
                                </MainServicesWrapper>
                            </>
                        ) : (
                            <p>{tabIndex === 2 && t('pages.agency.extra_services.no_services').text}</p>
                        )}
                    </div>
                    {validationError && tabIndex === 2 && cart.items.length === 0 ? (
                        <p className="validation-error">{t('pages.agency.extra_services.one_service_error').text}</p>
                    ) : null}

                    {tabIndex === 2 && (
                        <div className="services-footer-wrapper">
                            <div className="info">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <p>{t('pages.agency.extra_services.click_more_info').text}</p>
                            </div>
                            <Button
                                variant={ButtonVariant.solid}
                                color={'var(--purple)'}
                                onClick={() => {
                                    movePreCartToCart();
                                }}
                                size={'15%'}
                            >
                                {t('pages.agency.subscription.addToCart').text}
                            </Button>
                        </div>
                    )}
                </ChooseFeature>
            </Content>
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Content = styled.div<DarkThemeProps>`
    text-align: center;
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#212121;' : '#f7f7f7')};
    padding: 30px;
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        padding: 0 0 1rem;
    }
`;
const FilteredContainer = styled.div<DarkThemeProps>`
    max-height: 470px;
    overflow-y: auto;
    width: 100%;
    border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? '#121212;' : '#f7f7f7')};
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212;' : '#f7f7f7')};

    /* Styles for WebKit browsers */
    &::-webkit-scrollbar {
        width: 12px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #9b7fe9; /* Color of the track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a0a0a0; /* Color of the scrollbar thumb */
        border-radius: 6px; /* Roundness of the scrollbar thumb */
        border: 3px solid #9b7fe9; /* Creates padding around the scrollbar thumb */
    }

    /* Styles for Firefox */
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #9b7fe9 ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#f0f0f0')}; /* Thumb and track color */
`;
const ChooseFeature = styled.div<DarkThemeProps>`
    padding: 20px 0;
    text-align: center;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 0;
    }
    .select-all {
        padding: 10px;
        display: flex;
        width: 100%;
        border-bottom: 1px solid var(--purple);
        p {
            font-size: 14px;
            cursor: default;
        }
    }
    .notes {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .search-services {
            background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
            margin-bottom: 20px;
            text-align: -webkit-center;
            width: 100%;
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;
            height: 65px;
            padding: 10px 20px;
            font-size: 14px;
            text-transform: uppercase;
            .tabs {
                display: flex;
                gap: 20px;
                flex-direction: column;

                @media screen and (min-width: ${breakpoints.tablet}px) {
                    flex-direction: row;
                }
            }
            @media screen and (max-width: ${breakpoints.tablet - 1}px) {
                align-items: center;
                justify-content: center;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                align-items: center;
                flex-direction: column;
                gap: 0.5rem;
                height: auto;
            }
            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 1}px) {
                flex-direction: row;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
                height: 65px;
            }
            @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
                height: 65px;
            }
            .title-tooltip-wrapper {
                display: flex;
                align-items: center;
                gap: 1rem;
                .chooseService {
                    color: var(--white);
                    font-size: 14px;
                    text-transform: uppercase;
                }
                .tooltip-wrapper {
                    div {
                        > span {
                            background-color: var(--white);
                            color: var(--purple);
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            display: block;
                            position: relative;
                            &::after {
                                content: '?';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-weight: 900;
                                font-size: 17px;
                            }
                            span {
                                width: 200px;
                            }
                        }
                    }
                }
            }

            .search-input {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                padding: 0 10px;
                background-color: var(--white);
                width: 100%;
                @media only screen and (min-width: ${MQ_BREAKPOINTS.tablet}) {
                    width: 35%;
                }
                @media only screen and (min-width: ${breakpoints.laptop - 1}px) {
                    width: auto;
                }
                svg {
                    color: var(--gray);
                    font-size: 15px;
                }
                .input {
                    width: 100%;
                    .input-container {
                        margin-bottom: 0;
                        input {
                            border-bottom: 0;
                            font-size: 15px;
                        }
                    }
                }
            }

            .serviceHeader {
                font-size: 14px;
                padding: 10px 20px;
                cursor: pointer;

                p {
                    font-size: 14px;
                }

                @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                    display: flex;
                    align-items: center;
                }
            }

            @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptopM - 1}px) {
                .smallerPadding {
                    padding: 0;
                }
                color: white;
            }

            .itemSelected {
                border-bottom: 3px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'white')};
                color: white;
                padding-top: 1px;
                padding: 10px 20px;
            }
        }
        .row {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            &:last-child {
                border-bottom: none;
            }
        }
        .rowHover {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            :hover {
                cursor: pointer;
                background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : '#cebcff')};
                .left,
                .right {
                    color: var(--black);
                }
            }
        }
        .description {
            padding: 5px 80px 5px 30px;
            border-radius: 20px;
            margin-top: 5px;
            font-size: 13px;
            text-align: justify;
            font-style: italic;
        }
        .left {
            text-align: left;
            display: flex;
            max-width: 280px;
            color: var(--gray);
            font-size: 15px;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 150px;
            }
            > div {
                > div {
                    margin-bottom: 0;
                }
            }
        }
        .right {
            text-align: right;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            color: var(--gray);
            font-size: 15px;
            .crosedText {
                text-decoration: line-through;
            }
        }
        .counter {
            display: flex;
            align-items: center;
            span {
                padding: 0 5px;
                border: 2px solid var(--purple);
            }
        }
        .counterBtn {
            border: none;
            padding: 5px 8px;
            cursor: pointer;
            margin: 0 5px;
            color: var(--purple);
            font-weight: bold;
            background-color: transparent;
        }
        .counterBtn:hover {
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--white)')};
        }
        .counter-parent {
            display: flex;
            align-items: center;
        }
    }
    .validation-error {
        color: red;
        margin-top: 1.5rem;
        font-size: 14px;
    }
    .services-footer-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            gap: 1.5rem;
        }
        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: ${colors.purple};
            justify-content: flex-start;
            p {
                font-size: 14px;
            }
        }
    }
`;

const MainServicesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 50vh;

    .header {
        font-weight: 400;
        margin: 20px 0 40px 0;
        font-size: 32px;
    }

    .cardWrapper {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping to multiple rows */
        justify-content: space-between; /* Distribute space evenly between cards */
        width: 100%;
        gap: 20px;

        @media screen and (min-width: ${breakpoints.desktop}px) {
            justify-content: left;
        }

        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            justify-content: center;
        }
    }
    .select-all-wrapper {
        display: flex;
        justify-content: space-between; /* Distribute space evenly between cards */
        width: 100%;
        gap: 20px;

        @media screen and (min-width: ${breakpoints.desktop}px) {
            justify-content: left;
        }

        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            justify-content: center;
        }
    }
`;

export default StepOne;
