import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';
import SelectDefaultLang from './selectComponent';
import { ObjectKeys } from '../../../types/objectKeys';
import useAgencyStore from '../../../store/agency';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import ToggleSwitch from '../../../components/input/toggleSwitch';

const DefaultLang: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { agency } = useAgencyStore();
    const userLang = agency.user.data.lang;

    const userGoogleAnalyticsPreference = agency?.user?.data?.cookie?.data?.google_analytics;
    const userTalkTwoPreference = agency?.user?.data?.cookie?.data?.tawkto;

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [cookiePrefenceObject, setCookiePreferenceObject] = useState<ObjectKeys>(agency?.user?.data?.cookie?.data);

    const [formData, setFormData] = useState({
        lang: userLang,
    });

    const update = async (): Promise<void> => {
        setLoaderVisible(true);

        if (formData) {
            const name = formData.lang === 'rs' ? 'Srpski' : formData.lang === 'en' ? 'English' : 'Русский';
            useLanguageStore.setState({ currentLangName: name });
            localStorage.setItem('language', name);

            try {
                const cookieResponse = await communication.authCookiesGlobal(cookiePrefenceObject);

                if (cookieResponse) {
                    const userResponse = await communication.updateUser(formData, agency.user.data.id);

                    if (userResponse.status === 200) {
                        setSuccessfullySaved(true);

                        setCookiePreferenceObject(userResponse.data?.data?.cookie?.data);

                        useAgencyStore.setState((prevState) => ({
                            agency: {
                                ...prevState.agency,
                                user: {
                                    ...prevState.agency.user,
                                    data: userResponse.data.data,
                                },
                            },
                        }));
                    }
                }

                setLoaderVisible(false);
            } catch (error) {
                console.error('Error in update process', error);
                setLoaderVisible(false);
            }
        }

        setLoaderVisible(false);
        useChangeModalStore.setState({ changeModalVisible: false });
    };

    const handleNameChange = (name: string): void => {
        const langCode = name === 'Srpski' ? 'rs' : name === 'English' ? 'en' : name === 'Русский' ? 'ru' : name;
        if (formData) setFormData({ ...formData, lang: langCode });
    };

    const getCurrentDateTime = (): string => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const updateCookiesPreference = (cookie: 'tawkto' | 'analytics', choice: boolean) => {
        if (cookie === 'tawkto' && userTalkTwoPreference !== choice) {
            setCookiePreferenceObject({
                ...cookiePrefenceObject,
                consent_given: true,
                tawkto: choice,
                tawkto_updated: getCurrentDateTime(),
            });
        }
        if (cookie === 'analytics' && userGoogleAnalyticsPreference !== choice) {
            setCookiePreferenceObject({
                ...cookiePrefenceObject,
                consent_given: true,
                google_analytics: choice,
                google_analytics_updated: getCurrentDateTime(),
            });
        }
    };

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {formData ? (
                <Content>
                    <div className="center">
                        <div className="languageDiv">
                            <div className="lang-selector">
                                <p>{t('pages.agency.langSelection.description').text}</p>
                            </div>
                            <div className="lang-selector2">
                                <SelectDefaultLang color="black" onNameChange={handleNameChange} />
                            </div>
                        </div>
                        <div className="cookiesSelectionDiv">
                            <p>{t('pages.invoices.conditions.cookiePreferences').text}</p>
                            <div className="selectionToggles">
                                <div className="toggleDiv">
                                    <p>{t('pages.invoices.conditions.default').text}</p>
                                    <ToggleSwitch defaultChecked={true} isDisabled={true} />
                                </div>
                                <div className="toggleDiv">
                                    <p>{t('pages.invoices.conditions.analytics').text}</p>
                                    <ToggleSwitch
                                        defaultChecked={cookiePrefenceObject.google_analytics}
                                        onChange={(value: boolean) => {
                                            updateCookiesPreference('analytics', value);
                                        }}
                                    />
                                </div>
                                <div className="toggleDiv">
                                    <p>Tawk.to</p>
                                    <ToggleSwitch
                                        defaultChecked={cookiePrefenceObject.tawkto}
                                        onChange={(value: boolean) => {
                                            updateCookiesPreference('tawkto', value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="button-container">
                            <Button variant={ButtonVariant.solid} color={colors.purple} size={200} onClick={update}>
                                {t('pages.agency.profile.save').text}
                            </Button>
                        </div>
                    </div>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default DefaultLang;

const Content = styled.div`
    .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .languageDiv {
        display: flex;
        flex-direction: column;
        /* width: 100%; */
        justify-content: space-between;
        gap: 20px;
    }

    .cookiesSelectionDiv {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 80%;
        margin-top: 15px;

        .selectionToggles {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;
        }

        .toggleDiv {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p {
                text-align: left;
            }
        }
    }

    .lang-selector,
    .lang-selector2 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lang-selector {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 15px;
        color: var(--adjust);
    }
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .bank-number {
        width: 400px;
        position: relative;
    }
    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 15px;
        color: var(--gray);
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 2px;
        border: 0;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) {
        width: 59%;
    }

    @media screen and (min-width: ${breakpoints.tablet}px) {
        width: 100%;
        .languageDiv,
        .cookiesSelectionDiv {
            flex-direction: row;
            width: 60%;
        }

        .cookiesSelectionDiv {
            p {
                text-align: left;
            }
        }
    }
`;
