import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '@/types/objectKeys';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import TextArea from '../../../components/input/textarea';
import { formatTwoDecimals } from '../../../functions/format';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';
import React from 'react';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';

interface StepThreeProps {
    cart: ObjectKeys;
    nextStep: Function;
    previousStep: Function;
    updatedCart: Function;
    setCart: Function;
}

type ResponsesType = {
    [productName: string]: {
        [questionKey: string]: string;
    };
};

const StepThree: React.FC<StepThreeProps> = ({ cart, nextStep, updatedCart, previousStep, setCart }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = localStorage.language;
    const navigate = useNavigate();
    const defaultLang = lang === 'Srpski' ? 'rs' : lang === 'English' ? 'en' : lang === 'Русский' ? 'ru' : 'rs';

    const [responses, setResponses] = useState<ResponsesType>({});

    const [requiredValidation, setRequiredValidation] = useState<boolean>(false);

    const location = useLocation();
    const state = location.state || {};

    const [discount, setDiscount] = useState<number>(0);

    const discountedPrice = (price: number): number => {
        const discounted = price;
        return discounted;
    };

    const updateQuantity = (itemId: string, newQuantity: number) => {
        setCart((prevCart: { items: any[]; total_price: number }) => {
            const updatedItems = prevCart.items.map((item) => {
                if (item.id === itemId) {
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });

            const newTotalPrice = updatedItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const newCart = { ...prevCart, items: updatedItems, total_price: newTotalPrice };

            localStorage.setItem('guestCart', JSON.stringify(newCart));

            return newCart;
        });
    };

    const removeItemFromCart = (itemId: string) => {
        setCart((prevCart: { items: any[]; total_price: number }) => {
            const updatedItems = prevCart.items.filter((item) => item.id !== itemId);

            const newTotalPrice = updatedItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            const newCart = { ...prevCart, items: updatedItems, total_price: newTotalPrice };

            localStorage.setItem('guestCart', JSON.stringify(newCart));

            return newCart;
        });
    };

    const handleResponseChange = (serviceId: string, questionKey: string, value: string) => {
        setResponses((prev) => {
            const updated = {
                ...prev,
                [serviceId]: {
                    ...prev[serviceId],
                    [questionKey]: value,
                },
            };

            const guestCart = JSON.parse(localStorage.getItem('guestCart') || '{}');
            const cleanResponses = Object.fromEntries(
                Object.entries(updated).filter(([key]) => cart.items.some((item: ObjectKeys) => item.id === key)),
            );

            const updated_cart = {
                ...guestCart,
                responses: cleanResponses,
            };

            localStorage.setItem('guestCart', JSON.stringify(updated_cart));

            return updated;
        });
    };

    const getProductName = (product: any, selectLang: string) => {
        return product[`name_${selectLang}`] || 'unknown_product';
    };

    // const generateCombinedString = (cartUser: ObjectKeys, userResponse: ResponsesType): string => {
    //     let combinedString = cartUser.note + '\n\n';

    //     const formatKey = (key: string, value: string, number: string): string => {
    //         if (key.endsWith('_answer')) {
    //             return `Answer ${number}: ${value}`;
    //         }
    //         return `Question ${number}: ${value}`;
    //     };

    //     for (const [productName, questions] of Object.entries(userResponse)) {
    //         combinedString += `${productName}:\n`;

    //         const questionNumbers = new Set();

    //         for (const [key, value] of Object.entries(questions)) {
    //             const match = key.match(/question_(\d+)_/);

    //             if (match) {
    //                 const questionNumber = match[1];

    //                 if (!questionNumbers.has(questionNumber)) {
    //                     questionNumbers.add(questionNumber);

    //                     const formattedQuestion = formatKey(
    //                         `question_${questionNumber}_${defaultLang}`,
    //                         value,
    //                         questionNumber,
    //                     );
    //                     combinedString += `${formattedQuestion}\n`;

    //                     const answerKey = `question_${questionNumber}_${defaultLang}_answer`;
    //                     if (questions[answerKey]) {
    //                         const formattedAnswer = formatKey(answerKey, questions[answerKey], questionNumber);
    //                         combinedString += `${formattedAnswer}\n`;
    //                     }
    //                 }
    //             }
    //         }

    //         combinedString += '\n';
    //     }

    //     return combinedString.trim();
    // };

    const proceedToNextStep = (): void => {
        const hasUnansweredRequired = cart.items.some((item: ObjectKeys) => {
            const itemResponses = responses[item.id] || {};
            const questionKeys = Object.keys(item.questions?.[0] || {}).filter(
                (key) => key.endsWith(`_${defaultLang}`) && item.questions[0][key] !== null,
            );

            return questionKeys.some((questionKey) => {
                const baseKey = questionKey.replace(`_${defaultLang}`, '');
                const answerKey = `${baseKey}_${defaultLang}_answer`;
                return !itemResponses[answerKey]?.trim();
            });
        });

        setRequiredValidation(hasUnansweredRequired);

        if (!hasUnansweredRequired) {
            nextStep();
            navigate('/guest/3');
        }
    };

    useEffect(() => {
        const guestCart = JSON.parse(localStorage.getItem('guestCart') || '{}');
        const savedResponses = guestCart.responses || {};
        const hydrated: ResponsesType = {};

        cart.items.forEach((item: ObjectKeys) => {
            const itemId = item.id;
            const storedItemResponses = savedResponses[itemId] || {};
            hydrated[itemId] = {};

            Object.entries(item.questions?.[0] || {}).forEach(([key, value]) => {
                if (key.endsWith(`_${defaultLang}`) && value !== null) {
                    const baseKey = key.replace(`_${defaultLang}`, '');
                    const answerKey = `${baseKey}_${defaultLang}_answer`;

                    hydrated[itemId][key] = value as string;
                    hydrated[itemId][answerKey] = storedItemResponses[answerKey] || '';
                }
            });
        });

        // ✅ Store only valid responses in localStorage
        const updated_cart = {
            ...cart,
            responses: hydrated,
        };
        setCart(updated_cart);
        // localStorage.setItem('guestCart', JSON.stringify(updated_cart));

        setResponses(hydrated);
    }, [cart?.items]);

    return (
        <>
            {requiredValidation && (
                <Modal modalVisible={requiredValidation} closeModal={() => setRequiredValidation(!requiredValidation)}>
                    <ErrorModal
                        t={t}
                        errorMessage="Molimo Vas odgovorite na sva pitanja vezana za uslugu"
                        setOpenModal={setRequiredValidation}
                    />
                </Modal>
            )}
            <ContentSecondStep>
                <Title>
                    <h2>{t('pages.agency.extra_services.choosen_services').text}</h2>
                </Title>
                <div className="content-wrapper">
                    <div className="leftPage">
                        <div>
                            <TableWrapper>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{t('pages.agency.extra_services.service').text}</th>
                                            <th>{t('pages.agency.extra_services.quantity').text}</th>
                                            <th>{t('pages.agency.extra_services.price').text}</th>
                                            <th>{t('pages.agency.extra_services.discount_price').text}</th>
                                            <th>{t('pages.agency.extra_services.remove').text}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cart.items.map((i: ObjectKeys) => {
                                            const productName = getProductName(i, defaultLang);
                                            return (
                                                <React.Fragment key={i.id}>
                                                    <tr>
                                                        <td className="service-name">{i[`name_${defaultLang}`]}</td>
                                                        <td className="marginQuantity">
                                                            <button
                                                                onClick={() => updateQuantity(i.id, i.quantity - 1)}
                                                                disabled={i.quantity <= 1}
                                                            >
                                                                -
                                                            </button>
                                                            {i.quantity}
                                                            <button
                                                                onClick={() => updateQuantity(i.id, i.quantity + 1)}
                                                            >
                                                                +
                                                            </button>
                                                        </td>
                                                        <td>{formatTwoDecimals((i.price * i.quantity).toString())}</td>
                                                        <td>
                                                            {formatTwoDecimals(
                                                                (discountedPrice(i.price) * i.quantity).toString(),
                                                            )}
                                                        </td>
                                                        <td className="removeItemClass">
                                                            <button onClick={() => removeItemFromCart(i.id)}>x</button>
                                                        </td>
                                                    </tr>

                                                    {i.questions?.length > 0 &&
                                                        Object.entries(i.questions[0]).some(
                                                            ([key, value]) =>
                                                                key.endsWith(`_${defaultLang}`) &&
                                                                value !== null &&
                                                                value !== '',
                                                        ) && (
                                                            <tr
                                                                className="question-section"
                                                                key={i[`name_${defaultLang}`]}
                                                            >
                                                                <td colSpan={5}>
                                                                    <fieldset>
                                                                        <legend>
                                                                            {
                                                                                t(
                                                                                    'pages.agency.subscription.questionsFor',
                                                                                ).text
                                                                            }{' '}
                                                                            {i[`name_${defaultLang}`]}
                                                                        </legend>
                                                                        {Object.entries(i.questions[0])
                                                                            .filter(
                                                                                ([key, value]) =>
                                                                                    key.endsWith(`_${defaultLang}`) &&
                                                                                    value !== null,
                                                                            )
                                                                            .map(([key, questionText], index) => {
                                                                                const questionKey = `question_${
                                                                                    index + 1
                                                                                }_${defaultLang}`;
                                                                                const answerKey = `${questionKey}_answer`;

                                                                                return (
                                                                                    <div
                                                                                        key={key}
                                                                                        className="question-container"
                                                                                    >
                                                                                        <p>{questionText as string}</p>
                                                                                        <textarea
                                                                                            value={
                                                                                                responses[i.id]?.[
                                                                                                    answerKey
                                                                                                ] || ''
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                handleResponseChange(
                                                                                                    i.id,
                                                                                                    answerKey,
                                                                                                    e.target.value,
                                                                                                )
                                                                                            }
                                                                                            placeholder={
                                                                                                t(
                                                                                                    'pages.agency.subscription.yourAnswer',
                                                                                                ).text
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </fieldset>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TableWrapper>
                        </div>
                        <div className="bottom-note">
                            <div className="left">{t('pages.agency.extra_services.note').text}</div>
                            <div className="note">
                                <TextArea
                                    value={cart.note}
                                    onChange={(value: string) => {
                                        const newCart = { ...cart };
                                        newCart.note = value;
                                        updatedCart(newCart);
                                    }}
                                    placeholder={t('pages.agency.extra_services.placeholder_note').text}
                                    height={'75px'}
                                    maxLength={180}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rightMain">
                        <div className="rightPage">
                            <div className="amountPrice">
                                <div className="table">
                                    <div>
                                        <p>{t('pages.agency.subscription.price').text}</p>
                                        <p>{formatTwoDecimals(cart.total_price.toString())} RSD</p>
                                    </div>
                                    <div>
                                        <p>{t('pages.agency.subscription.discount').text}</p>
                                        <p>{formatTwoDecimals(discount.toString())} RSD</p>
                                    </div>
                                </div>
                                <div className="total">
                                    <p>{t('pages.agency.subscription.total').text}</p>
                                    <p>{formatTwoDecimals((cart.total_price - discount).toString())} RSD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentSecondStep>
            <Arrows>
                <StepControlButton
                    step={'previous'}
                    handleClick={() => {
                        previousStep();
                        navigate('/guest/1', {
                            state, // Keep the same state (fromTab: 'packages', tabIndex: 0)
                        });
                    }}
                />
                {cart?.items.length > 0 && (
                    <StepControlButton
                        step={'next'}
                        handleClick={() => {
                            proceedToNextStep();
                        }}
                    />
                )}
            </Arrows>
        </>
    );
};
const ContentSecondStep = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    .content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
        }

        @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
            min-height: 60vh;
        }
        .rightMain {
            flex: 1;
            display: flex;
            flex-direction: column;
            max-width: 50%;
            -webkit-box-pack: justify;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
        }
        .leftPage {
            flex: 1;
            max-width: 50%;
            padding: 20px 0;
            table {
                width: 100%;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
            .packages {
                display: flex;
                gap: 5px;
                padding: 10px;
                justify-content: space-evenly;
            }
            .chooseSubscription {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px;
                font-size: 15px;
                .item {
                    padding-left: 10px;
                }
                .crossed {
                    text-decoration: line-through;
                }
            }
            .bottom-note {
                display: flex;
                flex-direction: column;
                .left {
                    text-align: left;
                    font-size: 15px;
                    color: var(--gray);
                }
                textarea {
                    font-size: 15px;
                }
            }
        }
        .rightPage {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            color: white;
            height: fit-content;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 100%;
                gap: 20px;
            }
            .title {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                h2 {
                    font-size: 15px;
                    color: white;
                    margin: 10px;
                }
            }
            .table {
                width: 90%;
                padding: 20px 0;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 0;
                    p {
                        font-size: 15px;
                    }
                }
            }
            .amountPrice {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--purple);
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
            }
            .total {
                display: flex;
                justify-content: space-between;
                width: 90%;
                padding: 20px 0;
                p {
                    font-size: 15px;
                }
            }
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 25px;
            width: 20px;
            margin-bottom: 10px;
        }
        .note {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
            text-align: center;
            line-height: 20px;
            border: 1px solid var(--purple);
            padding: 5px;
            width: 100%;
        }
        .left {
            margin-top: 40px;
            text-align: center;
        }
    }
`;

const Title = styled.div`
    background-color: var(--purple);
    padding: 20px 10px;
    h2 {
        font-size: 20px;
        color: var(--white);
        text-align: left;
    }
`;

const Arrows = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const TableWrapper = styled.div`
    max-height: 600px;
    overflow-y: auto;
    overflow-x: clip;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }

    table {
        width: 100%;
        margin-top: 20px;
        text-align: left;
        border-collapse: collapse;
        color: var(--gray);

        thead {
            border-bottom: 1px solid #ccc;

            th {
                padding: 10px;
                min-width: 120px;
                font-size: 15px;

                @media only screen and (max-width: 768px) {
                    font-size: 13px;
                    min-width: auto;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #ccc;

                td {
                    padding: 10px;
                    line-height: 1.2;
                    font-size: 15px;

                    @media only screen and (max-width: 768px) {
                        font-size: 13px;
                        text-align: center;
                    }

                    button {
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                        font-size: 16px;
                        color: var(--purple);
                        transition: 0.2s;

                        &:hover {
                            color: var(--purple);
                        }
                    }
                }

                .marginQuantity {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;

                    button {
                        font-size: 18px;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background-color: var(--purple);
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 0.2s;

                        &:hover {
                            background-color: var(--purple);
                        }
                    }
                }

                .removeItemClass {
                    text-align: right;
                }
            }

            .question-section {
                td {
                    padding: 15px;
                    background-color: rgba(240, 240, 240, 0.5);
                }

                fieldset {
                    border: 1px solid var(--purple);
                    padding: 15px;
                    border-radius: 8px;
                    margin-top: 10px;
                }

                legend {
                    font-size: 14px;
                    font-weight: bold;
                    color: var(--purple);
                    padding: 0 10px;
                }

                .question-container {
                    margin-top: 10px;

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }

                    textarea {
                        width: 100%;
                        min-height: 50px;
                        border-radius: 6px;
                        padding: 8px;
                        border: 1px solid #ccc;
                        font-size: 14px;
                        transition: 0.2s;

                        &:focus {
                            outline: none;
                            border-color: var(--purple);
                            box-shadow: 0 0 4px rgba(128, 0, 128, 0.3);
                        }
                    }
                }
            }
        }
    }
`;

export default StepThree;
