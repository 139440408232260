import { FunctionComponent } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import useTheme from '../../../../hooks/useTheme';

type Props = {
    t: Function;
    closeGlobalModal: () => void;
    selectedInvoicesInformationArray: string[];
    modalType: string;
    invoiceType: 'invoice' | 'einvoice' | 'proforma' | 'advance' | 'eadvance';
    isModalConfirmed: () => void;
};

const HandleInvoicesModal: FunctionComponent<Props> = ({
    t,
    closeGlobalModal,
    selectedInvoicesInformationArray,
    modalType,
    invoiceType,
    isModalConfirmed,
}) => {
    let modalTitle, modalFooter;
    const isDarkTheme = useTheme();

    switch (invoiceType) {
        case 'invoice':
            modalTitle =
                modalType === 'delete'
                    ? t('pages.invoices.handleInvoicesModal.titleDeleteInvoice').text
                    : t('pages.invoices.handleInvoicesModal.titlePaymentInvoice').text;
            modalFooter =
                modalType === 'delete'
                    ? t('pages.invoices.handleInvoicesModal.footerDeleteInvoice').text
                    : t('pages.invoices.handleInvoicesModal.footerPaymentInvoice').text;
            break;

        case 'einvoice':
            modalTitle = modalType === 'payment' && t('pages.invoices.handleInvoicesModal.titlePaymentEInvoice').text;
            modalFooter = modalType === 'payment' && t('pages.invoices.handleInvoicesModal.footerPaymentEInvoice').text;
            break;

        case 'proforma':
            modalTitle = t('pages.invoices.handleInvoicesModal.titleDeleteProforma').text;
            modalFooter = t('pages.invoices.handleInvoicesModal.footerDeleteProforma').text;
            break;

        case 'advance':
            modalTitle =
                modalType === 'delete'
                    ? t('pages.invoices.handleInvoicesModal.titleDeleteAdvance').text
                    : t('pages.invoices.handleInvoicesModal.titlePaymentAdvances').text;
            modalFooter =
                modalType === 'delete'
                    ? t('pages.invoices.handleInvoicesModal.footerDeleteAdvance').text
                    : t('pages.invoices.handleInvoicesModal.footerPaymentAdvances').text;
            break;

        case 'eadvance':
            modalTitle = modalType === 'payment' && t('pages.invoices.handleInvoicesModal.titlePaymentAdvances').text;
            modalFooter = modalType === 'payment' && t('pages.invoices.handleInvoicesModal.footerPaymentAdvances').text;
            break;

        default:
            modalTitle = 'Unknown Invoice Type';
            modalFooter = '';
            break;
    }

    return (
        <HandleInvoicesWrapper>
            <ModalText>
                <>
                    <p>{modalTitle}</p>
                    <br />
                    {selectedInvoicesInformationArray?.join(', ')}
                    <p>{modalFooter}</p>
                </>
            </ModalText>
            <ButtonContainer isDarkTheme={isDarkTheme}>
                <Button variant={ButtonVariant.solid} color={colors.purple} size={'100%'} onClick={isModalConfirmed}>
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </HandleInvoicesWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const HandleInvoicesWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div<DarkThemeProps>`
    display: flex;
    width: 50%;
    margin-top: 10px;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
    button:last-child {
        /* background-color: var(--white); */
        /* color: var(--black); */
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-right: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#fff')};
    }
`;
const ModalText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
    p:last-child {
        margin-top: 15px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .header {
        margin-bottom: 20px;
        text-align: center;
        color: var(--purple);
    }
`;

export default HandleInvoicesModal;
