import { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleQuestion, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useGeneralStore from '../../../store/general';
import useLanguageStore from '../../../store/language';
import useProfileStore from '../../../store/profile';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import { InvoiceCardComponentType } from '../../../components/invoiceCard';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Filters from './filters';
import DeleteModal from '../common/deleteModal';
import CancelModal from '../common/cancelModal';
import Modal from '../../../components/modal';
import Success from '../../../components/successModal';
import Sidebar from '../../../components/sideModal';
import AccountExpired from '../../../components/accountExpired';
import { SendDocumentFormData } from '../../../types/communication/document';
import SendDocumentModal from '../common/sendModal';
import PaymentModal from '../invoices/paymentModal';
import PaymentUpdateModal from '../invoices/paymentUpdateModal';
import EInvoiceCard from '../../../components/einvoiceCard';
import ErrorModal from '../../../components/errorModal';
import useInvoicesStore from '../../../store/invoices';
import SendReminderModal from '../common/sendReminderModal';
import getRestOfPayment from '../../../components/invoiceCard/getRestOfPayment';
import { formatNumber } from '../../../functions/format';
import ConfirmModal from '../../../components/confirmModal';
import { toast } from 'react-toastify';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';
import FilterTrack from '../common/filterTrack';
import useInvoiceParamsStore from '../../../store/invoiceParams';
import getAllEinvoices from './getAllInvoices';
import NoItems from '../../../components/noItems';
import Checkbox from '../../../components/checkbox';
import HandleInvoicesModal from '../common/handleInvoicesModal';
import useTheme from '../../../hooks/useTheme';
import Input, { InputComponentType } from '../../../components/input';
import moment from 'moment';
import Tooltip from '../../../components/tooltipMain';
import useResponsive from '../../../hooks/responsive/useResponsive';
import IntervalLoader from '../../../components/intervalLoaderModal';

const EInvoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { profile } = useProfileStore();
    const { eInvoiceData, desiredCardView } = useInvoicesStore();
    const role = localStorage.getItem('role');

    const token = getAccessToken();
    const isAccountActive = role === 'admin' ? true : profile?.active === 1 ? true : false;

    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [sendReminderModal, setReminderModal] = useState(false);
    const [successReminder, setSuccessReminder] = useState(false);

    const [invoiceToRemind, setInvoiceToRemind] = useState<ObjectKeys>({});
    const [invoiceToSend, setInvoiceToSend] = useState<ObjectKeys>({});

    const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);

    const [clickedPayment, setClickedPayment] = useState('');

    const [display, setDisplay] = useState(desiredCardView);
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [invoiceToCancel, setInvoiceToCancel] = useState<ObjectKeys>({});
    const [qrCode, setQrCode] = useState('');
    const [currencyPay, setCurrencyPay] = useState('');
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);

    //send
    const [sendModal, setSendModal] = useState(false);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const noItemHtml = t('pages.eInvoices.noItems.description').text;
    //pay
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [invoicePaidValue, setInvoicePaidValue] = useState<string | number>('');

    const [removePaymentsModal, setRemovePaymentsModal] = useState(false);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: '',
        searchFields: {},
        searchJoin: 'and',
        orderBy: '',
        sortedBy: '',
    });
    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    const [selectedInvoicesArray, setSelectedInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false);

    const isDarkTheme = useTheme();

    const [refreshDateFrom, setRefreshDateFrom] = useState<Date>(moment().startOf('year').toDate());
    const [refreshDateTo, setRefreshDateTo] = useState<Date>(moment().startOf('day').toDate());

    const { isDesktopLargeAndDown } = useResponsive();

    const [refreshEInvoicesModal, setRefreshEInvoicesModal] = useState<boolean>(false);

    const [showIntervalLoader, setShowIntervalLoader] = useState<boolean>(false);
    const [intervalLoaderMessage, setIntervalLoaderMessage] = useState<string>('');
    const [intervalLoaderSeconds, setIntervalLoaderSeconds] = useState<number>(30);

    useEffect(() => {
        getInvoices();
    }, [JSON.stringify(params), formattedParams]);

    useEffect(() => {
        useInvoicesStore.setState({ desiredCardView: display });
    }, [display]);

    const getInvoices = async (): Promise<boolean> => {
        setShowSkeleton(true);
        setIsAllChecked(false);
        setSelectedInvoicesArray([]);

        try {
            const finalParams = {
                ...params,
                search: 'is_prepaid:0;is_einvoice:1;' + formattedParams,
                searchFields: 'is_prepaid:=;is_einvoice:=;' + formattedParams,
            };
            useInvoiceParamsStore.setState({ einvoices_params: finalParams });
            const res = await communication.getInvoices(finalParams);

            if (res.status === 200) {
                if (res.data?.data?.length > 0) {
                    setHasInvoices(true);
                }
                setShowSkeleton(false);
                useInvoicesStore.setState({ eInvoiceData: res.data });
                return true;
            } else {
                setShowSkeleton(false);
                useInvoicesStore.setState({ eInvoiceData: [] });
                return false;
            }
        } catch (error) {
            setShowSkeleton(false);
            useInvoicesStore.setState({ eInvoiceData: [] });
            return false;
        }
    };

    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/invoices/edit/${invoiceId}`);
    };

    // Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        navigate(`/invoices/copy/${invoiceId}`);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={invoice}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => {
                    setHasInvoices(false);
                    getInvoices();
                }}
            />,
        );
    };

    //Invoice Cancel Modal
    const handleCancelModal = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
        setOpenCancelModal(true);
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            `00${invoiceNumber?.replace('/', '-')}`,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res)
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        false,
                        false,
                        res?.i ? res?.i : '',
                    );
            })
            .catch(() => {
                communication.downloadInvoice(invoiceId, token, invoice.invoice_number, false, false, undefined);
            });
    };

    //Invoice Print
    const handleViewInvoicePDF = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            `00${invoiceNumber?.replace('/', '-')}`,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res)
                    communication
                        .printInvoice(invoiceId, token, invoice.invoice_number, false, false, res?.i ? res?.i : '')
                        .then((resp: ObjectKeys) => {
                            const url = window.URL.createObjectURL(resp.data);
                            const printWindow = window.open(url, '_blank');
                            if (printWindow) {
                                printWindow.onload = () => {
                                    printWindow.focus();
                                    printWindow.print();
                                };
                            }
                        });
            })
            .catch(() => {
                communication
                    .printInvoice(invoiceId, token, invoice.invoice_number, false, false, undefined)
                    .then((resp: ObjectKeys) => {
                        const url = window.URL.createObjectURL(resp.data);
                        const printWindow = window.open(url, '_blank');
                        if (printWindow) {
                            printWindow.onload = () => {
                                printWindow.focus();
                                printWindow.print();
                            };
                        }
                    });
            });
    };

    //refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);
        try {
            const res = await communication.refreshEInvoice({ id: eInvoiceId });

            if (res.status === 200) {
                setLoaderVisible(false);
                getInvoices().then((resp: boolean) => {
                    if (resp) {
                        setSuccess(true);
                    }
                });
            } else {
                console.error('Unexpected response status code:', res.status);
                setLoaderVisible(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    const handleEinvoicesRefresh = (): void => {
        setRefreshDateFrom(moment().startOf('year').toDate());
        setRefreshDateTo(moment().startOf('day').toDate());
        setRefreshEInvoicesModal(true);
    };

    const refreshEinvoices = async (): Promise<void> => {
        setLoaderVisible(true);
        const payload = {
            dateFrom: refreshDateFrom ? moment(refreshDateFrom).format('YYYY-MM-DD') : undefined,
            dateTo: refreshDateTo ? moment(refreshDateTo).format('YYYY-MM-DD') : undefined,
        };
        try {
            const res = await communication.refreshManyEInvoices(payload);
            if (res && res.data && res.data.total > 0) {
                const time = res.data.total * 10 - 10;
                setIntervalLoaderSeconds(time);
                setLoaderVisible(false);
                setIntervalLoaderMessage(t('pages.eInvoices.refreshEinvoiceLoading').text);
                setShowIntervalLoader(true);
                setRefreshEInvoicesModal(false);
            } else {
                toast.success('Nema eFaktura za osvežavanje.');
                setLoaderVisible(false);
                setRefreshEInvoicesModal(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setRefreshEInvoicesModal(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    const removeAllPayments = (): void => {
        setRemovePaymentsModal(false);
        setLoaderVisible(true);
        communication
            .removeAllPayments(clickedInvoice)
            .then(async (res: ObjectKeys) => {
                if (res) {
                    getInvoices().then(async () => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    //send eInvoice
    const handleSendModal = (
        invoiceId: string,
        invoiceClientEmail: Array<string>,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmails(invoiceClientEmail);
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
            `00${invoiceNumber?.replace('/', '-')}`,
            '221',
        )
            .then((res: ObjectKeys | any) => {
                if (res) {
                    setQrCode(res.i);
                    setSendModal(true);
                }
            })
            .catch((error: any) => {
                setQrCode('');
                console.error(error);
                setSendModal(true);
            });
    };
    const handleReminderModal = (invoiceId: string, invoiceClientEmail: Array<string>): void => {
        setClickedInvoice(invoiceId);
        setClientEmails(invoiceClientEmail);
        setReminderModal(true);
    };
    const handleRemind = (): void => {
        setClickedInvoice('');
        setSuccessReminder(true);
        getInvoices();
    };
    const handleSendInvoice = (): void => {
        setClickedInvoice('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //payment
    const handlePaymentModal = (invoiceId: string, paidValue: string | number, currency: string): void => {
        setCurrencyPay(currency);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
        setOpenPaymentModal(true);
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
    };

    //Add new invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/invoices/add/${invoiceType}`);
    };

    const handleUpdatePayment = (paymentId: string, invoiceId: string, paidValue: string | number): void => {
        setClickedPayment(paymentId);
        setOpenPaymentUpdateModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    // Specific Payment Delete
    const handleDeletePayment = (paymentId: string, invoiceId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteInvoiceSpecificPayment(invoiceId, paymentId)
            .then(async (res: ObjectKeys) => {
                if (res) {
                    getAllEinvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const handleAttachedMemos = (invoice: ObjectKeys): void => {
        setLoaderVisible(true);
        const updateInvoice = {
            ...invoice,
            invoice_number: invoice.real_invoice_number,
            services: invoice.services.map((service: ObjectKeys, i: number) => ({
                ...service,
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                discount: service.pivot.discount,
                pivot: {
                    discount: service.pivot.discount,
                    index: i, // Add index to pivot
                    measurement_unit: service.pivot.measurement_unit,
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    quantity: service.pivot.quantity.replace(',', '.'),
                    total_price: service.pivot.total_price, // Ensure total_price is included
                },
            })),
        };
        communication
            .editInvoice(invoice.id, updateInvoice)
            .then((res: ObjectKeys) => {
                if (res) {
                    setInvoiceToSend(res.data.data);
                    getAllEinvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const handleSelectedCard = (id: string): void => {
        const isInvoiceInArray = selectedInvoicesArray.find((selectedInvoice) => selectedInvoice === id);
        if (isInvoiceInArray) {
            const filteredInvoiceList = selectedInvoicesArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedInvoicesArray(filteredInvoiceList);
        } else {
            setSelectedInvoicesArray((prevState) => [...prevState, id]);
        }
    };

    const handleSelectAllInvoices = (isChecked: boolean) => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newInvoiceIds = eInvoiceData?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedInvoicesArray(newInvoiceIds);
        } else {
            setSelectedInvoicesArray([]);
        }
    };

    const handleDownloadInvoices = (): void => {
        getSelectedInvoicesNames();
    };

    const handleInvoicesPayment = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
    };

    const getSelectedInvoicesNames = (): string[] => {
        const invoiceNamesArray: string[] = [];
        selectedInvoicesArray.map((separateItem) => {
            const foundObject = eInvoiceData?.data?.find((obj: any) => obj.id === separateItem);

            invoiceNamesArray.push(`${foundObject.prefix ? foundObject.prefix : ''} ${foundObject.invoice_number}`);
        });
        return invoiceNamesArray;
    };

    const handleModalSubmission = (): void => {
        setLoaderVisible(true);
        setIsInvoicesModalOpen(false);
        communication
            .chargeSelectedInvoices('charge', selectedInvoicesArray)
            .then((res: ObjectKeys) => {
                if (res.status === 204) {
                    setLoaderVisible(false);
                    getInvoices();
                }
            })
            .catch((error: any) => {
                console.error('Error', error);
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        return () => {
            useInvoicesStore.setState({ eInvoiceData: [] });
        };
    }, []);

    return (
        <>
            {showIntervalLoader && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setRemovePaymentsModal(false);
                    }}
                >
                    <IntervalLoader
                        close={() => setShowIntervalLoader(false)}
                        message={intervalLoaderMessage}
                        seconds={intervalLoaderSeconds}
                        doneText={t('pages.eInvoices.refreshEinvoiceSuccess').text}
                        action={() => {
                            getInvoices();
                        }}
                    />
                </Modal>
            )}
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {openCancelModal && (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={invoiceToCancel}
                        onCancelInvoice={getInvoices}
                    />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success
                        close={() => setSuccess(false)}
                        message={successMessage ? successMessage : t('pages.eInvoices.refreshSuccess').text}
                    />
                </Modal>
            )}
            {removePaymentsModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setRemovePaymentsModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            removeAllPayments();
                        }}
                        close={() => {
                            setRemovePaymentsModal(false);
                        }}
                        active={removePaymentsModal}
                        message={t('pages.invoices.remove_payments_eInvoice').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoiceToSend}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmails={clientEmails}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        qrCode={qrCode}
                        editInvoice={(updatedInvoice: ObjectKeys) => {
                            handleAttachedMemos(updatedInvoice);
                        }}
                    />
                </Sidebar>
            )}
            {sendReminderModal && (
                <Sidebar close={() => setReminderModal(false)}>
                    <SendReminderModal
                        close={() => setReminderModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleRemind();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmails={clientEmails}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        invoice={invoiceToRemind}
                    />
                </Sidebar>
            )}
            {successReminder && (
                <Modal modalVisible={true} closeModal={() => setSuccessReminder(false)}>
                    <Success
                        close={() => setSuccessReminder(false)}
                        message={t('pages.invoices.sendModal.successReminder').text}
                    />
                </Modal>
            )}
            {openPaymentModal && (
                <Modal modalVisible={openPaymentModal} closeModal={() => setOpenPaymentModal(false)}>
                    <PaymentModal
                        setOpenPaymentModal={setOpenPaymentModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        setLoaderVisible={setLoaderVisible}
                        currency={currencyPay}
                        type={'einvoice'}
                    />
                </Modal>
            )}
            {openPaymentUpdateModal ? (
                <Modal modalVisible={openPaymentUpdateModal} closeModal={() => setOpenPaymentUpdateModal(false)}>
                    <PaymentUpdateModal
                        setOpenPaymentUpdateModal={setOpenPaymentUpdateModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        clickedPayment={clickedPayment}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={() => void 0}
                        formattedParams={formattedParams}
                        type={'einvoice'}
                    />
                </Modal>
            ) : null}
            {isInvoicesModalOpen && (
                <Modal
                    modalVisible={isInvoicesModalOpen}
                    closeModal={() => {
                        setIsInvoicesModalOpen(false);
                    }}
                >
                    <HandleInvoicesModal
                        t={t}
                        closeGlobalModal={() => {
                            setIsInvoicesModalOpen(false);
                        }}
                        selectedInvoicesInformationArray={getSelectedInvoicesNames()}
                        modalType="payment"
                        invoiceType="einvoice"
                        isModalConfirmed={handleModalSubmission}
                    />
                </Modal>
            )}
            {refreshEInvoicesModal && (
                <Modal
                    modalVisible={refreshEInvoicesModal}
                    closeModal={() => setRefreshEInvoicesModal(!refreshEInvoicesModal)}
                >
                    <ConfirmModal
                        action={() => {
                            refreshEinvoices();
                            setRefreshEInvoicesModal(false);
                        }}
                        close={() => setRefreshEInvoicesModal(false)}
                        active={refreshEInvoicesModal}
                        message={t('pages.eInvoices.multipleRefreshQuestion').text}
                    >
                        <div
                            className="dateDiv"
                            style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '0 10% 20px' }}
                        >
                            <p style={{ textAlign: 'center', marginBottom: '60px' }}>
                                {t('pages.eInvoices.multipleRefreshText').text}
                            </p>
                            <p style={{ textAlign: 'center', marginBottom: '20px' }}>
                                {t('pages.eInvoices.refreshDateRange').text}
                            </p>
                            <Input
                                type={InputComponentType.Date}
                                label={t('pages.editInvoice.previewModal.from').text}
                                className={'datepicker'}
                                date={!refreshDateFrom ? null : moment(refreshDateFrom).toDate()}
                                onChange={(value: Date) => {
                                    setRefreshDateFrom(value);
                                }}
                                maxDate={new Date()}
                                datepickerPosition="top"
                            />
                            <Input
                                type={InputComponentType.Date}
                                label={t('pages.editInvoice.previewModal.to').text}
                                className={'datepicker'}
                                date={refreshDateTo}
                                onChange={(value: Date) => {
                                    setRefreshDateTo(value);
                                }}
                                maxDate={new Date()}
                                datepickerPosition="top"
                            />
                        </div>
                    </ConfirmModal>
                </Modal>
            )}
            <PageWrapper className="e-invoices page">
                <Header isDarkTheme={isDarkTheme}>
                    <div>
                        <h1>{t('pages.eInvoices.title').text}</h1>
                        <div style={{ position: 'relative' }}>
                            <Button
                                onClick={() => {
                                    if (isAccountActive) {
                                        handleAddInvoice('domestic');
                                    }
                                }}
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.invoices.newInvoice').text}
                            </Button>
                        </div>
                    </div>
                </Header>
                {isAccountActive ? <></> : <AccountExpired />}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                    statusFilters={[
                        { value: '', label: t('pages.eInvoices.status.all').text },
                        { value: 'Sending;', label: t('pages.eInvoices.status.sending').text },
                        { value: 'Sent;', label: t('pages.eInvoices.status.sent').text },
                        { value: 'Approved;', label: t('pages.eInvoices.status.verified').text },
                        { value: 'Rejected;', label: t('pages.eInvoices.status.rejected').text },
                        { value: 'Storno;', label: t('pages.eInvoices.status.storned').text },
                    ]}
                    showLabel={t('pages.eInvoices.eInvoice').text}
                    selectedUserView={display}
                />
                <div className="eInvoiceRefresh">
                    <div onClick={() => handleEinvoicesRefresh()} className="refreshIconDiv">
                        <FontAwesomeIcon icon={faRefresh} color={colors.purple} />
                        {t('pages.eInvoices.multipleRefreshButton').text}{' '}
                        <Tooltip
                            text={t('pages.eInvoices.multipleRefreshTooltip').text}
                            isHtml={true}
                            position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                            customPositioning={{ top: '50%', right: '80%', transform: 'translateY(-50%)' }}
                            zIndex={10}
                            label={
                                <FontAwesomeIcon
                                    icon={faCircleQuestion}
                                    fontSize={'15px'}
                                    style={{ marginBottom: '0.05rem' }}
                                    color="var(--purple)"
                                />
                            }
                        />
                    </div>
                </div>
                <FilterTrack
                    onChevronClick={(name: string, order: string) => {
                        setParams({ ...params, orderBy: name, sortedBy: order });
                    }}
                    t={t}
                />
                {eInvoiceData?.data?.length > 0 && isAccountActive && (
                    <SelectAllSection>
                        <div className="selectCheckbox">
                            <Checkbox
                                onChange={(value: any) => {
                                    handleSelectAllInvoices(value);
                                }}
                                label={t('pages.agency.extra_services.select_all').text}
                                defaultChecked={isAllChecked}
                            />
                        </div>
                        {selectedInvoicesArray.length > 0 && (
                            <div className="userControlSection">
                                {/* <div className="selectionDiv" onClick={handleDownloadInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                            </div> */}
                                <div className="selectionDiv" onClick={handleInvoicesPayment}>
                                    <span>{t('pages.invoiceCard.dropdownItems.charge').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.green} icon={faCircleCheck} />
                                </div>
                            </div>
                        )}
                    </SelectAllSection>
                )}
                {showSkeleton ? (
                    Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                ) : (
                    <>
                        <InvoicesList
                            className={eInvoiceData?.data?.length === 0 ? 'noItemsClass' : `display-${display}`}
                        >
                            {eInvoiceData?.data?.length > 0 ? (
                                eInvoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                    const invoice: ObjectKeys = {
                                        ...invoicePrev,
                                        services: invoicePrev.original_data.services,
                                        client: { data: invoicePrev.original_data.client },
                                    };
                                    return (
                                        <div key={invoice.id} className={`${display}`}>
                                            <EInvoiceCard
                                                eInvoice={invoice?.is_einvoice}
                                                type={InvoiceCardComponentType.EInvoice}
                                                dropdownDirection={index > 2 ? 'up' : 'down'}
                                                cardData={invoice}
                                                cardDisplay={display}
                                                handleChange={() => handleChange(invoice.id)}
                                                handleDelete={() => handleDeleteModal(invoice)}
                                                handleCancel={() => handleCancelModal(invoice)}
                                                handleDownload={() =>
                                                    handleDownloadInvoice(
                                                        invoice?.id,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.value_in_rsd,
                                                        invoice.invoice_number,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice,
                                                    )
                                                }
                                                handlePayment={() =>
                                                    handlePaymentModal(
                                                        invoice.id,
                                                        invoice.payments
                                                            ? getRestOfPayment(invoice)
                                                            : formatNumber(
                                                                  Number(invoice.value),
                                                                  4,
                                                                  3,
                                                                  '.',
                                                                  ',',
                                                              ).toString(),
                                                        invoice.currency,
                                                    )
                                                }
                                                handleSend={() => {
                                                    setInvoiceToSend(invoice);
                                                    const mails = (() => {
                                                        if (invoice.client?.data) {
                                                            const emails =
                                                                invoice.client.data.clientEmails?.data?.map(
                                                                    (item: ObjectKeys) => item.email,
                                                                ) || [];
                                                            return emails.length > 0
                                                                ? emails
                                                                : invoice.client.data.email
                                                                ? [invoice.client.data.email]
                                                                : [];
                                                        }
                                                        return [];
                                                    })();
                                                    handleSendModal(
                                                        invoice.id,
                                                        mails,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.value_in_rsd,
                                                        invoice.invoice_number,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                    );
                                                }}
                                                handleReminder={() => {
                                                    const mails = (() => {
                                                        if (invoice.client?.data) {
                                                            const emails =
                                                                invoice.client.data.clientEmails?.data?.map(
                                                                    (item: ObjectKeys) => item.email,
                                                                ) || [];
                                                            return emails.length > 0
                                                                ? emails
                                                                : invoice.client.data.email
                                                                ? [invoice.client.data.email]
                                                                : [];
                                                        }
                                                        return [];
                                                    })();
                                                    handleReminderModal(invoice.id, mails);
                                                    setInvoiceToRemind(invoice);
                                                }}
                                                handleRefresh={() => handleRefreshEInvoice(invoice.id)}
                                                handleClick={() => handleClickedInvoice(invoice)}
                                                handleCopy={() => handleCopy(invoice.id)}
                                                handleRemovePayments={() => {
                                                    setClickedInvoice(invoice.id);
                                                    setRemovePaymentsModal(true);
                                                }}
                                                handleUpdatePayment={(e: string) => {
                                                    const specificPaymentValue = invoice?.payments?.data.filter(
                                                        (payment: ObjectKeys) => {
                                                            return payment.id === e;
                                                        },
                                                    );
                                                    handleUpdatePayment(
                                                        e,
                                                        invoice.id,
                                                        formatNumber(
                                                            Number(specificPaymentValue[0]?.value),
                                                            4,
                                                            3,
                                                            '.',
                                                            ',',
                                                        ).toString(),
                                                    );
                                                }}
                                                handleDeletePayment={(e: string) => {
                                                    handleDeletePayment(e, invoice.id);
                                                }}
                                                paymentInfo={() => void 0}
                                                checkedInvoice={handleSelectedCard}
                                                cardIdArray={selectedInvoicesArray}
                                                handleViewPDF={() =>
                                                    handleViewInvoicePDF(
                                                        invoice?.id,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.value_in_rsd,
                                                        invoice.invoice_number,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice,
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    {hasInvoices ? (
                                        <NoItems text={t('pages.invoices.filters.noResults').text} />
                                    ) : (
                                        <NoData>
                                            <p dangerouslySetInnerHTML={{ __html: noItemHtml }} />
                                            <p>
                                                {t('pages.eInvoices.noItems.third').text}{' '}
                                                <Link to="/settings/e-invoice">
                                                    {t('pages.eInvoices.noItems.link').text}
                                                </Link>
                                            </p>
                                        </NoData>
                                    )}
                                </>
                            )}
                        </InvoicesList>
                        {eInvoiceData?.data?.length > 0 && (
                            <Pagination
                                pageCount={eInvoiceData.meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                                nextLabel={`${
                                    eInvoiceData.meta?.pagination?.current_page ===
                                    eInvoiceData.meta?.pagination?.total_pages
                                        ? ''
                                        : '>'
                                }`}
                                previousLabel={`${eInvoiceData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={eInvoiceData.meta?.pagination?.current_page - 1}
                            />
                        )}
                    </>
                )}
            </PageWrapper>
        </>
    );
};
export default EInvoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
    .eInvoiceRefresh {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        gap: 16px;
        width: 100%;
    }

    .eInvoiceRefresh .datepicker {
        width: 180px;
    }

    .eInvoiceRefresh > div {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 10px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.2s ease;
    }

    .refreshIconDiv {
        margin: 5px 0;
    }

    @media (max-width: 768px) {
        .eInvoiceRefresh {
            flex-direction: column;
            align-items: flex-start;
        }

        .eInvoiceRefresh .datepicker {
            width: 100%;
        }
    }
`;

interface DarkThemeProps {
    isDarkTheme: boolean;
}

const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;
const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
        }
    }
`;
export const NoData = styled.div`
    flex: 1;
    text-align: center;
    margin: 60px 0;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
    }
    a {
        font-weight: bold;
    }
`;
