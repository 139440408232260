import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAllUsersStore from '../../../store/admin/allUsers';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Filters from '../../pausal/advanceInvoices/filters';
import DeleteModal from './deleteModal';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import { toast } from 'react-toastify';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';
import Checkbox from '../../../components/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../global/colors';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../components/modal';
import HandleInvoicesModal from '../../../pages/pausal/common/handleInvoicesModal';
import ErrorModal from '../../../components/errorModal';

const Advances: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const token = getAccessToken();
    const { allUsers } = useAllUsersStore();
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [advances, setAdvances] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);

    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState<ObjectKeys>({});

    const [selectedAdvanceInvoicesArray, setSelectedAdvanceInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isAdvanceInvoicesModalOpen, setIsAdvanceInvoicesModalOpen] = useState<boolean>(false);
    const [advanceInvoicesModalType, setAdvanceInvoicesModalType] = useState<string>('');

    const [catchErrorModal, setCatchErrorModal] = useState<boolean>(false);
    const [catchErrorMessage, setCatchErrorMessage] = useState<string>('');

    useBackOfficePasswordProtect();

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: '',
        searchFields: '',
        searchJoin: 'and',
    });

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + ' - ' + user.pib };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    const getInvoices = (): void => {
        if (selectedUserId) {
            setLoaderVisible(true);
            communication
                .getInvoicesByUserId(selectedUserId, {
                    ...params,
                    search: 'is_prepaid:1;' + formatParams(params.search),
                    searchFields: 'is_prepaid:=;' + formatParams(params.searchFields),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setAdvances(res?.data);
                        handleSelectAllAdvanceInvoices(false);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        toast.error(error?.response?.data?.message);
                        setAdvances([]);
                    }
                })
                .finally(() => {
                    setLoaderVisible(false);
                });
        }
    };

    useEffect(() => {
        if (selectedUserId) {
            getInvoices();
        }
    }, [selectedUserId, params]);

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };
    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/admin/advances/edit/${invoiceId}/${selectedUserId}`);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        const invoiceNumber = invoice.prefix ? invoice.prefix + ' ' + invoice.invoice_number : invoice.invoice_number;
        const invoiceId = invoice.id;
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={clickedInvoice}
                invoiceNumber={invoiceNumber}
                closeGlobalModal={closeGlobalModal}
                setInvoices={setAdvances}
                selectedUserId={selectedUserId}
                invoiceId={invoiceId}
                onDeleteInvoice={() => {
                    getInvoices();
                }}
            />,
        );
    };

    //Invoice Download
    const handleDownloadInvoice = (invoice: ObjectKeys): void => {
        communication.downloadInvoice(
            invoice.id,
            token,
            invoice.invoice_number,
            invoice.currency !== 'RSD',
            false,
            undefined,
        );
    };

    const handleViewPDF = (invoice: ObjectKeys): void => {
        setLoaderVisible(true);
        communication
            .printInvoice(invoice.id, token, invoice.invoice_number, invoice.currency !== 'RSD')
            .then((response: ObjectKeys) => {
                setLoaderVisible(false);
                const url = window.URL.createObjectURL(response.data);
                const printWindow = window.open(url, '_blank');
                if (printWindow) {
                    printWindow.onload = () => {
                        printWindow.focus();
                        printWindow.print();
                    };
                }
                setCatchErrorMessage('');
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error('Error downloading the invoice:', error);
                setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
                setCatchErrorModal(true);
            });
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice);
    };

    const handleDeleteAdvanceInvoices = (): void => {
        setIsAdvanceInvoicesModalOpen(true);
        getSelectedAdvanceInvoicesNames();
        setAdvanceInvoicesModalType('delete');
    };

    const handleDownloadAdvanceInvoices = (): void => {
        getSelectedAdvanceInvoicesNames();
        setAdvanceInvoicesModalType('download');
    };

    const handleAdvanceInvoicesPayment = (): void => {
        setIsAdvanceInvoicesModalOpen(true);
        getSelectedAdvanceInvoicesNames();
        setAdvanceInvoicesModalType('payment');
    };

    const getSelectedAdvanceInvoicesNames = (): string[] => {
        const advanceInvoiceNamesArray: string[] = [];
        selectedAdvanceInvoicesArray.map((separateItem) => {
            const foundObject = advances?.data?.find((obj: any) => obj.id === separateItem);

            advanceInvoiceNamesArray.push(
                `${foundObject.prefix ? foundObject.prefix : ''} ${foundObject.invoice_number}`,
            );
        });

        return advanceInvoiceNamesArray;
    };

    const handleSelectAllAdvanceInvoices = (isChecked: boolean) => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newAdvanceInvoiceIds = advances?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedAdvanceInvoicesArray(newAdvanceInvoiceIds);
        } else {
            setSelectedAdvanceInvoicesArray([]);
        }
    };
    const handleModalSubmission = (): void => {
        if (advanceInvoicesModalType === 'delete') {
            setLoaderVisible(true);
            setIsAdvanceInvoicesModalOpen(false);
            communication
                .deleteSelectedInvoices('delete', selectedAdvanceInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                    toast.error(error?.response?.data?.message);
                });
        } else if (advanceInvoicesModalType === 'payment') {
            setLoaderVisible(true);
            setIsAdvanceInvoicesModalOpen(false);
            communication
                .chargeSelectedInvoices('charge', selectedAdvanceInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                });
        }
    };
    const handleSelectedCard = (id: string): void => {
        const isAdvanceInvoiceInArray = selectedAdvanceInvoicesArray.find((selectedInvoice) => selectedInvoice === id);
        if (isAdvanceInvoiceInArray) {
            const filteredAdvanceInvoiceList = selectedAdvanceInvoicesArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedAdvanceInvoicesArray(filteredAdvanceInvoiceList);
        } else {
            setSelectedAdvanceInvoicesArray((prevState) => [...prevState, id]);
        }
    };

    return (
        <>
            {catchErrorModal && (
                <Modal modalVisible={catchErrorModal} closeModal={() => setCatchErrorModal(!catchErrorModal)}>
                    <ErrorModal t={t} errorMessage={catchErrorMessage} setOpenModal={setCatchErrorModal} />
                </Modal>
            )}
            {isAdvanceInvoicesModalOpen && (
                <Modal
                    modalVisible={isAdvanceInvoicesModalOpen}
                    closeModal={() => {
                        setIsAdvanceInvoicesModalOpen(false);
                    }}
                >
                    <HandleInvoicesModal
                        t={t}
                        closeGlobalModal={() => {
                            setIsAdvanceInvoicesModalOpen(false);
                        }}
                        selectedInvoicesInformationArray={getSelectedAdvanceInvoicesNames()}
                        modalType={advanceInvoicesModalType}
                        invoiceType="advance"
                        isModalConfirmed={handleModalSubmission}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <div className="invoices page">
                <Header>
                    <h1>{t('pages.advanceInvoices.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SearchCustomComponent
                        value={allUsersOptions.find((v) => v.value === userBOid)}
                        allUsersOptions={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            useUserStore.setState({ userBOid: data.value });
                            setSelectedUserId(data.value);
                        }}
                        placeholder={t('pages.admin.users.chooseUser').text}
                        className="user-selector"
                    />
                </SelectWrapper>
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <SelectAllSection>
                    <div className="selectCheckbox">
                        <Checkbox
                            onChange={(value: any) => {
                                handleSelectAllAdvanceInvoices(value);
                            }}
                            label={t('pages.agency.extra_services.select_all').text}
                            defaultChecked={isAllChecked}
                        />
                    </div>
                    {selectedAdvanceInvoicesArray.length > 0 && (
                        <div className="userControlSection">
                            <div className="selectionDiv" onClick={handleDeleteAdvanceInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                            </div>
                            {/* <div className="selectionDiv" onClick={handleDownloadAdvanceInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                            </div> */}
                        </div>
                    )}
                </SelectAllSection>
                <InvoicesList className={`display-${display}`}>
                    {advances?.data?.length > 0 ? (
                        advances?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.Invoice}
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice.id)}
                                        handleDelete={() => handleDeleteModal(invoice)}
                                        handleDownload={() => handleDownloadInvoice(invoice)}
                                        handleClick={() => handleClickedInvoice(invoice)}
                                        eInvoice={invoice?.is_einvoice}
                                        checkedInvoice={handleSelectedCard}
                                        cardIdArray={selectedAdvanceInvoicesArray}
                                        handleViewPDF={() => handleViewPDF(invoice)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.invoices.noItems.description').text} />
                    )}
                </InvoicesList>
                {advances?.data?.length > 0 && (
                    <Pagination
                        pageCount={advances.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            advances.meta?.pagination?.current_page === advances.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${advances.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={advances.meta?.pagination?.current_page - 1}
                        currentPage={advances.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default Advances;

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;
export const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            grid-template-columns: 1fr;
        }
    }
`;
