import { FunctionComponent } from 'react';
import styled from 'styled-components';
import NoItemsPhoto from '../../assets/pausal-logo.png';

const Loader: FunctionComponent = () => {
    return (
        <Container className="loader">
            <img className="logo" src={NoItemsPhoto} alt="photo" />
            <WarningText>Ne napuštaj stranicu dok se ne završi proces plaćanja.</WarningText>
            <WarningText>Do not leave the page until the payment process is completed.</WarningText>
        </Container>
    );
};

export default Loader;

const Container = styled.div`
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    .logo {
        animation: pulse 1s infinite;
        width: 200px;
        height: auto;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const WarningText = styled.p`
    margin-top: 20px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    font-family: 'Open Sans', sans-serif;

    animation: pulse 1s infinite;
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;
