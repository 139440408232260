import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import communication from '../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import useAgencyStore from '../../../store/agency';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import TextArea from '../../../components/input/textarea';
import { formatTwoDecimals } from '../../../functions/format';
import { useNavigate } from 'react-router-dom';
import { StepControlButton } from '../../../components/stepControlButton';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import useCartStore from '../../../store/cart';
import React from 'react';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import InfoModal from '../../../components/infoModal';

interface StepThreeProps {
    nextStep: Function;
    previousStep: Function;
}

const StepThree: React.FC<StepThreeProps> = ({ nextStep, previousStep }) => {
    const isDarkTheme = useTheme();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = getCurrentLanguage();
    const navigate = useNavigate();
    const { agency } = useAgencyStore();

    const isActiveAccount = agency.user.data.active;
    const isTrialUser = agency.user.data.trial;

    const servicesDiscount =
        isTrialUser || !isActiveAccount ? 0 : agency.user.data.payment_package.data.services_discount;

    const [_loader, setLoader] = useState<boolean>(false);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);
    const [_selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>(packages[0]);

    const cart = useCartStore((state) => state.cartData);
    const updateCart = useCartStore((state) => state.updateCart);
    const helperNote = useCartStore((s) => s.helperNote);
    const setHelperNote = useCartStore((s) => s.setHelperNote);

    const services = useCartStore((data: any) => data.servicesData);

    const [questionResponses, setQuestionResponses] = useState<Record<string, Record<string, string>>>({});

    const [requiredValidation, setRequiredValidation] = useState<boolean>(false);

    const [extractedQuestionsAndAnswers, setExtractedQuestionsAndAnswers] = useState<
        Record<string, { question: string; answer: string }[]>
    >({});

    const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [userCartNote, setUserCartNote] = useState<string>(cart?.note ?? '');

    const formattedQuestions = React.useMemo(() => {
        return Object.entries(questionResponses).flatMap(([itemId, responses]) => {
            return Object.entries(responses).map(([questionKey, answer]) => {
                const questionText =
                    cart.items.find((item: any) => item.id === itemId)?.questions?.[0]?.[
                        questionKey.replace('_answer', `_${lang}`)
                    ] || '';

                return {
                    itemId,
                    question: questionText,
                    answer,
                };
            });
        });
    }, [questionResponses, cart.items, lang]);

    useEffect(() => {
        const groupedQuestions = formattedQuestions.reduce((acc: any, item) => {
            if (item.question && item.answer) {
                if (!acc[item.itemId]) {
                    acc[item.itemId] = [];
                }
                acc[item.itemId].push({
                    answer: item.answer,
                    question: item.question,
                });
            }
            return acc;
        }, {});

        setExtractedQuestionsAndAnswers((prevState) => {
            const isSame = JSON.stringify(prevState) === JSON.stringify(groupedQuestions);
            return isSame ? prevState : groupedQuestions;
        });
    }, [formattedQuestions]);

    const handleAnswerChange = (itemId: string, questionKey: string, value: string): void => {
        const updatedResponses = {
            ...questionResponses,
            [itemId]: {
                ...(questionResponses[itemId] ?? {}),
                [questionKey]: value,
            },
        };

        setQuestionResponses(updatedResponses);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setHelperNote(extractedQuestionsAndAnswers);
            updateCart({
                ...cart,
                note: userCartNote,
            });
        }, 500);
    };

    const discountedPrice = (price: number): number => {
        const discounted = price - price * (servicesDiscount / 100);
        return discounted;
    };

    const handleServiceName = (service: ObjectKeys): string => {
        const matchedService = services.find((s: ObjectKeys) => s.id === service.id);
        return matchedService ? matchedService[`name_${lang}`] : service.name;
    };

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                const activePackages = response.data.data.filter((item: ObjectKeys) => item.active);
                setPackages(activePackages);
                setSelectedPackageOption(activePackages[0]);
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const updateQuantity = (id: number, newQuantity: number): void => {
        if (newQuantity < 1 || newQuantity > 20) return;

        const updatedItems = cart.items.map((item: ObjectKeys) =>
            item.id === id ? { ...item, quantity: newQuantity } : item,
        );

        updateCart({ ...cart, items: updatedItems });
    };

    const removeItem = (id: number): void => {
        const updatedItems = cart.items.filter((item: ObjectKeys) => item.id !== id);
        updateCart({ ...cart, items: updatedItems });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (cart && cart.items && cart.items.length < 1) {
                navigate('/services/1');
            }
        }, 3000);

        return () => clearTimeout(timeout);
    }, [cart]);

    const hasHydratedRef = useRef(false);

    useEffect(() => {
        if (hasHydratedRef.current) return;

        const hydratedResponses: Record<string, Record<string, string>> = {};

        cart?.items?.forEach((item: ObjectKeys) => {
            const itemId = item.id;
            const answers: Record<string, string> = {};

            if (item.questions && item.questions.length > 0) {
                Object.keys(item.questions[0]).forEach((key) => {
                    if (key.includes(`_${lang}`)) {
                        const baseKey = key.replace(`_${lang}`, '');
                        const answerKey = `${baseKey}_answer`;
                        const questionText = item.questions[0][key];

                        const previousAnswer = helperNote?.[itemId]?.find(
                            (q: any) => q.question === questionText,
                        )?.answer;

                        answers[answerKey] = previousAnswer || '';
                    }
                });
                hydratedResponses[itemId] = answers;
            }
        });

        setQuestionResponses(hydratedResponses);
        hasHydratedRef.current = true;
    }, [cart?.items, helperNote, lang]);

    const validateTextFields = () => {
        for (const item of cart.items) {
            if (item.questions && item.questions[0]) {
                const questionsForLang = Object.entries(item.questions[0]).filter(
                    ([key, value]) => key.endsWith(`_${lang}`) && value !== null,
                );

                for (const [questionKey] of questionsForLang) {
                    const answerKey = questionKey.replace(`_${lang}`, '_answer');
                    const answer = questionResponses[item.id]?.[answerKey];

                    if (!answer || answer.trim() === '') {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const handleUserNoteChange = (value: string): void => {
        setUserCartNote(value);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setHelperNote(extractedQuestionsAndAnswers);
            updateCart({
                ...cart,
                note: value,
            });
        }, 500);
    };

    return (
        <>
            {requiredValidation && (
                <Modal modalVisible={requiredValidation} closeModal={() => setRequiredValidation(!requiredValidation)}>
                    <InfoModal
                        message={t('pages.editInvoice.previewModal.answerServiceQuestions').text}
                        buttonText={t('buttons.ok').text}
                        setOpenModal={setRequiredValidation}
                    />
                </Modal>
            )}
            {_loader && <Loader />}
            <ContentSecondStep isDarkTheme={isDarkTheme}>
                <Title isDarkTheme={isDarkTheme}>
                    <h2>{t('pages.agency.extra_services.choosen_services').text}</h2>
                </Title>
                <div className="agency-content-wrapper">
                    <div className="leftPage">
                        <div>
                            <TableWrapper isDarkTheme={isDarkTheme}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{t('pages.agency.extra_services.service').text}</th>
                                            <th>{t('pages.agency.extra_services.quantity').text}</th>
                                            <th>{t('pages.agency.extra_services.price').text}</th>
                                            <th>{t('pages.agency.extra_services.discount_price').text}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cart?.items?.map((i: ObjectKeys) => {
                                            return (
                                                <React.Fragment key={i.id}>
                                                    <tr>
                                                        <td className="service-name">{handleServiceName(i)}</td>
                                                        <td className="marginQuantity">
                                                            <button
                                                                onClick={() => updateQuantity(i.id, i.quantity - 1)}
                                                                disabled={i.quantity <= 1}
                                                            >
                                                                -
                                                            </button>
                                                            {i.quantity}
                                                            <button
                                                                onClick={() => updateQuantity(i.id, i.quantity + 1)}
                                                            >
                                                                +
                                                            </button>
                                                        </td>
                                                        <td>{formatTwoDecimals((i.price * i.quantity).toString())}</td>
                                                        <td>
                                                            {formatTwoDecimals(
                                                                (discountedPrice(i.price) * i.quantity).toString(),
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button onClick={() => removeItem(i.id)}>✖</button>
                                                        </td>
                                                    </tr>

                                                    {i.questions?.some((question: any) =>
                                                        Object.entries(question).some(
                                                            ([key, value]) =>
                                                                key.endsWith(`_${lang}`) &&
                                                                value !== null &&
                                                                value !== '',
                                                        ),
                                                    ) && (
                                                        <tr>
                                                            <td colSpan={5} className="questionContainer">
                                                                <fieldset className="legendContainer">
                                                                    <legend className="legendText">
                                                                        {
                                                                            t('pages.agency.subscription.questionsFor')
                                                                                .text
                                                                        }{' '}
                                                                        {services?.map((service: ObjectKeys) => {
                                                                            if (service.id === i.id) {
                                                                                return service[`name_${lang}`];
                                                                            }
                                                                        })}
                                                                    </legend>
                                                                    <div className="question-group">
                                                                        {['question_1', 'question_2', 'question_3'].map(
                                                                            (questionKey) => {
                                                                                const questionText =
                                                                                    i.questions[0]?.[
                                                                                        `${questionKey}_${lang}`
                                                                                    ];
                                                                                const answer =
                                                                                    questionResponses[i.id]?.[
                                                                                        `${questionKey}_answer`
                                                                                    ] ?? '';

                                                                                return (
                                                                                    questionText && (
                                                                                        <div
                                                                                            key={`${i.id}-${questionKey}`}
                                                                                            className="conatinerDiv"
                                                                                        >
                                                                                            <p
                                                                                                className="bold"
                                                                                                style={{
                                                                                                    marginBottom:
                                                                                                        '10px',
                                                                                                }}
                                                                                            >
                                                                                                {questionText}:
                                                                                            </p>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={answer}
                                                                                                onChange={(e: any) =>
                                                                                                    handleAnswerChange(
                                                                                                        i.id,
                                                                                                        `${questionKey}_answer`,
                                                                                                        e.target.value,
                                                                                                    )
                                                                                                }
                                                                                                placeholder={
                                                                                                    t(
                                                                                                        'pages.agency.subscription.yourAnswer',
                                                                                                    ).text
                                                                                                }
                                                                                                maxLength={190}
                                                                                                className="responseTextArea"
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                );
                                                                            },
                                                                        )}
                                                                    </div>
                                                                </fieldset>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TableWrapper>
                        </div>
                        <div className="bottom-note">
                            <div className="left">{t('pages.agency.extra_services.note').text}</div>
                            <div className="note">
                                <TextArea
                                    value={userCartNote}
                                    onChange={(value: string) => {
                                        handleUserNoteChange(value);
                                    }}
                                    placeholder="Add a note"
                                    height={'75px'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rightMain">
                        <div className="rightPage">
                            <div className="amountPrice">
                                <div className="table">
                                    <div>
                                        <p>{t('pages.agency.subscription.price').text}</p>
                                        {cart?.total_price && cart?.discount ? (
                                            <p>
                                                {formatTwoDecimals((cart?.total_price + cart?.discount)?.toString())}{' '}
                                                RSD
                                            </p>
                                        ) : (
                                            <p>{formatTwoDecimals(cart?.total_price?.toString())} RSD</p>
                                        )}
                                    </div>
                                    <div>
                                        <p>{t('pages.agency.subscription.discount').text}</p>
                                        {cart?.discount ? (
                                            <p>{formatTwoDecimals(cart?.discount?.toString())} RSD</p>
                                        ) : (
                                            <p>0 RSD</p>
                                        )}
                                    </div>
                                </div>
                                <div className="total">
                                    <p>{t('pages.agency.subscription.total').text}</p>
                                    {cart?.total_price ? (
                                        <p>{formatTwoDecimals(cart.total_price.toString())} RSD</p>
                                    ) : (
                                        <p>0 RSD</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentSecondStep>
            {cart?.items?.length > 0 ? (
                <Arrows>
                    <StepControlButton
                        step={'next'}
                        handleClick={() => {
                            const isValidationPassed = validateTextFields();

                            if (!isValidationPassed) {
                                setRequiredValidation(true);
                            } else {
                                nextStep();
                                navigate('/services/4');
                            }
                        }}
                    />
                </Arrows>
            ) : null}
        </>
    );
};

interface StyledPackageProps {
    isDarkTheme?: boolean;
}

const ContentSecondStep = styled.div<StyledPackageProps>`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    .agency-content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
        }
        .rightMain {
            flex: 1;
            display: flex;
            flex-direction: column;
            max-width: 50%;
            -webkit-box-pack: justify;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
        }
        .leftPage {
            flex: 1;
            max-width: 50%;
            padding: 20px 0;
            table {
                width: 100%;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                padding: 0;
                max-width: 100%;
            }
            .packages {
                display: flex;
                gap: 5px;
                padding: 10px;
                justify-content: space-evenly;
            }
            .chooseSubscription {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px;
                font-size: 15px;
                .item {
                    padding-left: 10px;
                }
                .crossed {
                    text-decoration: line-through;
                }
            }
            .bottom-note {
                display: flex;
                flex-direction: column;
                .left {
                    text-align: left;
                    font-size: 15px;
                    color: var(--adjust);
                }
                textarea {
                    font-size: 15px;
                }
                textarea::placeholder {
                    color: var(--adjust);
                }
            }
        }
        .rightPage {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            color: white;
            height: fit-content;
            justify-content: space-between;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                max-width: 100%;
                gap: 20px;
            }
            .title {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                h2 {
                    font-size: 15px;
                    color: white;
                    margin: 10px;
                }
            }
            .table {
                width: 90%;
                padding: 20px 0;
                border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple);' : 'white)')};
                border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple);' : 'white')};
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 0;
                    p {
                        font-size: 15px;
                    }
                }
            }
            .amountPrice {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212;' : 'var(--purple)')};
                margin-top: 20px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    padding: 0;
                }
            }
            .total {
                display: flex;
                justify-content: space-between;
                width: 90%;
                padding: 20px 0;
                p {
                    font-size: 15px;
                }
            }
        }
        input[type='radio'] {
            accent-color: #9b7fe9;
            height: 25px;
            width: 20px;
            margin-bottom: 10px;
        }
        .note {
            margin-top: 10px;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
            text-align: center;
            line-height: 20px;
            border: 1px solid var(--purple);
            padding: 5px;
            width: 100%;

            .userNoteInput {
                resize: none;
                font-family: inherit;
                background: transparent;
                font-size: 16px;
                line-height: 24px;
                padding: 11px;
                letter-spacing: -0.3px;
                border-radius: 2px;
                border-bottom: 1px solid var(--border-color);
                padding: 5px;
                border: none;
                height: 75px;
                width: 100%;
                color: var(--black);
                &:focus {
                    outline: none;
                    border-bottom: 1px solid var(--purple);
                }
            }

            .userNoteInput::placeholder {
                color: var(--gray);
                font-weight: 400;
            }
        }
        .left {
            margin-top: 40px;
            text-align: center;
        }
    }
`;

const Title = styled.div<StyledPackageProps>`
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212;' : 'var(--purple)')};
    padding: 20px 10px;
    h2 {
        font-size: 20px;
        color: white;
        text-align: left;
    }
`;

const Arrows = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

const TableWrapper = styled.div<StyledPackageProps>`
    overflow: auto;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 12px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--purple);
    }

    table {
        margin-top: 20px;
        text-align: left;
        border-collapse: collapse;
        color: var(--adjust);

        thead {
            border-bottom: 1px solid #ccc;
            th {
                padding-right: 20px;
                min-width: 120px;
                padding-bottom: 10px;
                font-size: 15px;
                @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                    min-width: auto;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #ccc;

                td {
                    padding: 10px 40px 10px 0;
                    line-height: 1.2;
                    font-size: 15px;

                    button {
                        width: 20px;
                        cursor: pointer;
                        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : 'transparent')};
                        border: none;
                        font-size: 16px;
                        color: ${({ isDarkTheme }) => (isDarkTheme ? '#9b7fe9' : 'var(--gray)')};
                    }

                    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
                        text-align: center;
                        padding-right: 0;
                    }
                }

                .questionContainer {
                    padding-bottom: 0;
                }

                .marginQuantity {
                    display: flex;
                    gap: 10px;
                    padding: 0;
                    min-height: 60px;
                    justify-content: center;
                    align-items: center;

                    button {
                        font-size: 18px;
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        background-color: var(--purple);
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: 0.2s;

                        &:hover {
                            background-color: var(--purple);
                        }
                    }

                    @media screen and (min-width: ${breakpoints.laptop}px) {
                        min-height: 70px;
                    }

                    @media screen and (min-width: ${breakpoints.laptopL}px) {
                        min-height: 60px;
                    }
                }

                .question-group {
                    display: flex;
                    flex-direction: column;

                    .conatinerDiv {
                        display: flex;
                        flex-direction: column;

                        p {
                            text-align: left;
                            padding-left: 5px;
                        }

                        input {
                            background-color: ${({ isDarkTheme }) => (isDarkTheme ? 'transparent' : '#fff')};
                        }

                        input::placeholder {
                            color: #afaeae;
                        }
                    }
                }
            }
        }
    }

    .responseTextArea {
        resize: none;
        font-family: inherit;
        background: ${({ isDarkTheme }) => (isDarkTheme ? '#333' : 'transparent')};
        font-size: 16px;
        line-height: 24px;
        padding: 11px;
        letter-spacing: -0.3px;
        border-radius: 2px;
        border-bottom: 1px solid var(--border-color);
        padding: 5px;
        border: none;
        height: 50px;
        width: 100%;
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#e0e0e0' : 'var(--black)')};
        margin-bottom: 10px;

        &:focus {
            outline: none;
            border-bottom: 1px solid var(--purple);
        }

        &::placeholder {
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#bbb' : 'var(--gray)')};
            font-weight: 400;
        }
    }

    .legendContainer {
        border: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? '#444' : 'var(--purple)')};
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 10px;

        p {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .responseTextArea {
            width: 100%;
            min-height: 50px;
            border-radius: 6px;
            padding: 8px;
            border: 1px solid rgb(204, 204, 204);
            font-size: 14px;
            transition: 0.2s;
        }

        .responseTextArea:focus {
            border: 1px solid var(--purple);
        }
    }

    .legendText {
        font-size: 14px;
        font-weight: bold;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--purple)')};
    }
`;

export default StepThree;
