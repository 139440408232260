import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import useTheme from '../../hooks/useTheme';

interface Props {
    close: Function;
    action: Function;
    message?: string;
    active?: boolean;
    className?: string;
    children?: string | any;
    confirmText?: string;
    rejectText?: string;
}

const ConfirmModal: FunctionComponent<Props> = ({ close, action, message, children, confirmText, rejectText }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const isDarkTheme = useTheme();
    return (
        <>
            {message && (
                <TextContainer>
                    <h3>{message}</h3>
                </TextContainer>
            )}
            <div>{children}</div>
            <ButtonContainer isDarkTheme={isDarkTheme}>
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => action()} size={'100%'}>
                    {confirmText ? confirmText : t('input.yes').text}
                </Button>
                <Button variant={ButtonVariant.solid} color={'var(--purple)'} onClick={() => close()} size={'100%'}>
                    {rejectText ? rejectText : t('input.no').text}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default ConfirmModal;

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div<DarkThemeProps>`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
    button:last-child {
        /* background-color: var(--white);
        color: var(--black); */
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-right: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
        border-left: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : '#fff')};
    }
`;
