import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import useGeneralStore from '../../../../store/general';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import Loader from '../../../../components/loader';
import useYearsStore from '../../../../store/years';
import { toast } from 'react-toastify';

type Props = {
    t: Function;
    clickedInvoice: ObjectKeys;
    closeGlobalModal: () => void;
    onDeleteInvoice: (confirmed: boolean) => void;
};

const DeleteModal: FunctionComponent<Props> = ({ t, clickedInvoice, closeGlobalModal, onDeleteInvoice }) => {
    const { setGlobalModal } = useGeneralStore();
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    const handleDeleteInvoice = (invoiceId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteInvoice(invoiceId)
            .then((res: ObjectKeys) => {
                closeGlobalModal();
                toast.success(res.data);
                updateYears();
            })
            .then(() => {
                onDeleteInvoice(true);
                setLoaderVisible(false);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            });
    };
    return (
        <DeleteModalWrapper>
            {loaderVisible && <Loader />}
            <ModalText>
                {clickedInvoice.is_prepaid ? (
                    clickedInvoice.status !== 'open' ? (
                        <>
                            <h4>{`${t('pages.advanceInvoices.deleteModal.title2').text} ${
                                clickedInvoice.prefix ?? ''
                            } ${clickedInvoice.invoice_number}?`}</h4>
                        </>
                    ) : (
                        <h4>{t('pages.advanceInvoices.deleteModal.title1').text}</h4>
                    )
                ) : clickedInvoice.status !== 'open' ? (
                    <>
                        <h4>{`${t('pages.invoices.deleteModal.title1').text} ${clickedInvoice.prefix ?? ''} ${
                            clickedInvoice.invoice_number
                        }?`}</h4>
                        <h5>{t('pages.invoices.deleteModal.description').text}</h5>
                    </>
                ) : (
                    <h4>{t('pages.invoices.deleteModal.title2').text}</h4>
                )}
            </ModalText>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleDeleteInvoice(clickedInvoice.id);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </DeleteModalWrapper>
    );
};

const DeleteModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--border-color);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;

export default DeleteModal;
