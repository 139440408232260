import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import colors from '../../global/colors';
import keysToBottomUnderscore from '../../functions/keysToUnderscore';
import useTranslations from '../../hooks/useTranslation';
import useEmailValidation from '../../hooks/useEmailValidation';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import useLanguageStore from '../../store/language';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import useProfileStore from '../../store/profile';
import initFetch from '../../functions/init';
import communication from '../../communication';
import Input, { InputComponentType } from '../../components/input';
import Button, { ButtonVariant } from '../../components/button';
import Modal from '../../components/modal';
import ErrorModal from '../../components/errorModal';
import Loader from '../../components/loader';
import CityPhoto from '../../assets/city.png';
import Logo from '../../assets/logo.png';
import RusImg from '../../assets/rus.png';
import SadImg from '../../assets/sad.png';
import SrbImg from '../../assets/srb.png';
import SelectLang from '../../components/selectLangLogin';
import { queryClient } from '../../App';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';
import InstagramLogo from '../../assets/instagram.png';
import FacebookLogo from '../../assets/facebook.png';
import LinkedinLogo from '../../assets/linkedin.png';
import XLogo from '../../assets/twitter.png';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '../../assets/google.png';

const Login: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const lang = localStorage.language;
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const navigate = useNavigate();
    const { isTabletAndDown, isMobile } = useResponsive();

    const flagUrl = lang === 'English' ? SadImg : lang === 'Русский' ? RusImg : SrbImg;
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [openModal, setOpenModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [clickedField, setClickedField] = useState('');

    const isEmailValid = useEmailValidation(formData.email);
    const isPasswordValid = usePasswordValidation(formData.password);

    const params = useParams();
    const isAccountActivated = params?.activated && params.activated === 'activated' ? true : false;
    const [isMounted, setIsMounted] = useState(false);

    const [modalHasBeenSeen, setModalHasBeenSeen] = useState<boolean>(false);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const [isBannerVisible, setIsBannerVisible] = useState(true);

    document.documentElement.setAttribute('data-theme', 'light');

    // Handle Google Login
    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse: any) => {
            setLoaderVisible(true);
            try {
                // Send the access token to your backend for verification
                const res = await communication.googleLogin({ oauth_token: codeResponse.access_token });

                const data = keysToBottomUnderscore(res?.data);
                localStorage.setItem('access_token', res?.data?.token);
                localStorage.setItem('role', res?.data?.roles[0]);
                if (data.roles[0] !== 'admin') {
                    useUserStore.setState({ userInfo: agency?.user?.data });
                    useProfileStore.setState({ profile: agency });
                    const userInfo = await initFetch();
                    if (userInfo.active) {
                        navigate('/dashboard');
                    } else {
                        navigate('/invoices');
                    }
                } else {
                    await initFetch();
                    navigate('/admin/admin-dashboard');
                }
                setLoaderVisible(false);
            } catch (error: any) {
                setLoaderVisible(false);
                console.error('Google Login Error:', error);
                toast.error(error.response.data.message);
            }
        },
        onError: () => {
            toast.error('Google prijava nije uspela, pokušajte ponovo.');
        },
    });

    const login = async (): Promise<void> => {
        setShowValidationMessage(true);
        setLoaderVisible(true);
        if (isEmailValid === '' && isPasswordValid === '') {
            try {
                queryClient.removeQueries();
                const res = await communication.login({ email: formData.email, password: formData.password });
                const data = keysToBottomUnderscore(res?.data);

                localStorage.setItem('access_token', data.response_content.access_token);
                localStorage.setItem('refresh_token', data.response_content.refresh_token);
                localStorage.setItem('role', data.roles[0]);
                if (data.roles[0] !== 'admin') {
                    useUserStore.setState({ userInfo: agency?.user?.data });
                    useProfileStore.setState({ profile: agency });
                    const userInfo = await initFetch();
                    if (userInfo.active) {
                        navigate('/dashboard');
                    } else {
                        navigate('/invoices');
                    }
                } else {
                    await initFetch();
                    navigate('/admin/admin-dashboard');
                }
            } catch (error: any) {
                setLoaderVisible(false);
                if (error?.response?.status === 422) {
                    toast.error(
                        currentLangName === 'English'
                            ? error.response.data.message
                            : error.response.data.errors.email[0],
                    );
                } else if (error?.response?.status === 409) {
                    setErrorMessage(error.response.data.message);
                    setOpenModal(true);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        }
        setLoaderVisible(false);
    };

    useEffect(() => {
        useAgencyStore.setState({ agency: {} });

        setIsMounted(true);

        const modalAppearanceStatus = sessionStorage.getItem('modalHasBeenSeen');
        if (!modalAppearanceStatus) {
            setModalHasBeenSeen(false);
            sessionStorage.setItem('modalHasBeenSeen', 'true');
        } else {
            setModalHasBeenSeen(true);
        }
    }, []);

    useEffect(() => {
        if (isMounted && isAccountActivated && t && !modalHasBeenSeen) {
            toast.success(t('pages.confirmEmail.validationMessages.success').text);
        }
    }, [isMounted, isAccountActivated, modalHasBeenSeen]);

    return (
        <>
            {/* {isBannerVisible && <Banner onClose={() => setIsBannerVisible(false)} />} */}
            {openModal ? (
                <Modal modalVisible={openModal} closeModal={() => setOpenModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenModal} errorMessage={errorMessage} />
                </Modal>
            ) : (
                <></>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="login page">
                <div className="lang-selector">
                    <img src={flagUrl} alt="photo" width={'16px'} height={'11px'} key={localStorage.language} />
                    <SelectLang color={'black'} />
                </div>

                <LoginInner>
                    <LeftColumn>
                        <div className="content">
                            <h1>{t('pages.login.leftColumn.title').text}</h1>
                            <h3>{t('pages.login.leftColumn.subtitle').text}</h3>
                        </div>
                        <div className="callToAction">
                            <div className="orderAService">
                                <span>{t('pages.login.rightColumn.noAccount').text}</span>
                                <div className="serviceButtons">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.white}
                                        onClick={() => navigate('/register')}
                                        size={isMobile ? 160 : 200}
                                        height={40}
                                    >
                                        {t('pages.login.rightColumn.tryForFree').text}
                                    </Button>
                                    <a
                                        href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2TdR9gvWnPNIsichnNmBhntosgqTbIdSiJLOXdCq_EO2tcq86b-B4myoxAyh2ICnW05dddHzcv"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button
                                            variant={ButtonVariant.solid}
                                            color={colors.white}
                                            size={isMobile ? 160 : 200}
                                            height={40}
                                        >
                                            {t('pages.login.rightColumn.orderADemo').text}
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <div className="orderAService">
                                <span>{t('pages.login.rightColumn.serviceWithNoReg').text}</span>
                                <div className="serviceButtons">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.white}
                                        onClick={() => navigate('/guest/1')}
                                        size={isMobile ? 160 : 200}
                                        height={40}
                                    >
                                        {t('pages.login.rightColumn.orderService').text}
                                    </Button>
                                </div>
                            </div>
                            <div className="socialMedia">
                                <span>{t('pages.login.leftColumn.followUs').text}</span>
                                <a href="https://www.instagram.com/pausalrs/" target="_blank" rel="noreferrer">
                                    <img className="socialImage" src={InstagramLogo} alt="instagram" />
                                </a>
                                <a href="https://facebook.com/pausalrs" target="_blank" rel="noreferrer">
                                    <img className="socialImage" src={FacebookLogo} alt="facebook" />
                                </a>
                                <a href="https://www.linkedin.com/showcase/pausalrs/" target="_blank" rel="noreferrer">
                                    <img className="socialImage" src={LinkedinLogo} alt="linkedin" />
                                </a>
                                <a href="https://x.com/Pausalrs" target="_blank" rel="noreferrer">
                                    <img className="socialImageX" src={XLogo} alt="x social" />
                                </a>
                            </div>
                        </div>
                    </LeftColumn>

                    <RightColumn>
                        <div className="content">
                            <div className="top-block">
                                <h4>{t('pages.login.rightColumn.title').text}</h4>
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="form">
                                <Input
                                    type={InputComponentType.Text}
                                    label={t('pages.login.rightColumn.form.email').text}
                                    validation={showValidationMessage || clickedField === 'email' ? isEmailValid : ''}
                                    value={formData.email}
                                    blurHandler={() => handleValidation('email')}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, email: value });
                                    }}
                                    dataCy="email-input"
                                />
                                <Input
                                    type={InputComponentType.Password}
                                    label={t('pages.login.rightColumn.form.password').text}
                                    validation={
                                        showValidationMessage || clickedField === 'password' ? isPasswordValid : ''
                                    }
                                    value={formData.password}
                                    onEnter={login}
                                    blurHandler={() => handleValidation('password')}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, password: value });
                                    }}
                                    dataCy="password-input"
                                />
                                <div className="forgot-password-link">
                                    <Link to="/forgot-password">
                                        <span>{t('pages.login.rightColumn.form.forgotPasswordLink').text}</span>
                                    </Link>
                                </div>
                                <div className="login-btn">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        className="big"
                                        // size={200}
                                        onClick={login}
                                        dataCy="login-button"
                                    >
                                        {t('buttons.login').text}
                                    </Button>
                                </div>

                                <div className="or-section">
                                    <div className="line"></div>
                                    <span className="or-text">{t('pages.login.rightColumn.or').text}</span>
                                    <div className="line"></div>
                                </div>

                                <div className="google-login-btn">
                                    <Button
                                        variant={ButtonVariant.outlined}
                                        color={'var(--purple)'}
                                        onClick={() => {
                                            googleLogin();
                                        }}
                                        className="googleSignInButton"
                                    >
                                        <img src={GoogleIcon} alt="google logo" />{' '}
                                        <span>{t('buttons.googleSignIn').text}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </RightColumn>
                </LoginInner>
            </PageWrapper>
        </>
    );
};
export default Login;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    background: linear-gradient(to right, #9b7fe9, #ebdaff);

    min-height: 100vh;
    /* height: 100vh; */
    .lang-selector {
        position: absolute;
        top: 30px;
        right: 55px;
        z-index: 100;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;
        height: 20px;
        gap: 20px;
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 111.1vh;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        overflow: auto;

        .lang-selector {
            top: 15px;
            right: 45px;
            width: 100px;
            margin-left: auto;
        }
    }
`;
const LoginInner = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 8% 40px 0 40px;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        justify-content: space-around;
        flex-direction: column-reverse;
        gap: 30px;
    }

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 111.1vh;
        padding: 0 40px;
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: ${breakpoints.desktop}px) {
        height: 100vh;
        padding: 0 40px;
        justify-content: center;
        align-items: center;
    }
`;
const LeftColumn = styled.div`
    background: transparent;
    color: var(--white);
    flex: 2 1 60%;
    display: flex;
    flex-direction: column;
    padding-left: 180px;

    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        padding-left: 0;
    }

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding-bottom: 30px;
    }
    @media only screen and (max-width: ${breakpoints.laptop - 1}px) {
        padding-left: 140px;
    }
    @media only screen and (max-width: ${breakpoints.mobileL}px) {
        padding-left: 0px;
        padding-bottom: 100px;
    }

    .content {
        max-width: 650px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 40px;
        h1 {
            margin-bottom: 30px;
            font-size: 30px;
            max-width: 450px;
        }
        h3 {
            line-height: 1.2;
            font-size: 16px;
            max-width: 450px;
        }
        @media only screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
            max-width: 850px;
        }
        @media screen and (max-width: ${breakpoints.laptopL - 1}px) {
            h1 {
                font-size: 28px;
            }

            h3 {
                font-size: 18px;
            }
        }
        @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
            h1 {
                margin-top: 15px;
            }
        }
        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            margin-right: 0;
        }
    }

    .callToAction {
        display: flex;
        flex-direction: column;

        .orderAService {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 30px;

            span {
                font-size: 17px;
                font-weight: 600;
            }

            .serviceButtons {
                display: flex;
                flex-direction: row;
                gap: 25px;
                button {
                    font-size: 15px;
                    color: #9b7fe9;
                    box-shadow: -1px 9px 7px -5px rgba(0, 0, 0, 0.75);
                }

                @media screen and (max-width: ${breakpoints.mobileL - 1}px) {
                    flex-direction: column;
                    button {
                        width: 100%;
                    }
                }
            }
        }

        .socialMedia {
            display: flex;
            height: 85px;

            span {
                margin-top: auto;
                font-size: 20px;
                margin-right: 10px;
                padding-bottom: 8px;
            }
            a {
                margin-top: auto;
                margin-left: 10px;
            }

            .socialImage,
            .socialImageX {
                width: 35px;
                height: 35px;
                padding: 3px;
                border-radius: 5px;
                cursor: pointer;
                background-color: var(--white);
                color: var(--purple);
                box-shadow: rgba(0, 0, 0, 0.35) -1px 9px 7px -5px;
                transition: opacity 0.3s ease;
            }

            .socialImageX {
                padding: 5px;
            }

            .socialImage:hover,
            .socialImageX:hover {
                opacity: 0.7;
            }

            @media screen and (max-width: ${breakpoints.laptopL - 1}px) {
                height: 90px;
            }
            @media screen and (max-width: ${breakpoints.laptopM - 1}px) {
                height: 90px;
            }
            @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
                span {
                    font-size: 12px;
                    white-space: nowrap;
                }
            }
            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                .socialImage,
                .socialImageX {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
`;
const RightColumn = styled.div`
    background: transparent;
    flex: 2 1 40%;
    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: ${MQ_BREAKPOINTS.mobileL};
        //Media Queries
        @media only screen and (max-width: ${breakpoints.laptop}px) {
            margin: 20px auto 0 auto;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 160px;
            padding: 20px 45px;
            //Media Queries
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                padding: 30px;
                height: 130px;
            }

            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
                flex-direction: column-reverse;
                align-items: center;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px;
            //Media Queries
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                padding: 30px;
            }
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
                padding: 10px;
            }
            .input-container {
                width: 100%;
            }
            .forgot-password-link {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
                a {
                    color: var(--purple);
                }
            }
            .login-btn {
                display: flex;
                justify-content: center;

                button {
                    height: 45px;
                    width: 100%;

                    @media screen and (min-width: ${breakpoints.tablet}px) {
                        width: 80%;
                    }
                }
            }

            .or-section {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0;
                margin: 20px 0;

                @media screen and (min-width: ${breakpoints.tablet}px) {
                    padding: 0 10%;
                }
            }

            .line {
                flex: 1;
                height: 1px;
                background-color: var(--gray);
                margin: 0 10px;
            }

            .or-text {
                font-size: 16px;
                color: var(--gray);
                font-weight: 600;
                white-space: nowrap;
            }

            .google-login-btn {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                .googleSignInButton {
                    display: flex;
                    gap: 7px;
                    text-transform: none;
                    color: #4c595f;
                    border: 1px solid #92a5ac;
                    height: 45px;
                    width: 100%;

                    @media screen and (min-width: ${breakpoints.tablet}px) {
                        width: 80%;
                    }

                    font-weight: 550;
                    img {
                        height: 1.3rem;
                        width: 1.3rem;
                    }
                }
            }
        }
        .big {
            min-width: auto;

            @media screen and (max-width: ${breakpoints.laptop - 1}px) {
                margin-top: 0;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 20px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //Media Queries
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                padding: 20px 30px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                span {
                    margin-bottom: 10px;
                }
            }
            span {
                color: var(--gray);
                margin-right: 15px;
            }
        }
    }
`;
