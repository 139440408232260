import { useQuery } from '@tanstack/react-query';
import communication from '../communication';
import { isStatusSuccess } from '../communication/handlers/handleRequest';
import { GET_NotificationsRequest } from '../communication/notifications/types';
import { queryClient } from '../App';
import { getAccessToken } from '../functions/auth';

const fetchNotifications = async (params: GET_NotificationsRequest) => {
    try {
        const response = await communication.getUserNotifications(params);
        const unreadCountResponse = await communication.getUnreadCountNotf();
        if (isStatusSuccess(response.status) && isStatusSuccess(unreadCountResponse.status)) {
            return { ...response.data, unreadNotificationsCount: unreadCountResponse.data[1] };
        } else {
            throw new Error('Failed to fetch notifications');
        }
    } catch (error) {
        console.error(error);
    }
};

export const useNotificationsQuery = (params: GET_NotificationsRequest) => {
    const invalidate = (): Promise<void> => {
        return queryClient.invalidateQueries({ queryKey: ['notifications'] });
    };

    const remove = (): void => {
        return queryClient.removeQueries({ queryKey: ['notifications'] });
    };

    const accessToken = getAccessToken();

    const {
        data: notifications,
        refetch,
        isLoading,
        isFetching,
        isRefetching,
        error,
    } = useQuery({
        queryKey: ['notifications', params],
        queryFn: () => fetchNotifications(params),
        enabled: !!accessToken,
        refetchInterval: 1000 * 60 * 5,
        staleTime: 1000 * 60 * 5,
    });

    return { notifications, refetch, isLoading, isFetching, error, invalidate, isRefetching, remove };
};
