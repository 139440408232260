import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../../components/sideModal';
import Loader from '../../../components/loader';
import Button, { ButtonVariant } from '../../../components/button';
import FindModal from './findModal';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';

const AdminPayments: FunctionComponent = () => {
    const [loader, setLoader] = useState<boolean>(false);

    const [findModal, setFindModal] = useState(false);

    useBackOfficePasswordProtect();

    return (
        <>
            {loader && <Loader />}
            {findModal && (
                <Sidebar close={() => setFindModal(false)}>
                    <FindModal
                        close={() => setFindModal(false)}
                        success={() => void 0}
                        loading={(e: boolean) => setLoader(e)}
                        errorMessage={(mess: string) => {
                            console.error(mess);
                        }}
                        modalVisible={findModal}
                    />
                </Sidebar>
            )}
            <Header>
                <h1>Plaćanja</h1>
                <Google>
                    <Button
                        textTransformNone
                        variant={ButtonVariant.outlined}
                        color={'var(--purple)'}
                        onClick={() => {
                            setFindModal(true);
                        }}
                        size={'100%'}
                    >
                        Pretraga po Cart ID
                    </Button>
                </Google>
            </Header>

            <Content>
                <h2>Podaci</h2>
            </Content>
        </>
    );
};

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
`;
const Google = styled.div`
    width: 250px;
`;
const Content = styled.div`
    margin-top: 20px;
    h2 {
        background-color: var(--purple);
        color: white;
        padding: 10px;
        border-radius: 5px;
    }
    .analytics {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        h3 {
            font-size: 1.2em;
            margin-bottom: 5px;
        }
    }
`;

export default AdminPayments;
