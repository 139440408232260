import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightToFile,
    faCircleCheck,
    faCircleQuestion,
    faDownload,
    faPlus,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useProfileStore from '../../../store/profile';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import { formatNumber } from '../../../functions/format';
import Button, { ButtonVariant } from '../../../components/button';
import InvoiceCard from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import Success from '../../../components/successModal';
import Conditions from '../../../components/conditionsModal';
import getRestOfPayment from '../../../components/invoiceCard/getRestOfPayment';
import ResponseModal from '../common/responseModal';
import ErrorModal from '../../../components/errorModal';
import { ErrorObject, SuccessObject } from '../common/responseModal';
import Filters from './filters';
import PaymentModal from './paymentModal';
import PaymentUpdateModal from './paymentUpdateModal';
import CancelModal from '../common/cancelModal';
import EInvoiceModal from '../common/eInvoiceModal';
import DeleteModal from '../common/deleteModal';
import SendDocumentModal from '../common/sendModal';
import SendReminderModal from '../common/sendReminderModal';
import NoEInvoiceApiModal from '../common/noEinvoiceApiModal';
import useYearsStore from '../../../store/years';
import useInvoicesStore from '../../../store/invoices';
import useAgencyStore from '../../../store/agency';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../components/confirmModal';
import InvoiceSkeleton from '../common/skeleton/invoiceCardSkeleton';
import { MQ_BREAKPOINTS, breakpoints } from '../../../constants/breakpoints';
import FilterTrack from '../common/filterTrack';
import useInvoiceParamsStore from '../../../store/invoiceParams';
import getAllInvoices from './getAllInvoices';
import countTotal from '../common/countTotal';
import moment from 'moment';
import AccountExpired from '../../../components/accountExpired';
import Tooltip from '../../../components/tooltipMain';
import useResponsive from '../../../hooks/responsive/useResponsive';
import Checkbox from '../../../components/checkbox';
import HandleInvoicesModal from '../common/handleInvoicesModal';
import useTheme from '../../../hooks/useTheme';
import { formatTwoDecimals } from '../../../functions/format';

const Invoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { invoiceData, desiredCardView } = useInvoicesStore();
    const { agency } = useAgencyStore();
    const isAdmin = profile?.role === 'admin' ? true : false;

    const [hasReadPaymentConditions, setHasReadPaymentConditions] = useState(
        agency.user ? agency.user.data.has_read_payment_conditions : false,
    );

    const [removePaymentsModal, setRemovePaymentsModal] = useState(false);

    const confirmConditions = async (): Promise<void> => {
        if (isAdmin) return;
        setLoaderVisible(true);
        setHasReadPaymentConditions(true);
        const formData = {
            has_read_payment_conditions: true,
        };
        if (formData) {
            const res = await communication.updateUser(formData, agency.user.data.id);
            if (res.status === 200) {
                await communication.getAgency().then((response: ObjectKeys) => {
                    useAgencyStore.setState({
                        agency: response?.data?.data,
                    });
                });
            }
        }
        setLoaderVisible(false);
    };

    const token = getAccessToken();
    const isAccountActive = isAdmin ? true : profile?.active === 1 ? true : false;

    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEInvoiceModal, setOpenEInvoiceModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [sendReminderModal, setReminderModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successInvoice, setSuccessInvoice] = useState(false);
    const [successReminder, setSuccessReminder] = useState(false);
    const [successEinvoice, setSuccessEinvoice] = useState(false);
    const [clientEmails, setClientEmails] = useState<Array<string>>([]);
    const [invoicePaidValue, setInvoicePaidValue] = useState<string | number>('');
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadErrorMessage, setUploadErrorMessage] = useState<string | ErrorObject[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | SuccessObject[]>([]);
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);
    const { isDesktopLargeAndDown } = useResponsive();
    const lang = localStorage.language;
    const [catchErrorModal, setCatchErrorModal] = useState<boolean>(false);
    const [catchErrorMessage, setCatchErrorMessage] = useState<string>('');

    const [invoiceToCancel, setInvoiceToCancel] = useState<ObjectKeys>({});
    const [invoiceToEinvoice, setInvoiceToEinvoice] = useState<ObjectKeys>({});
    const [invoiceToRemind, setInvoiceToRemind] = useState<ObjectKeys>({});
    const [invoiceToSend, setInvoiceToSend] = useState<ObjectKeys>({});

    const [qrCode, setQrCode] = useState('');
    const [currencyPay, setCurrencyPay] = useState('');

    const [display, setDisplay] = useState(desiredCardView);
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [clickedPayment, setClickedPayment] = useState('');

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const tooltipUpload = t('pages.invoices.upload_tooltip').text;
    const tooltipDownloadExampleExcelFile = t('pages.invoices.download_invoice_example_toolip').text;

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);

    const [selectedInvoicesArray, setSelectedInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false);
    const [invoicesModalType, setInvoicesModalType] = useState<string>('');

    const isDarkTheme = useTheme();

    const [params, setParams] = useState({
        limit: 10,
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
        orderBy: '',
        sortedBy: '',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const formattedParams = useMemo(() => formatParams(params.search), [params]);
    const updateYears = async (): Promise<void> => {
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });
    };

    useEffect(() => {
        getInvoices();
    }, [JSON.stringify(params), formattedParams]);

    useEffect(() => {
        useInvoicesStore.setState({ desiredCardView: display });
    }, [display]);

    //Invoice Change
    const handleChange = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        if (invoice.status === 'paid' || invoice.status === 'partially') {
            setRemovePaymentsModal(true);
        } else {
            navigate(`/invoices/edit/${invoice.id}`);
        }
    };

    //Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        navigate(`/invoices/copy/${invoiceId}`);
    };

    //Invoice Cancel Modal
    const handleCancelModal = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
        setOpenCancelModal(true);
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
        isForeign: boolean,
    ): void => {
        if (isForeign) {
            communication.downloadInvoice(
                invoiceId,
                token,
                invoice.invoice_number,
                invoice.currency !== 'RSD',
                false,
                undefined,
            );
        } else {
            generateQrCode(
                agency_data.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res)
                        communication.downloadInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            res.i ? res.i : undefined,
                        );
                })
                .catch((error: ObjectKeys) => {
                    communication.downloadInvoice(
                        invoiceId,
                        token,
                        invoice.invoice_number,
                        invoice.currency !== 'RSD',
                        false,
                        undefined,
                    );
                    console.error(error);
                });
        }
    };

    //Refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);

        try {
            const res = await communication.refreshEInvoice({ id: eInvoiceId });

            if (res.status === 200) {
                setLoaderVisible(false);
                getInvoices().then((resp: boolean) => {
                    if (resp) {
                        setSuccess(true);
                    }
                });
            } else {
                console.error('Unexpected response status code:', res.status);
                setLoaderVisible(false);
            }
        } catch (error: any) {
            setLoaderVisible(false);
            setErrorMessage(error.response.data.message);
            setErrorModal(true);
        }
    };

    const getInvoices = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            setShowSkeleton(true);
            const finalParams = {
                ...params,
                search: 'is_prepaid:0;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            };
            useInvoiceParamsStore.setState({ invoices_params: finalParams });
            setIsAllChecked(false);
            setSelectedInvoicesArray([]);
            communication
                .getInvoices(finalParams)
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        if (res.data?.data?.length > 0) {
                            setHasInvoices(true);
                        }
                        useInvoicesStore.setState({ invoiceData: res.data });
                        resolve(true);
                        setTimeout(function () {
                            setShowSkeleton(false);
                            setLoaderVisible(false);
                        }, 1000);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setShowSkeleton(false);
                        useInvoicesStore.setState({ invoiceData: [] });
                        reject(false);
                    }
                });
        });
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEInvoiceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEInvoiceModal = async (invoice: ObjectKeys): Promise<void> => {
        setInvoiceToEinvoice(invoice);
        if (agency.e_invoice_api_key) handleOpenEInvoiceModal(invoice.id);
        else setNoEInvoiceApiModal(true);
    };

    const handlePaymentModal = (invoiceId: string, paidValue: string | number, currency: string): void => {
        setCurrencyPay(currency);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
        setOpenPaymentModal(true);
    };

    const handleClickedInvoice = (invoice: ObjectKeys): void => {
        setClickedInvoice(invoice.id);
        setInvoiceToCancel(invoice);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoice: ObjectKeys): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={invoice}
                closeGlobalModal={closeGlobalModal}
                onDeleteInvoice={() => {
                    setHasInvoices(false);
                    getInvoices().then(() => {
                        updateYears();
                    });
                }}
            />,
        );
    };

    const handleSendModal = (
        invoiceId: string,
        invoiceClientEmail: Array<string>,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        isForeign: boolean,
    ): void => {
        setClickedInvoice(invoiceId);
        setClientEmails(invoiceClientEmail);
        if (isForeign) {
            setQrCode('');
            setSendModal(true);
        } else {
            generateQrCode(
                agency_data.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res) {
                        setQrCode(res.i);
                        setSendModal(true);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setQrCode('');
                    console.error(error);
                    setSendModal(true);
                });
        }
    };

    const handleReminderModal = (
        invoiceId: string,
        invoiceClientEmail: Array<string>,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        isForeign: boolean,
    ): void => {
        setClickedInvoice(invoiceId);
        setClientEmails(invoiceClientEmail);
        if (isForeign) {
            setQrCode('');
            setReminderModal(true);
        } else {
            generateQrCode(
                agency_data.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res) {
                        setQrCode(res.i);
                        setReminderModal(true);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setQrCode('');
                    console.error(error);
                    setReminderModal(true);
                });
        }
    };

    const handleSendInvoice = (): void => {
        setClickedInvoice('');
        setSuccessInvoice(true);
    };

    const handleRemind = (): void => {
        setClickedInvoice('');
        setSuccessReminder(true);
        getInvoices();
    };

    const removeAllPayments = (): void => {
        setRemovePaymentsModal(false);
        setLoaderVisible(true);
        communication
            .removeAllPayments(clickedInvoice)
            .then(async (res: ObjectKeys) => {
                if (res.status === 204) {
                    navigate(`/invoices/edit/${clickedInvoice}`);
                    setLoaderVisible(false);
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/invoices/add/${invoiceType}`);
    };

    const handleUpdatePayment = (paymentId: string, invoiceId: string, paidValue: string | number): void => {
        setClickedPayment(paymentId);
        setOpenPaymentUpdateModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    // Specific Payment Delete
    const handleDeletePayment = (paymentId: string, invoiceId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteInvoiceSpecificPayment(invoiceId, paymentId)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const update = async (): Promise<any> => {
        setLoaderVisible(true);
        try {
            const updateInvoice = {
                ...invoiceToSend,
                auto_increment: true,
                cancel_number: false,
                cancelled: false,
                custom_number: false,
                custom_text: false,
                invoice_date: moment(invoiceToSend.invoice_date).format('YYYY-MM-DD').toString(),
                invoice_number: invoiceToSend.real_invoice_number,
                invoice_per_owner: true,
                is_custom: false,
                is_prepaid: undefined,
                paid_date: moment(invoiceToSend.paid_date).format('YYYY-MM-DD').toString(),
                prefix: invoiceToSend.prefix ? invoiceToSend.prefix : null,
                rate: Number(invoiceToSend.rate),
                sef_invoice_number: invoiceToSend.sef_invoice_number ? invoiceToSend.sef_invoice_number : null,
                services: invoiceToSend.services.map((service: ObjectKeys, i: number) => ({
                    ...service,
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: Number(service.pivot.quantity.replace(',', '.')),
                    price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                    discount: service.pivot.discount,
                    pivot: {
                        discount: service.pivot.discount,
                        index: i, // Add index to pivot
                        measurement_unit: service.pivot.measurement_unit,
                        price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                        quantity: service.pivot.quantity.replace(',', '.'),
                        total_price: service.pivot.total_price, // Ensure total_price is included
                        description: service.pivot.description,
                    },
                })),
                memorandums: invoiceToSend.memorandums?.data?.map((memo: ObjectKeys) => memo.id) || [],
                trading_date: moment(invoiceToSend.trading_date).format('YYYY-MM-DD').toString(),
                value: Number(countTotal(invoiceToSend.services).replace(',', '.')),
                value_in_rsd: Number(countTotal(invoiceToSend.services).replace(',', '.')),
            };

            const res = await communication.editInvoice(invoiceToSend.id, updateInvoice);

            if (res) {
                setLoaderVisible(false);
                navigate('/invoices');
                return res?.data?.data;
            }
        } catch (error: any) {
            setLoaderVisible(false);
            console.error(error.response.data.message);
            setErrorMessage(error.response.data.message);
            setSendModal(false);
            setErrorModal(true);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fileInput = event.target; // Reference to the input element
        const file = fileInput.files?.[0];

        if (file) {
            const validMimeTypes = [
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
            ];

            if (!validMimeTypes.includes(file.type)) {
                setWrongTypeModal(true);
                fileInput.value = ''; // Clear the input value
                return;
            }
            setWrongTypeModal(false);
            setLoaderVisible(true);
            communication
                .importInvoices(agency.user.data.id, file)
                .then((res: ObjectKeys) => {
                    setLoaderVisible(false);
                    if (res && res.data && res.status === 200) {
                        if (res?.data?.success_rows?.length > 0) {
                            const successMessages = res.data.success_rows;
                            setSuccessMessage(successMessages);
                            const errorMessages = res.data.errors;
                            setUploadErrorMessage(errorMessages);
                            getInvoices();
                            toast.success('Invoices imported successfully');
                        } else if (res && res.data && res.data.errors && res.data.errors.length > 0) {
                            const errorMessages = res.data.errors;
                            setUploadErrorMessage(errorMessages);
                            const successMessages = res.data.success_rows;
                            setSuccessMessage(successMessages);
                        } else if (res?.data?.success_rows?.length === 0 && res.data.errors.length === 0) {
                            setUploadErrorMessage('');
                            setSuccessMessage('');
                        }
                        setResponseModal(true);
                    } else {
                        setErrorMessage(t('pages.clients.modal.form.failedUpload').text);
                        setErrorModal(true);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setLoaderVisible(false);
                    console.error(error);
                    setErrorMessage(t('pages.clients.modal.form.failedUpload').text);
                    setErrorModal(true);
                })
                .finally(() => {
                    setLoaderVisible(false);
                    fileInput.value = '';
                });
        }
    };

    const handleEdit8milionLimit = (invoice: ObjectKeys, limit: boolean): void => {
        setLoaderVisible(true);
        const updateInvoice = {
            ...invoice,
            limit8_million_exclude: limit,
            invoice_number: invoice.real_invoice_number,
            services: invoice.services.map((service: ObjectKeys, i: number) => ({
                ...service,
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                discount: service.pivot.discount,
                pivot: {
                    discount: service.pivot.discount,
                    index: i, // Add index to pivot
                    measurement_unit: service.pivot.measurement_unit,
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    quantity: service.pivot.quantity.replace(',', '.'),
                    total_price: service.pivot.total_price, // Ensure total_price is included
                },
            })),
            memorandums: invoice.memorandums?.data?.map((memo: ObjectKeys) => memo.id) || [],
        };
        communication
            .editInvoice(invoice.id, updateInvoice)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const handleAttachedMemos = (invoice: ObjectKeys): void => {
        setLoaderVisible(true);
        const updateInvoice = {
            ...invoice,
            invoice_number: invoice.real_invoice_number,
            services: invoice.services.map((service: ObjectKeys, i: number) => ({
                ...service,
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                discount: service.pivot.discount,
                pivot: {
                    discount: service.pivot.discount,
                    index: i, // Add index to pivot
                    measurement_unit: service.pivot.measurement_unit,
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                    quantity: service.pivot.quantity.replace(',', '.'),
                    total_price: service.pivot.total_price, // Ensure total_price is included
                },
            })),
        };
        communication
            .editInvoice(invoice.id, updateInvoice)
            .then((res: ObjectKeys) => {
                if (res) {
                    setInvoiceToSend(res.data.data);
                    getAllInvoices().then(() => {
                        setLoaderVisible(false);
                    });
                }
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                toast.error(error.response.data.message);
            });
    };

    const triggerFileInput = (): void => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const triggerExampleInvoiceDownload = (): void => {
        let fileName = 'Invoice_Import_TemplateSRB.xlsx';
        if (lang === 'Srpski') {
            fileName = 'Invoice_Import_TemplateSRB.xlsx';
        } else if (lang === 'English') {
            fileName = 'Invoice_Import_TemplateENG.xlsx';
        } else if (lang === 'Русский') {
            fileName = 'Invoice_Import_TemplateRUS.xlsx';
        }

        const filePath = `${process.env.PUBLIC_URL}/${fileName}`;
        const link = document.createElement('a');
        link.href = filePath;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSelectedCard = (id: string): void => {
        const isInvoiceInArray = selectedInvoicesArray.find((selectedInvoice) => selectedInvoice === id);
        if (isInvoiceInArray) {
            const filteredInvoiceList = selectedInvoicesArray.filter((oneInvoice) => oneInvoice !== id);
            setSelectedInvoicesArray(filteredInvoiceList);
        } else {
            setSelectedInvoicesArray((prevState) => [...prevState, id]);
        }
    };

    const handleSelectAllInvoices = (isChecked: boolean): void => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newInvoiceIds = invoiceData?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedInvoicesArray(newInvoiceIds);
        } else {
            setSelectedInvoicesArray([]);
        }
    };

    const handleDeleteInvoices = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
        setInvoicesModalType('delete');
    };

    // const handleDownloadInvoices = (): void => {
    //     getSelectedInvoicesNames();
    //     setInvoicesModalType('download');
    // };

    const handleInvoicesPayment = (): void => {
        setIsInvoicesModalOpen(true);
        getSelectedInvoicesNames();
        setInvoicesModalType('payment');
    };

    const getSelectedInvoicesNames = (): string[] => {
        const invoiceNamesArray: string[] = [];
        selectedInvoicesArray.map((separateItem) => {
            const foundObject = invoiceData?.data?.find((obj: any) => obj.id === separateItem);

            invoiceNamesArray.push(`${foundObject.prefix ? foundObject.prefix : ''} ${foundObject.invoice_number}`);
        });
        return invoiceNamesArray;
    };

    const handleModalSubmission = (): void => {
        if (invoicesModalType === 'delete') {
            setLoaderVisible(true);
            setIsInvoicesModalOpen(false);
            communication
                .deleteSelectedInvoices('delete', selectedInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        toast.success(t('pages.invoices.handleInvoicesModal.invoiceDeleteSuccess').text);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);

                    const errorMessages =
                        error?.response?.data?.errors?.length > 0
                            ? error.response.data.errors[0]
                            : error.response.data.message;

                    setErrorMessage(errorMessages);

                    if (error?.response?.status === 422) {
                        const realErrorMessage = error?.response?.data?.errors;

                        const firstKey: any = Object.keys(realErrorMessage || {})[0];
                        const message = realErrorMessage?.[firstKey]?.[0] || 'An error occurred';
                        setErrorMessage(message);
                        getInvoices();
                    }

                    setErrorModal(true);
                });
        } else if (invoicesModalType === 'payment') {
            setLoaderVisible(true);
            setIsInvoicesModalOpen(false);
            communication
                .chargeSelectedInvoices('charge', selectedInvoicesArray)
                .then((res: ObjectKeys) => {
                    if (res.status === 204) {
                        setLoaderVisible(false);
                        // toast.success(t('pages.invoices.handleInvoicesModal.invoicePaymentSuccess').text);
                        getInvoices();
                    }
                })
                .catch((error: any) => {
                    console.error('Error', error);
                    setLoaderVisible(false);
                });
        }
    };

    const handleViewInvoicePDF = (
        invoiceId: string,
        agency_data: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
        isForeign: boolean,
    ): void => {
        if (isForeign) {
            communication
                .printInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD', false, undefined)
                .then((secondResponse: ObjectKeys) => {
                    const url = window.URL.createObjectURL(secondResponse.data);
                    const printWindow = window.open(url, '_blank');
                    if (printWindow) {
                        printWindow.onload = () => {
                            printWindow.focus();
                            printWindow.print();
                        };
                    }
                    setCatchErrorMessage('');
                })
                .catch((error: ObjectKeys) => {
                    setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
                    setCatchErrorModal(true);
                });
        } else {
            generateQrCode(
                agency_data.full_name,
                clientData.company_name,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                invoiceNumber ? `00${invoiceNumber.replace('/', '-')}` : null,
                '221',
            )
                .then((res: ObjectKeys | any) => {
                    if (res)
                        communication
                            .printInvoice(
                                invoiceId,
                                token,
                                invoice.invoice_number,
                                invoice.currency !== 'RSD',
                                false,
                                res.i ? res.i : undefined,
                            )
                            .then((secondResponse: ObjectKeys) => {
                                const url = window.URL.createObjectURL(secondResponse.data);
                                const printWindow = window.open(url, '_blank');
                                if (printWindow) {
                                    printWindow.onload = () => {
                                        printWindow.focus();
                                        printWindow.print();
                                    };
                                }
                                setCatchErrorMessage('');
                            })
                            .catch((error: ObjectKeys) => {
                                setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
                                setCatchErrorModal(true);
                            });
                })
                .catch((error: ObjectKeys) => {
                    communication
                        .printInvoice(
                            invoiceId,
                            token,
                            invoice.invoice_number,
                            invoice.currency !== 'RSD',
                            false,
                            undefined,
                        )
                        .then((res: ObjectKeys) => {
                            const url = window.URL.createObjectURL(res.data);
                            const printWindow = window.open(url, '_blank');
                            if (printWindow) {
                                printWindow.onload = () => {
                                    printWindow.focus();
                                    printWindow.print();
                                };
                            }
                            setCatchErrorMessage('');
                        })
                        .catch((secondError: ObjectKeys) => {
                            setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
                            setCatchErrorModal(true);
                        });
                    console.error(error);
                    setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
                    setCatchErrorModal(true);
                });
        }
    };

    const handleIssueInvoice = async (id: string): Promise<void> => {
        setLoaderVisible(true);
        try {
            const res = await communication.issueInvoice(id);
            if (res) {
                setLoaderVisible(false);
                navigate('/invoices');
                getInvoices();
                setCatchErrorMessage('');
            }
        } catch (error: any) {
            console.error('Error issuing the invoice:', error);
            setCatchErrorMessage(error.response?.data?.message || 'An error occurred');
            setCatchErrorModal(true);
            setLoaderVisible(false);
        }
    };

    useEffect(() => {
        return () => {
            useInvoicesStore.setState({ invoiceData: [] });
        };
    }, []);

    return (
        <>
            {removePaymentsModal && (
                <Modal
                    modalVisible={removePaymentsModal}
                    closeModal={() => setRemovePaymentsModal(!removePaymentsModal)}
                >
                    <ConfirmModal
                        action={async () => {
                            setLoaderVisible(true);
                            setRemovePaymentsModal(false);
                            removeAllPayments();
                        }}
                        close={() => {
                            setRemovePaymentsModal(false);
                        }}
                        active={removePaymentsModal}
                        message={t('warnings.changingInvoiceWarning').text}
                    />
                </Modal>
            )}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={t('pages.clients.modal.form.invalidFileType').text}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {responseModal && (
                <Modal modalVisible={responseModal} closeModal={() => setResponseModal(false)}>
                    <ResponseModal
                        t={t}
                        setOpenModal={setResponseModal}
                        errorMessages={uploadErrorMessage ? uploadErrorMessage : ''}
                        successMessages={successMessage ? successMessage : ''}
                    />
                </Modal>
            )}

            {isInvoicesModalOpen && (
                <Modal
                    modalVisible={isInvoicesModalOpen}
                    closeModal={() => {
                        setIsInvoicesModalOpen(false);
                    }}
                >
                    <HandleInvoicesModal
                        t={t}
                        closeGlobalModal={() => {
                            setIsInvoicesModalOpen(false);
                        }}
                        selectedInvoicesInformationArray={getSelectedInvoicesNames()}
                        modalType={invoicesModalType}
                        invoiceType="invoice"
                        isModalConfirmed={handleModalSubmission}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        invoice={invoiceToSend}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            getInvoices();
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmails={clientEmails}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        handleUpdateInvoice={invoiceToSend.status === 'open' ? update : undefined}
                        modalVisible={sendModal}
                        qrCode={qrCode}
                        editInvoice={(updatedInvoice: ObjectKeys) => {
                            handleAttachedMemos(updatedInvoice);
                        }}
                    />
                </Sidebar>
            )}
            {sendReminderModal && (
                <Sidebar close={() => setReminderModal(false)}>
                    <SendReminderModal
                        close={() => setReminderModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleRemind();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmails={clientEmails}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                        invoice={invoiceToRemind}
                        qrCode={qrCode}
                    />
                </Sidebar>
            )}
            {openPaymentModal ? (
                <Modal modalVisible={openPaymentModal} closeModal={() => setOpenPaymentModal(false)}>
                    <PaymentModal
                        setOpenPaymentModal={setOpenPaymentModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={() => void 0}
                        currency={currencyPay}
                        type={'invoice'}
                    />
                </Modal>
            ) : null}
            {openPaymentUpdateModal ? (
                <Modal modalVisible={openPaymentUpdateModal} closeModal={() => setOpenPaymentUpdateModal(false)}>
                    <PaymentUpdateModal
                        setOpenPaymentUpdateModal={setOpenPaymentUpdateModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        clickedPayment={clickedPayment}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={() => void 0}
                        formattedParams={formattedParams}
                        type={'invoice'}
                    />
                </Modal>
            ) : null}
            {openCancelModal ? (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={invoiceToCancel}
                        onCancelInvoice={() => {
                            getInvoices();
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openEInvoiceModal ? (
                <Modal modalVisible={openEInvoiceModal} closeModal={() => setOpenEInvoiceModal(false)}>
                    <EInvoiceModal
                        t={t}
                        setOpenEInvoiceModal={setOpenEInvoiceModal}
                        clickedInvoice={invoiceToEinvoice}
                        onEinvoiceSent={() => {
                            setSuccessEinvoice(true);
                            getInvoices().then(() => {
                                updateYears();
                            });
                        }}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {noEInvoiceApiModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setNoEInvoiceApiModal(false);
                    }}
                >
                    <NoEInvoiceApiModal
                        saveChanges={() => navigate('/settings/e-invoice')}
                        close={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                        message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                    />
                </Modal>
            )}
            {!hasReadPaymentConditions && !isAdmin && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        return;
                    }}
                >
                    <Conditions close={() => confirmConditions()} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                </Modal>
            )}
            {successInvoice && (
                <Modal modalVisible={true} closeModal={() => setSuccessInvoice(false)}>
                    <Success close={() => setSuccessInvoice(false)} message={t('pages.invoices.sentSuccess').text} />
                </Modal>
            )}
            {successReminder && (
                <Modal modalVisible={true} closeModal={() => setSuccessReminder(false)}>
                    <Success
                        close={() => setSuccessReminder(false)}
                        message={t('pages.invoices.sendModal.successReminder').text}
                    />
                </Modal>
            )}
            {successEinvoice && (
                <Modal modalVisible={true} closeModal={() => setSuccessEinvoice(false)}>
                    <Success close={() => setSuccessEinvoice(false)} message={t('pages.eInvoices.sentSuccess').text} />
                </Modal>
            )}

            {catchErrorModal && (
                <Modal modalVisible={catchErrorModal} closeModal={() => setCatchErrorModal(!catchErrorModal)}>
                    <ErrorModal t={t} errorMessage={catchErrorMessage} setOpenModal={setCatchErrorModal} />
                </Modal>
            )}

            {loaderVisible && <Loader />}
            <PageWrapper className="invoices page">
                <Header isDarkTheme={isDarkTheme}>
                    <div>
                        <h1>{t('pages.invoices.title').text}</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color="var(--purple)"
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.invoices.newInvoice').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.invoice').text,
                                                handler: () => handleAddInvoice('domestic'),
                                            },
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.foreign').text,
                                                handler: () => handleAddInvoice('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                    {isAccountActive && (
                        <div className="userInteraction">
                            <div className="uploadInvoice">
                                <Icon onClick={triggerFileInput}>
                                    <FontAwesomeIcon
                                        icon={faArrowRightToFile}
                                        fontSize={'15px'}
                                        color={isDarkTheme ? '#ffffff' : 'var(--purple)'}
                                    />
                                </Icon>
                                <p onClick={triggerFileInput}>{t('pages.invoices.upload_tooltip_header').text}</p>
                                <Tooltip
                                    text={tooltipUpload}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '40%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color="var(--purple)"
                                        />
                                    }
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                    accept=".pdf,.doc,.docx,.xlsx,.csv" // Specify accepted file types
                                />
                            </div>
                            <div className="downloadInvoice">
                                <Icon onClick={triggerExampleInvoiceDownload}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        fontSize={'15px'}
                                        color={isDarkTheme ? '#ffffff' : 'var(--purple)'}
                                    />
                                </Icon>
                                <p onClick={triggerExampleInvoiceDownload}>
                                    {t('pages.invoices.download_invoice_example').text}
                                </p>
                                <Tooltip
                                    text={tooltipDownloadExampleExcelFile}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '50%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color="var(--purple)"
                                        />
                                    }
                                />
                            </div>
                        </div>
                    )}
                </Header>
                {isAccountActive ? <></> : <AccountExpired />}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                    selectedUserView={display}
                />
                <FilterTrack
                    onChevronClick={(name: string, order: string) => {
                        setParams({ ...params, orderBy: name, sortedBy: order });
                    }}
                    t={t}
                />
                {invoiceData?.data?.length > 0 && isAccountActive && (
                    <SelectAllSection>
                        <div className="selectCheckbox">
                            <Checkbox
                                onChange={(value: any) => {
                                    handleSelectAllInvoices(value);
                                }}
                                label={t('pages.agency.extra_services.select_all').text}
                                defaultChecked={isAllChecked}
                            />
                        </div>
                        {selectedInvoicesArray.length > 0 && (
                            <div className="userControlSection">
                                <div className="selectionDiv" onClick={handleDeleteInvoices}>
                                    <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                </div>
                                {/* <div className="selectionDiv" onClick={handleDownloadInvoices}>
                                <span>{t('pages.invoiceCard.dropdownItems.download').text}</span>
                                <FontAwesomeIcon className="icon" color={colors.blue} icon={faDownload} />
                            </div> */}
                                <div className="selectionDiv" onClick={handleInvoicesPayment}>
                                    <span>{t('pages.invoiceCard.dropdownItems.charge').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.green} icon={faCircleCheck} />
                                </div>
                            </div>
                        )}
                    </SelectAllSection>
                )}
                {showSkeleton ? (
                    Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                ) : (
                    <>
                        <InvoicesList
                            className={invoiceData?.data?.length === 0 ? 'noItemsClass' : `display-${display}`}
                        >
                            {invoiceData?.data?.length > 0 ? (
                                invoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                    const invoice: ObjectKeys = {
                                        ...invoicePrev,
                                        services: invoicePrev.original_data.services,
                                        client: { data: invoicePrev.original_data.client },
                                    };
                                    // Get client updated emails
                                    const clientMails: Array<string> = (() => {
                                        if (invoicePrev.client?.data) {
                                            const emails =
                                                invoicePrev.client.data.clientEmails?.data?.map(
                                                    (item: ObjectKeys) => item.email,
                                                ) || [];
                                            return emails.length > 0
                                                ? emails
                                                : invoicePrev.client.data.email
                                                ? [invoicePrev.client.data.email]
                                                : [];
                                        }
                                        return [];
                                    })();
                                    return (
                                        <div key={invoice.id} className={`${display}`}>
                                            <InvoiceCard
                                                eInvoice={invoice?.is_einvoice}
                                                type={'invoice'}
                                                dropdownDirection={index > 1 ? 'up' : 'down'}
                                                cardData={invoice}
                                                cardDisplay={display}
                                                handleChange={() => handleChange(invoice)}
                                                handleCopy={() => handleCopy(invoice.id)}
                                                handleDelete={() => handleDeleteModal(invoice)}
                                                handleCancel={() => handleCancelModal(invoice)}
                                                handleDownload={() =>
                                                    handleDownloadInvoice(
                                                        invoice?.id,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice,
                                                        invoice.type === 'foreign',
                                                    )
                                                }
                                                handleEInvoice={() => !invoice.eInvoice && handleEInvoiceModal(invoice)}
                                                handlePayment={() => {
                                                    handlePaymentModal(
                                                        invoice.id,
                                                        invoice.payments
                                                            ? getRestOfPayment(invoice)
                                                            : formatTwoDecimals(invoice.value).toString(),
                                                        invoice.currency,
                                                    );
                                                }}
                                                handleSend={() => {
                                                    setInvoiceToSend(invoice);
                                                    handleSendModal(
                                                        invoice.id,
                                                        clientMails,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice.type === 'foreign',
                                                    );
                                                }}
                                                handleReminder={() => {
                                                    handleReminderModal(
                                                        invoice.id,
                                                        clientMails,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice.type === 'foreign',
                                                    );
                                                    setInvoiceToRemind(invoice);
                                                }}
                                                handleClick={() => handleClickedInvoice(invoice)}
                                                handleRefresh={() => {
                                                    handleRefreshEInvoice(invoice.id);
                                                }}
                                                handleUpdatePayment={(e: string) => {
                                                    const specificPaymentValue = invoice?.payments?.data.filter(
                                                        (payment: ObjectKeys) => {
                                                            return payment.id === e;
                                                        },
                                                    );
                                                    handleUpdatePayment(
                                                        e,
                                                        invoice.id,
                                                        formatNumber(
                                                            Number(specificPaymentValue[0]?.value),
                                                            4,
                                                            3,
                                                            '.',
                                                            ',',
                                                        ).toString(),
                                                    );
                                                }}
                                                statusFilter={params?.search}
                                                handleDeletePayment={(e: string) => {
                                                    handleDeletePayment(e, invoice.id);
                                                }}
                                                paymentInfo={() => void 0}
                                                handle8millionLimit={(value: boolean) => {
                                                    handleEdit8milionLimit(invoice, value);
                                                }}
                                                checkedInvoice={handleSelectedCard}
                                                cardIdArray={selectedInvoicesArray}
                                                handleViewPDF={() =>
                                                    handleViewInvoicePDF(
                                                        invoice?.id,
                                                        invoice?.original_data?.agency,
                                                        invoice?.client?.data,
                                                        invoice.total_for_payment_in_rsd,
                                                        `${invoice.prefix ? invoice.prefix : ''}${
                                                            invoice.invoice_number
                                                        }`,
                                                        invoice.bank_account,
                                                        invoice.currency,
                                                        true,
                                                        invoice,
                                                        invoice.type === 'foreign',
                                                    )
                                                }
                                                issueInvoice={() => handleIssueInvoice(invoice?.id)}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    {hasInvoices ? (
                                        <NoItems text={t('pages.invoices.filters.noResults').text} />
                                    ) : (
                                        <NoItems text={t('pages.invoices.noItems.description').text} />
                                    )}
                                </>
                            )}
                        </InvoicesList>
                        {invoiceData?.data?.length > 0 && (
                            <Pagination
                                pageCount={invoiceData.meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => {
                                    setParams({ ...params, page: e.selected + 1 });
                                }}
                                nextLabel={`${
                                    invoiceData.meta?.pagination?.current_page ===
                                    invoiceData.meta?.pagination?.total_pages
                                        ? ''
                                        : '>'
                                }`}
                                previousLabel={`${invoiceData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={invoiceData.meta?.pagination?.current_page - 1}
                            />
                        )}
                    </>
                )}
            </PageWrapper>
        </>
    );
};
export default Invoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
`;

interface DarkThemeProps {
    isDarkTheme: boolean;
}

const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }

    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        margin-bottom: 0;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }

    .userInteraction {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .uploadInvoice,
    .downloadInvoice {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin-right: 10px;
            font-size: 12px;
            line-height: 15px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : 'var(--purple)')};
            cursor: pointer;
        }

        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            grid-template-columns: 1fr;
        }
    }
`;

const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
`;

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
