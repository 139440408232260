import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEllipsisVertical, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import useTranslations from '../../hooks/useTranslation';
import useOutsideClick from '../../hooks/useOutsideClick';
import useLanguageStore from '../../store/language';
import { ObjectKeys } from '../../types/objectKeys';
import { getNumberByClientType, getNumberNameByClientType } from './getByClientType';
import Dropdown from '../dropdown';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';
import useTheme from '../../hooks/useTheme';
import Checkbox from '../checkbox';

interface Props {
    className?: string;
    handleChange?: Function;
    handleDelete?: Function;
    handleActivate?: Function;
    handleClick: Function;
    cardData: ObjectKeys;
    cardType: string;
    selectedClientCard?: Function;
    clientIdArray?: string[] | [];
}

const ClientCard: FunctionComponent<Props> = ({
    className,
    handleChange,
    handleDelete,
    handleActivate,
    handleClick,
    cardData,
    cardType,
    selectedClientCard,
    clientIdArray,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);

    const { isTabletAndDown } = useResponsive();

    const isDarkTheme = useTheme();

    return (
        <ContentWrapper>
            <CheckboxSection onClick={(e: any) => e.stopPropagation()}>
                <Checkbox
                    onChange={() => {
                        selectedClientCard && selectedClientCard(cardData.id);
                    }}
                    defaultChecked={clientIdArray?.find((separateId) => separateId === cardData.id) ? true : false}
                />
            </CheckboxSection>
            <StyledClientCard isDarkTheme={isDarkTheme} key={cardData.id} className={`card ${className ?? ''}`.trim()}>
                <CardContent className="card-content cursor-pointer">
                    <InfoWrapper className="info-wrapper" isDarkTheme={isDarkTheme}>
                        <div className="name-col">
                            <div className="label">{t('pages.clientCard.infoWrapper.name').text}</div>
                            <div className="name">{cardData?.company_name ? cardData?.company_name : '/'}</div>
                        </div>
                        {cardData.type !== null && (
                            <div className="number-col">
                                <div className="label">
                                    {cardData?.is_foreign
                                        ? t('pages.clientCard.infoWrapper.taxNumber').text
                                        : t(`pages.clientCard.infoWrapper.${getNumberNameByClientType(cardData)}`).text}
                                </div>
                                <div className="number">
                                    {cardData?.is_foreign ? cardData.pib : getNumberByClientType(cardData)}
                                </div>
                            </div>
                        )}
                        <div className="address-col">
                            <div className="label">{t('pages.clientCard.infoWrapper.address').text}</div>
                            <div className="address">{cardData?.address ? cardData?.address : '/'}</div>
                        </div>
                        <div className="city-col">
                            <div className="label">{t('pages.clientCard.infoWrapper.city').text}</div>
                            <div className="city">{cardData?.city ? cardData?.city : '/'}</div>
                        </div>
                    </InfoWrapper>
                    <ActionWrapper>
                        <div className="label"></div>
                        <div className="action">
                            {isTabletAndDown ? (
                                <Action>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVertical}
                                        style={{ color: colors.gray }}
                                        className="cursor-pointer dots"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClick(cardData?.id);
                                            setDropdownVisible(!dropdownVisible);
                                        }}
                                    />
                                    {dropdownVisible && (
                                        <DropdownContainer
                                            className={`${cardType === 'archived' ? 'for-archived' : ''}`.trim()}
                                            ref={ref}
                                            onClick={handleClickOutside}
                                        >
                                            <Dropdown
                                                arrowRight="4px"
                                                itemList={
                                                    cardType !== 'archived'
                                                        ? [
                                                              {
                                                                  icon: faPenToSquare,
                                                                  iconColor: colors.blue,
                                                                  label: t('pages.clientCard.dropdownItems.change')
                                                                      .text,
                                                                  handler: handleChange,
                                                              },
                                                              {
                                                                  icon: faTrashCan,
                                                                  iconColor: colors.danger,
                                                                  label: t('pages.clientCard.dropdownItems.archive')
                                                                      .text,
                                                                  handler: handleDelete,
                                                              },
                                                          ]
                                                        : [
                                                              {
                                                                  label: t('pages.clientCard.dropdownItems.activate')
                                                                      .text,
                                                                  handler: handleActivate,
                                                              },
                                                          ]
                                                }
                                            />
                                        </DropdownContainer>
                                    )}
                                </Action>
                            ) : (
                                <ActionWithoutDropdown>
                                    {handleChange !== undefined ? (
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faPenToSquare}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleChange && handleChange(e)}
                                            />
                                            <div className="label">
                                                {t('pages.clientCard.dropdownItems.change').text}
                                            </div>
                                        </Icon>
                                    ) : (
                                        <></>
                                    )}
                                    {cardType === 'archived' ? (
                                        <p className="activate" onClick={(e) => handleActivate && handleActivate(e)}>
                                            {t('pages.clientCard.dropdownItems.activate').text}
                                        </p>
                                    ) : (
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                style={{ color: colors.danger }}
                                                onClick={(e) => handleDelete && handleDelete(e)}
                                            />
                                            <div className="label">
                                                {t('pages.clientCard.dropdownItems.archive').text}
                                            </div>
                                        </Icon>
                                    )}
                                </ActionWithoutDropdown>
                            )}
                        </div>
                    </ActionWrapper>
                </CardContent>
            </StyledClientCard>
        </ContentWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const ContentWrapper = styled.div`
    display: flex;
`;

const StyledClientCard = styled.div<DarkThemeProps>`
    margin-bottom: 10px;
    padding: 16px 20px;
    width: 100%;

    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px ${({ isDarkTheme }) => (isDarkTheme ? 'var(--body-gray)' : '#eee')};
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        align-items: start;
    }
`;

const CheckboxSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 23.8px;
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 8fr 1fr;

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
const InfoWrapper = styled.div<DarkThemeProps>`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    gap: 5px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        line-height: 25px;
    }
`;
const ActionWrapper = styled.div`
    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;
const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;
const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        height: 120px;
        overflow-y: auto;
    }
    &.for-archived {
        .dropdown-container {
            height: 80px;
        }
    }
`;
const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 10px;
    .activate {
        text-transform: uppercase;
        color: var(--purple);
        font-size: 13px;
        line-height: 1.3;
    }
`;
const Icon = styled.div`
    position: relative;

    .label {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-50%, -50%);
        padding: 10px;
        border-radius: 4px;
        z-index: 9;
        font-size: 15px;
    }

    &:hover {
        .label {
            opacity: 1;
            transition: opacity 0.5s;
            /* background: var(--gray);
            color: var(--white); */
            z-index: 1;
        }
    }
`;
export default ClientCard;
