import axios, { AxiosResponse } from 'axios';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

const printInvoice = async (
    id: string | any,
    token: string,
    invoiceNo: string,
    foreign: boolean,
    proforma?: boolean,
    qr_code?: string | null,
): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('token', token);
    if (qr_code && !foreign) {
        formData.append('qr_code', `data:image/jpeg;base64,${qr_code}`);
    }

    return axios({
        url: proforma ? routes.proforma.downloadProforma(id) : routes.invoice.downloadInvoice(id),
        method: 'POST',
        responseType: 'blob',
        data: formData,
        headers: {
            'Accept-Language': !foreign ? getCurrentLanguage() : 'en',
            'Content-Type': 'multipart/form-data',
        },
    });
};

export default printInvoice;
