import { ObjectKeys } from '../types/objectKeys';
import fetch from './fetch';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Service {
    public getAllArticles(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.service,
            params,
        });
    }
    public getArchivedArticles(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.archived,
            params,
        });
    }
    public activateArticles(articlesObject: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.activateArticles,
            data: articlesObject,
        });
    }
    public archiveArticles(articlesObject: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.archiveArticles,
            data: articlesObject,
        });
    }
    public createArticle(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.service,
            data: options,
        });
    }
    public editArticle(id: string, data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.byId(id),
            data: data,
        });
    }
    public activateArticle(id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.activate(id),
        });
    }
    public deleteArticle(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.services.byId(id),
        });
    }
}
