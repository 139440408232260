import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useChangeModalStore from '../../store/changeModal';
import CityPhoto from '../../assets/city.png';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SelectComponent from '../../components/select';
import useWindowSize from '../../hooks/useResize';
import Modal from '../../components/modal';
import Success from '../../components/successModal';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useResponsive from '../../hooks/responsive/useResponsive';

interface Props {
    saveChanges?: Function;
}

const MySettings: FunctionComponent<Props> = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const navigation = useMemo(
        () => [
            {
                label: t('pages.agency.navbar.notifications').text,
                link: '/settings/notifications',
                value: '/settings/notifications',
            },
            {
                label: t('pages.agency.navbar.changePassword').text,
                link: '/settings/change-password',
                value: '/settings/change-password',
            },
            {
                label: t('pages.agency.navbar.einvoices').text,
                link: '/settings/e-invoice',
                value: '/settings/e-invoice',
            },
            {
                label: t('pages.agency.myAccount.title').text,
                link: '/settings/default-lang',
                value: '/settings/default-lang',
            },
            {
                label: t('pages.agency.subscription.subscription').text,
                link: '/settings/subscription/1',
                value: '/settings/subscription/1',
            },
            {
                label: t('pages.agency.navbar.wallet').text,
                link: '/settings/wallet',
                value: '/settings/wallet',
            },
        ],
        [t],
    );

    const [selected, setSelected] = useState(navigation.findIndex((nav) => pathname.includes(nav.link)) || 0);
    const [changeVisible, setChangeVisible] = useState(useChangeModalStore.getState().changeModalVisible);
    const { isMobile } = useResponsive();
    const size = useWindowSize();

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    useEffect(() => {
        setChangeVisible(useChangeModalStore.getState().changeModalVisible);
    }, [size.width, selected, t]);

    useEffect(() => {
        setSelected(navigation.findIndex((nav) => pathname.includes(nav.link)) || 0);
    }, [t, pathname]);

    return (
        <Container>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <HeaderContainer>
                <Header />
            </HeaderContainer>

            <Inner>
                <h1>{t('navbar.main.settings').text}</h1>
                {!isMobile && (
                    <Navigation>
                        {navigation?.map((i, index) => (
                            <Link
                                key={index}
                                onClick={() => {
                                    if (!changeVisible) setSelected(index);
                                }}
                                className={
                                    index === navigation.findIndex((nav) => pathname.includes(nav.link))
                                        ? 'selected'
                                        : ''
                                }
                                to={i.link}
                            >
                                <div>{i.label?.toUpperCase()}</div>
                            </Link>
                        ))}
                    </Navigation>
                )}

                {isMobile && (
                    <SelectContainer>
                        <SelectComponent
                            handleSelectedValue={(v: string) => {
                                if (!changeVisible) {
                                    const ind = navigation.findIndex((x) => x.link === v);
                                    navigate(v);
                                    setSelected(ind);
                                }
                            }}
                            optionList={navigation}
                            width="100%"
                            defaultSelectedOption={navigation[selected]}
                            isSearchable={false}
                        />
                    </SelectContainer>
                )}

                <Outlet />
            </Inner>
        </Container>
    );
};
export default MySettings;

const Container = styled.div`
    background: var(--white);
    .select__control {
        background: var(--light-gray);
    }
`;

const HeaderContainer = styled.div`
    background: var(--body-gray);

    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        margin-top: 100px;
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        margin-top: 0px;
    }
`;

const Header = styled.div`
    height: 160px;
    background: url(${CityPhoto}) bottom no-repeat;
    background-size: 100% auto;
    border-bottom: 2px solid var(--border-color);
    position: relative;
    text-align: center;
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: 60px;
    }
`;

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1800px;
    margin: 0 auto;

    padding: 20px 50px 50px;
    clear: both;
    overflow: hidden;

    justify-content: center;
    align-items: center;

    /* position: relative; */
    h2 {
        text-align: left;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 32px;
    }
    h1 {
        margin-bottom: 20px;
    }
    //Media Queries
    @media only screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 5% 15px;
    }
`;

const Navigation = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    color: var(--black);
    padding-right: 60px;
    margin-bottom: 2rem;

    a {
        font-size: 14px;
        display: block;
        padding: 15px;
        padding-bottom: 5px;
        color: var(--black);
        &.selected {
            border-bottom: 3px solid var(--purple);
        }
    }
`;

const SelectContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    > div {
        background: var(--white);
    }
    text-transform: uppercase;
`;
