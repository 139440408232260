import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import StepOne from './step1/';
import StepTwo from './step2';
import StepThree from './step3';
import StepFour from './step4';
import { ObjectKeys } from '../../types/objectKeys';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import communication from '../../communication';
import useAgencyStore from '../../store/agency';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import useTheme from '../../hooks/useTheme';
import useCartStore from '../../store/cart';
import { debounce } from 'lodash';
import Loader from '../../components/loader';

const Services: React.FC = () => {
    const [step, setStep] = useState(1);
    // const navigate = useNavigate();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const paramStep = useParams().step;
    const [loader, setLoader] = useState<boolean>(false);

    // const paramsCartId = useParams().cartId;
    // const location = useLocation();
    // const state = location.state || {};

    const { agency } = useAgencyStore();
    const isDarkTheme = useTheme();

    const isTrial = agency?.user?.data?.trial || false;
    const isActiveAccount = agency?.user?.data?.active || false;
    const userPackage = agency?.user?.data?.payment_package?.data;

    const cart = useCartStore((data) => data.cartData);
    // const createCart = useCartStore((data) => data.createCart);
    const updateCart = useCartStore((data) => data.updateCart);
    // const initializeCart = useCartStore((data) => data.initializeCart);
    // const updateDiscount = useCartStore((data) => data.updateDiscount);
    const setServices = useCartStore((data) => data.setServices);
    const setServicesWithoutConsultation = useCartStore((data) => data.setServicesWithoutConsultation);
    const setConsultationServices = useCartStore((data) => data.setConsultationServices);
    const setPackageServices = useCartStore((data) => data.setPackageServices);

    useEffect(() => {
        setStep(paramStep ? parseInt(paramStep) : 1);
    }, [paramStep]);

    const createUpdateCart = (): void => {
        if (!cart || !cart.items?.length) return;
        updateCart(cart);
    };

    const fetchAllServices = async (): Promise<void> => {
        setLoader(true);
        try {
            const freeServices = userPackage?.services?.data?.map((service: any) => service.id) || [];

            const params = {
                page: 1,
                limit: 1000,
                searchJoin: 'and',
                orderBy: 'name_rs',
                sortedBy: 'asc',
            };

            const response = await communication.getAllAdminServices(params);
            const allServices = response?.data?.data || [];

            let processedServices: ObjectKeys[];

            if (isTrial || !isActiveAccount) {
                // ✅ Trial users / Non-active accounts: No free services logic
                processedServices = allServices.map((service: any) => ({
                    ...service,
                    quantity: 0,
                    type: 'service',
                }));
            } else {
                // ✅ Active users: Apply free services logic
                processedServices = allServices.map((service: any) => ({
                    ...service,
                    quantity: 0,
                    price: freeServices.includes(service.id) ? 0 : service.price, // ✅ Set free service price to 0
                    type: 'service',
                }));
            }

            setServices(processedServices);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error('Error fetching services:', error);
        }
    };

    const fetchServicesWithoutConsultation = async (): Promise<void> => {
        setLoader(true);
        try {
            const freeServices = userPackage?.services?.data?.map((service: any) => service.id) || [];

            const params = {
                page: 1,
                limit: 1000,
                search: 'is_consultation:0',
                searchFields: 'is_consultation:=',
                searchJoin: 'and',
                orderBy: 'name_rs',
                sortedBy: 'asc',
            };

            const response = await communication.getAllAdminServices(params);
            const allServices = response?.data?.data || [];

            let processedServices: ObjectKeys[];

            if (isTrial || !isActiveAccount) {
                // ✅ Trial users / Non-active accounts: No free services logic
                processedServices = allServices.map((service: any) => ({
                    ...service,
                    quantity: 0,
                    type: 'service',
                }));
            } else {
                // ✅ Active users: Apply free services logic
                processedServices = allServices.map((service: any) => ({
                    ...service,
                    quantity: 0,
                    price: freeServices.includes(service.id) ? 0 : service.price, // ✅ Set free service price to 0
                    type: 'service',
                }));
            }

            setServicesWithoutConsultation(processedServices);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error('Error fetching services:', error);
        }
    };

    const fetchConsultationPackages = (): void => {
        setLoader(true);
        const params = {
            page: 1,
            limit: 100,
            search: 'type:consultation;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                const originalData = response?.data?.data || [];
                const freeServices: string[] = userPackage?.services?.data?.map((service: any) => service.id) || [];

                // Process each service package
                const updatedData = originalData.map((packageItem: ObjectKeys) => {
                    const updatedServices =
                        packageItem?.services?.data?.map((service: any) => {
                            // Check if the current service is in the list of free services
                            const isFreeService = freeServices?.includes(service.id);

                            return {
                                ...service,
                                price: isFreeService ? 0 : service.price, // Set price to 0 if it's a free service
                            };
                        }) || [];

                    return {
                        ...packageItem,
                        services: {
                            ...packageItem.services,
                            data: updatedServices, // Update the services data
                        },
                    };
                });

                // Handle different statuses
                if (isTrial || !isActiveAccount) {
                    setConsultationServices(originalData);
                } else {
                    setConsultationServices(updatedData);
                }
                setLoader(false);
            })
            .catch((error: any) => {
                setLoader(false);
                setConsultationServices([]);
                console.error('Error:', error);
            });
    };

    const fetchServicePackages = (): void => {
        setLoader(true);
        const params = {
            page: 1,
            limit: 100,
            search: 'type:service;',
            searchFields: 'type:=;',
            searchJoin: 'and',
        };

        communication
            .getAllServicePackages(params)
            .then((response: ObjectKeys) => {
                setLoader(false);
                const originalData = response?.data?.data || [];
                const freeServices: string[] = userPackage?.services?.data?.map((service: any) => service.id) || [];
                // Process each service package
                const updatedData = originalData.map((packageItem: ObjectKeys) => {
                    const updatedServices =
                        packageItem.services?.data?.map((service: any) => {
                            // Check if the current service is in the list of free services
                            const isFreeService = freeServices?.includes(service.id);

                            return {
                                ...service,
                                price: isFreeService ? 0 : service.price, // Set price to 0 if it's a free service
                            };
                        }) || [];

                    return {
                        ...packageItem,
                        services: {
                            ...packageItem.services,
                            data: updatedServices, // Update the services data
                        },
                    };
                });

                // Handle different statuses
                if (isTrial || !isActiveAccount) {
                    setPackageServices(originalData);
                } else {
                    setPackageServices(updatedData);
                }
            })
            .catch((error: any) => {
                setLoader(false);
                setPackageServices([]);
                console.error('Error:', error);
            });
    };

    const debouncedCartValidation = debounce(createUpdateCart, 500);

    // ✅ Remove `cart.total_price` Dependency (Handled in Store)
    useEffect(() => {
        debouncedCartValidation();
        return () => debouncedCartValidation.cancel();
    }, [cart?.items]);

    useEffect(() => {
        // if (agency?.user?.data?.id) {
        //     initializeCart(agency.user.data.id);
        // }
        fetchAllServices();
        fetchServicesWithoutConsultation();
        fetchConsultationPackages();
        fetchServicePackages();
    }, [agency?.user?.data?.id]);

    return (
        <>
            {loader && <Loader />}

            <Header isDarkTheme={isDarkTheme}>
                <div>
                    <h1>{t('navbar.pausal.services').text}</h1>
                </div>
            </Header>
            <Content>
                <Content>
                    {step === 1 && <StepOne />}
                    {/* {step === 2 && <StepTwo nextStep={() => setStep(3)} previousStep={() => setStep(1)} />} */}
                    {step === 3 && <StepThree nextStep={() => setStep(4)} previousStep={() => setStep(2)} />}
                    {step === 4 && <StepFour previousStep={() => setStep(3)} />}
                </Content>
            </Content>
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const Content = styled.div`
    text-align: center;
    margin-top: 20px;

    @media screen and (min-width: ${breakpoints.laptopM}px) and (max-width: ${breakpoints.desktop - 1}px) {
        min-height: 89.8vh;
    }
    @media screen and (min-width: ${breakpoints.laptop}px) and (max-width: ${breakpoints.laptopM - 1}px) {
        min-height: 76.3vh;
    }
`;
const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }

    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4C595F')};
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }
`;
export default Services;
