import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled from 'styled-components';
import { MQ_BREAKPOINTS } from '../../constants/breakpoints';

interface Props {
    step: 'previous' | 'next' | 'login' | 'cart';
    handleClick: () => void;
}

export const StepControlButton: FC<Props> = ({ handleClick, step }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    return (
        <ButtonWrapper step={step} onClick={handleClick}>
            <FontAwesomeIcon icon={step === 'next' || step === 'cart' ? faChevronRight : faChevronLeft} />
            <span>
                {
                    t(
                        `pages.agency.subscription.${
                            step === 'cart'
                                ? 'goToCart'
                                : step === 'next'
                                ? 'nextStep'
                                : step === 'previous'
                                ? 'previousStep'
                                : 'backToLogin'
                        }`,
                    ).text
                }
            </span>
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.button<{ step: 'next' | 'previous' | 'login' | 'cart' }>`
    text-transform: uppercase;
    color: var(--purple);
    font-size: 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    gap: 10px;
    cursor: pointer;
    transition: font-weight 0.05s ease;
    flex-direction: ${(props) => (props.step === 'next' || props.step === 'cart' ? 'row-reverse' : 'row')};
    font-family: inherit;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        font-size: 16px;
    }
    &:hover {
        font-weight: 500;
        svg {
            transform: ${(props) =>
                props.step === 'next' || props.step === 'cart' ? 'translateX(10px)' : 'translateX(-10px)'};
        }
    }
    span,
    svg {
        font-size: inherit;
        font-weight: inherit;
    }
    svg {
        transition: transform 0.2s ease;
    }
`;
