import { FunctionComponent, useState, useRef } from 'react';
import { transformDateFormat } from '../../functions/formatDateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import { ObjectKeys } from '../../types/objectKeys';
import Dropdown from '../dropdown';
import useDropdown from './useDropdown';
import getStatus from './getStatus';
import getNumberLabel from './getNumberLabel';
import InvoiceActionsWithoutDropdown from './invoiceActionsWithoutDropdown';
import useOutsideClick from '../../hooks/useOutsideClick';
import PaymentDropdown from './paymentDropdown';
import PreviewInvoiceModalPlain from '../../pages/pausal/common/previewInvoiceModalPlain';
import PreviewInvoiceModalArtistic from '../../pages/pausal/common/previewInvoiceModalArtistic';
import PreviewInvoiceModalBusiness from '../../pages/pausal/common/previewInvoiceModalBusiness';
import PreviewInvoiceModalInvoicePro from '../../pages/pausal/common/previewInvoiceModalInvoicePro';
import PreviewInvoiceModalPlain2 from '../../pages/pausal/common/previewInvoiceModalPlain2';
import PreviewInvoiceModalPlainSanta from '../..//pages/pausal/common/previewInvoiceModalPlainSanta';
import PreviewInvoiceModalPlainPenguin from '../..//pages/pausal/common/previewInvoiceModalPlainPenguin';
import PreviewInvoiceModalWomensDay from '../../pages/pausal/common/previewInvoiceModalWomensDay';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import Modal from '../modal';
import { generateQrCode } from '../../communication/qr';
import { useNavigate } from 'react-router-dom';
import TooltipMain from '../../pages/pausal/common/statusTooltip';
import useResponsive from '../../hooks/responsive/useResponsive';
import {
    StyledInvoiceCard,
    CardContent,
    InfoWrapper,
    ActionWrapper,
    Action,
    DropdownContainer,
    ContentWrapper,
    CheckboxSection,
} from './style';
import Checkbox from '../checkbox';
import useTheme from '../../hooks/useTheme';

export enum InvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoice = 'advanceInvoice',
    EAdvanceInvoice = 'eAdvanceInvoice',
}
export interface PausalInvoiceCardProps {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handleReminder?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleAdvance?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    statusFilter?: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
    // handleRemovePayments?: Function;
    dropdownDirection?: 'up' | 'down';
    paymentInfo?: Function;
    handle8millionLimit?: Function;
    checkedInvoice?: Function;
    cardIdArray?: string[] | [];
    handleViewPDF?: Function;
    issueInvoice?: Function;
}

const InvoiceCard: FunctionComponent<PausalInvoiceCardProps> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handleEInvoice,
    handlePayment,
    handleSend,
    handleReminder,
    handleClick,
    handleInvoice,
    handleAdvance,
    cardDisplay,
    cardData,
    statusFilter,
    eInvoice,
    handleRefresh,
    handleUpdatePayment,
    handleDeletePayment,
    // handleRemovePayments,
    dropdownDirection = 'down',
    paymentInfo,
    handle8millionLimit,
    checkedInvoice,
    cardIdArray,
    handleViewPDF,
    issueInvoice,
}) => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { profile } = useProfileStore();
    const { userInfo } = useUserStore();
    const {
        isMobileS,
        isMobileM,
        isMobileL,
        isTablet,
        isLaptop,
        isLaptopL,
        isDesktop,
        isDesktopL,
        isUltraWide,
        isLaptopAndDown,
    } = useResponsive();

    const preferredTemplate = role === 'admin' ? 'plain' : userInfo?.preferred_template?.data.slug;

    const isAccountActive = role === 'admin' ? true : profile?.active === 1 ? true : false;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const { agency } = useAgencyStore();
    const [qrCode, setQrCode] = useState('');
    const isAdvance = cardData?.is_prepaid ? true : false;

    const ref = useRef<HTMLDivElement>(null);

    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);

    const statusTooltipRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const { handleTooltipClickOutside } = useOutsideClick(statusTooltipRef, setShowTooltip);

    const isDarkTheme = useTheme();

    const agencyDataOrigin = cardData.object === 'Quotation' ? agency : cardData.original_data.agency;

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(
                agencyData,
                clientData,
                amount,
                invoiceNumber,
                bankAccount,
                currency,
                convert,
                `00${invoiceNumber?.replace('/', '-')}`,
                '221',
            )
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    setQrCode('');
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setQrCode('');
        }
    };

    const handleDownloadInvoice = (): void => {
        if (handleDownload) {
            handleDownload();
        }
    };

    const handleRefreshInvoice = (): void => {
        if (handleRefresh) {
            handleRefresh();
        }
    };

    const handleCopyInvoice = (): void => {
        if (handleCopy) {
            handleCopy();
        }
    };

    const handleDeleteInvoice = (): void => {
        if (handleDelete) {
            handleDelete();
        }
    };

    // const handleRemovePaymentsInvoice = (): void => {
    //     if (handleRemovePayments) {
    //         handleRemovePayments();
    //     }
    // };

    const handlePaymentInvoice = (): void => {
        if (handlePayment) {
            handlePayment();
        }
    };

    const handleSendInvoice = (): void => {
        if (handleSend) {
            handleSend();
        }
    };

    const handlePaymentInfo = (): void => {
        if (paymentInfo) {
            setShowPaymentDropdown(!showPaymentDropdown);
            paymentInfo();
        }
    };

    const viewInvoiceInPDF = (): void => {
        if (handleViewPDF) {
            handleViewPDF();
        }
    };

    const handleIssueInvoice = (): void => {
        if (issueInvoice) {
            issueInvoice();
        }
    };

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefresh : undefined,
        eInvoice,
        undefined,
        handlePaymentInfo,
        viewInvoiceInPDF,
        undefined,
    );

    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        handleReminder,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    const issuedDropdown = useDropdown(
        cardData,
        type,
        'issued',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        handleReminder,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        handleChange,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        handleIssueInvoice,
    );

    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        // handleRemovePaymentsInvoice,
        undefined,
        handlePaymentInfo,
        viewInvoiceInPDF,
        undefined,
    );

    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        handleChange,
        handleCancel,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        handlePaymentInvoice,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    const proformaDropdown = useDropdown(
        cardData,
        type,
        'proforma',
        handleChange,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        undefined,
        handleInvoice,
        handleAdvance,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    const closedDropdown = useDropdown(
        cardData,
        type,
        'closed',
        undefined,
        undefined,
        handleCopyInvoice,
        handleDeleteInvoice,
        handleSendInvoice,
        undefined,
        undefined,
        handleDownloadInvoice,
        handleEInvoice,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        undefined,
        undefined,
        viewInvoiceInPDF,
        undefined,
    );

    const adminDropdown = useDropdown(
        cardData,
        type,
        'admin',
        handleChange,
        undefined,
        undefined,
        handleDeleteInvoice,
        undefined,
        undefined,
        undefined,
        handleDownloadInvoice,
        undefined,
        undefined,
        undefined,
        eInvoice ? handleRefreshInvoice : undefined,
        eInvoice,
        // handleRemovePaymentsInvoice,
        undefined,
        handlePaymentInfo,
        viewInvoiceInPDF,
        undefined,
    );

    const handleCardClick = (): void => {
        if (role !== 'admin') {
            if (!isAccountActive) {
                if (cardData.status === 'open') return;
                handleDownload && handleDownload(cardData.id);
            } else if (cardData.status === 'open' && cardData.object !== 'Quotation') {
                if (cardData.is_prepaid) {
                    navigate(`/advance-invoices/edit/${cardData.id}`);
                } else {
                    navigate(`/invoices/edit/${cardData.id}`);
                }
            } else {
                setPreviewModal(true);
                if (cardData.type !== 'foreign' && !cardData.is_prepaid) {
                    const invoiceNumber = cardData.invoice_number
                        ? `${cardData.prefix ? cardData.prefix : ''}${cardData.invoice_number}`
                        : cardData.quotation_number
                        ? cardData.quotation_number
                        : '';
                    handleGenerateQRCode(
                        agency.full_name,
                        cardData?.client?.data?.company_name,
                        cardData?.total_for_payment_in_rsd
                            ? cardData?.total_for_payment_in_rsd
                            : cardData?.value_in_rsd,
                        invoiceNumber,
                        cardData?.bank_account,
                        cardData?.currency,
                        true,
                    );
                }
            }
        } else {
            setPreviewModal(true);
            if (cardData.type !== 'foreign' && !cardData.is_prepaid) {
                const invoiceNumber = cardData.invoice_number
                    ? `${cardData.prefix ? cardData.prefix : ''}${cardData.invoice_number}`
                    : cardData.quotation_number
                    ? cardData.quotation_number
                    : '';
                handleGenerateQRCode(
                    cardData.original_data.agency.full_name,
                    cardData?.client?.data?.company_name,
                    cardData?.total_for_payment_in_rsd ? cardData?.total_for_payment_in_rsd : cardData?.value_in_rsd,
                    invoiceNumber,
                    cardData?.bank_account,
                    cardData?.currency,
                    true,
                );
            }
        }
    };

    const [tooltipDisplayText, setTooltipDisplayText] = useState<string>('');

    const handleCardStatusSubmission = (e: any, invoiceNumber: string, cancelledInvoiceNumber: string | null): void => {
        if (!isAdvance) {
            const status = cardData?.status || '';
            if (status) {
                e.stopPropagation();
                if (status === 'sent' || status === 'issued') {
                    handlePayment && handlePayment();
                } else if (status === 'paid' || status === 'partially') {
                    if (cardData?.cancelled) {
                        setShowTooltip(true);
                        if (invoiceNumber) {
                            setTooltipDisplayText(
                                `${t('pages.invoiceCard.status.stornoStatus').text} ${invoiceNumber ?? invoiceNumber}`,
                            );
                        } else {
                            setTooltipDisplayText(t('pages.invoiceCard.status.invoiceCancelled').text);
                        }
                    } else {
                        handlePaymentInfo && handlePaymentInfo();
                    }
                } else if (status === 'open') {
                    setShowTooltip(true);
                    setTooltipDisplayText(t('pages.invoiceCard.status.openNotCharged').text);
                } else if (status === 'cancelled') {
                    setShowTooltip(true);

                    if (cancelledInvoiceNumber) {
                        setTooltipDisplayText(
                            `${t('pages.invoiceCard.status.cancelledStatus').text} ${cancelledInvoiceNumber}`,
                        );
                    } else {
                        setTooltipDisplayText(t('pages.invoiceCard.status.stornoDefault').text);
                    }
                }
            }
        }
    };

    return (
        <ContentWrapper>
            <CheckboxSection>
                {isAccountActive && (
                    <Checkbox
                        onChange={() => {
                            checkedInvoice && checkedInvoice(cardData.id);
                        }}
                        defaultChecked={cardIdArray?.find((separateId) => separateId === cardData.id) ? true : false}
                    />
                )}
            </CheckboxSection>
            <StyledInvoiceCard
                isDarkTheme={isDarkTheme}
                key={cardData.id}
                className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}
            >
                {previewModal && (
                    <Modal
                        width="700px"
                        className="preview-modal"
                        modalVisible={true}
                        hasCloseButton={true}
                        closeModal={() => {
                            setPreviewModal(false);
                            setQrCode('');
                        }}
                    >
                        {/* //TODO: setQrCode(''); ON CLOSE */}

                        {preferredTemplate === 'plain' && (
                            <PreviewInvoiceModalPlain
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {/* {preferredTemplate === 'Artistic' && (
                        <PreviewInvoiceModalArtistic
                            agency={agency}
                            invoice={cardData}
                            title={cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'}
                            type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                            note={cardData.comment}
                            qrCode={qrCode}
                        />
                    )} */}
                        {preferredTemplate === 'business' && (
                            <PreviewInvoiceModalBusiness
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'invoicePro' && (
                            <PreviewInvoiceModalInvoicePro
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'plain_2' && (
                            <PreviewInvoiceModalPlain2
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'santa' && (
                            <PreviewInvoiceModalPlainSanta
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'penguin' && (
                            <PreviewInvoiceModalPlainPenguin
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                        {preferredTemplate === 'womensDay' && (
                            <PreviewInvoiceModalWomensDay
                                agency={agencyDataOrigin}
                                invoice={cardData}
                                title={
                                    cardData.is_prepaid ? 'advance' : cardData.invoice_number ? 'invoice' : 'proforma'
                                }
                                type={cardData.type === 'national' ? 'domestic' : 'foreign'}
                                note={cardData.comment}
                                qrCode={qrCode}
                            />
                        )}
                    </Modal>
                )}
                <CardContent className="card-content cursor-pointer" isDarkTheme={isDarkTheme}>
                    <InfoWrapper
                        onClick={() => {
                            handleCardClick();
                        }}
                        className={`info-wrapper ${cardDisplay === 'grid' && 'grid-view'}`.trim()}
                        isDarkTheme={isDarkTheme}
                    >
                        <div className="name-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                            <div className="name">{cardData?.client?.data?.company_name}</div>
                        </div>
                        {cardData.object !== 'Quotation' ? (
                            <div className="number-col">
                                <div className="label">{t('pages.invoiceCard.infoWrapper.prefix').text}</div>
                                <div className="prefix">{cardData?.prefix}</div>
                            </div>
                        ) : null}
                        <div className="number-col">
                            <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                            <div className="number">
                                {(type === 'proforma' && cardData?.quotation_number) ||
                                    (cardData?.invoice_number
                                        ? cardData?.invoice_number
                                        : getStatus(cardData?.status?.toLowerCase(), t))}
                            </div>
                        </div>
                        <div className="date-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                            <div className="date">
                                {transformDateFormat(
                                    type === 'proforma' ? cardData?.quotation_date : cardData?.invoice_date,
                                )}
                            </div>
                        </div>
                        <div className="amount-col">
                            <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                            <div className="amount">
                                {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                            </div>
                        </div>
                        <div className="statusAndAction">
                            {type !== 'proforma' && (
                                <>
                                    <div className="status-test">
                                        <TooltipMain
                                            text={tooltipDisplayText}
                                            isHtml={true}
                                            position="top"
                                            show={showTooltip}
                                            refProps={handleTooltipClickOutside}
                                            label={
                                                <div className="status-col">
                                                    <div className="label">
                                                        {t('pages.invoiceCard.infoWrapper.status').text}
                                                    </div>
                                                    <div
                                                        className={`status ${cardData?.status} ${
                                                            isAdvance && 'noHover'
                                                        }`}
                                                    >
                                                        <span
                                                            onClick={(e) => {
                                                                handleCardStatusSubmission(
                                                                    e,
                                                                    cardData?.cancelled_invoice_number,
                                                                    cardData?.original_invoice_number,
                                                                );
                                                            }}
                                                        >
                                                            {getStatus(cardData?.status?.toLowerCase(), t)}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        {cardData.is_einvoice === 1 && cardData.eInvoice && (
                                            <div className="status-col">
                                                {isAdvance ? (
                                                    <div className="label">
                                                        {t('pages.eInvoices.status.title_eAdvance').text}
                                                    </div>
                                                ) : (
                                                    <div className="label">
                                                        {t('pages.eInvoices.status.title').text}
                                                    </div>
                                                )}
                                                <div
                                                    className={`estatus ${cardData?.eInvoice?.data?.status?.toLowerCase()} ${
                                                        isAdvance && 'noHover'
                                                    }`}
                                                >
                                                    <span>
                                                        {getStatus(
                                                            cardData?.eInvoice?.data?.status?.toLowerCase(),
                                                            t,
                                                            cardData?.eInvoice,
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {isAccountActive ? (
                                <ActionWrapper className="mainActionWrapper" onClick={(e) => e.stopPropagation()}>
                                    <div className="label"></div>
                                    <div className="action">
                                        {isLaptopAndDown ? (
                                            <Action ref={ref}>
                                                <FontAwesomeIcon
                                                    icon={faEllipsisVertical}
                                                    style={{ color: colors.purple }}
                                                    className="cursor-pointer dots"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDropdownVisible(!dropdownVisible);
                                                        handleClick(cardData?.id);
                                                    }}
                                                />
                                                {dropdownVisible && (
                                                    <DropdownContainer
                                                        className={dropdownDirection}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleClickOutside;
                                                            setDropdownVisible(false);
                                                        }}
                                                    >
                                                        <Dropdown
                                                            arrowRight="4px"
                                                            caretDown={dropdownDirection === 'up'}
                                                            itemList={
                                                                role !== 'admin'
                                                                    ? cardData?.status === 'paid'
                                                                        ? paidDropdown
                                                                        : cardData?.status === 'sent'
                                                                        ? sentDropdown
                                                                        : cardData?.status === 'issued'
                                                                        ? issuedDropdown
                                                                        : cardData?.status === 'open' &&
                                                                          type !== 'proforma'
                                                                        ? draftDropdown
                                                                        : cardData?.status === 'partially'
                                                                        ? partiallyDropdown
                                                                        : cardData?.status === 'cancelled'
                                                                        ? cancelledDropdown
                                                                        : cardData?.status === 'open' &&
                                                                          type === 'proforma'
                                                                        ? proformaDropdown
                                                                        : cardData?.status === 'closed'
                                                                        ? closedDropdown
                                                                        : []
                                                                    : adminDropdown
                                                            }
                                                        />
                                                    </DropdownContainer>
                                                )}
                                            </Action>
                                        ) : (
                                            <InvoiceActionsWithoutDropdown
                                                cardData={cardData}
                                                handleDownload={handleDownload}
                                                handleInvoice={handleInvoice}
                                                handleAdvance={handleAdvance}
                                                handleCopy={handleCopy}
                                                handlePayment={handlePayment}
                                                handleCancel={handleCancel}
                                                handleChange={handleChange}
                                                handleDelete={handleDelete}
                                                handleSend={handleSend}
                                                handleReminder={handleReminder}
                                                handleEInvoice={handleEInvoice}
                                                handleRefresh={handleRefresh}
                                                type={type}
                                                t={t}
                                                eInvoice={eInvoice}
                                                paymentInfo={handlePaymentInfo}
                                                handleViewPDF={handleViewPDF}
                                                issueInvoice={handleIssueInvoice}
                                            />
                                        )}
                                    </div>
                                </ActionWrapper>
                            ) : (
                                <></>
                            )}
                            {role === 'admin' && !isAccountActive ? (
                                <ActionWrapper onClick={(e) => e.stopPropagation()}>
                                    <div className="label"></div>
                                    <div className="action">
                                        {isLaptopAndDown ? (
                                            <Action>
                                                <FontAwesomeIcon
                                                    icon={faEllipsisVertical}
                                                    style={{ color: colors.gray }}
                                                    className="cursor-pointer dots"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleClick(cardData?.id);
                                                        setDropdownVisible(!dropdownVisible);
                                                    }}
                                                />
                                                {dropdownVisible && (
                                                    <DropdownContainer
                                                        ref={ref}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleClickOutside;
                                                        }}
                                                        className={dropdownDirection}
                                                    >
                                                        <Dropdown
                                                            arrowRight="4px"
                                                            caretDown={dropdownDirection === 'up'}
                                                            itemList={
                                                                role !== 'admin'
                                                                    ? cardData?.status === 'paid'
                                                                        ? paidDropdown
                                                                        : cardData?.status === 'sent'
                                                                        ? sentDropdown
                                                                        : cardData?.status === 'issued'
                                                                        ? issuedDropdown
                                                                        : cardData?.status === 'open' &&
                                                                          type !== 'proforma'
                                                                        ? draftDropdown
                                                                        : cardData?.status === 'partially'
                                                                        ? partiallyDropdown
                                                                        : cardData?.status === 'cancelled'
                                                                        ? cancelledDropdown
                                                                        : cardData?.status === 'open' &&
                                                                          type === 'proforma'
                                                                        ? proformaDropdown
                                                                        : cardData?.status === 'closed'
                                                                        ? closedDropdown
                                                                        : []
                                                                    : adminDropdown
                                                            }
                                                        />
                                                    </DropdownContainer>
                                                )}
                                            </Action>
                                        ) : (
                                            <InvoiceActionsWithoutDropdown
                                                cardData={cardData}
                                                handleDownload={handleDownload}
                                                handleInvoice={handleInvoice}
                                                handleAdvance={handleAdvance}
                                                handleCopy={handleCopy}
                                                handlePayment={handlePayment}
                                                handleCancel={handleCancel}
                                                handleChange={handleChange}
                                                handleDelete={handleDelete}
                                                handleSend={handleSend}
                                                handleEInvoice={handleEInvoice}
                                                type={type}
                                                t={t}
                                                eInvoice={eInvoice}
                                            />
                                        )}
                                    </div>
                                </ActionWrapper>
                            ) : (
                                <></>
                            )}
                        </div>
                    </InfoWrapper>
                </CardContent>
                {showPaymentDropdown &&
                !cardData?.cancelled &&
                cardData?.payments?.data &&
                cardData?.payments?.data.length > 0 ? (
                    <PaymentDropdown
                        cardData={cardData}
                        t={t}
                        handlePayment={handlePayment}
                        type={type}
                        handleUpdatePayment={handleUpdatePayment}
                        handleDeletePayment={handleDeletePayment}
                        edit8millionLimit={handle8millionLimit}
                        cardDisplay={cardDisplay}
                    />
                ) : null}
            </StyledInvoiceCard>
        </ContentWrapper>
    );
};

export default InvoiceCard;
