import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import useGeneralStore from '../../../../store/general';
import { ObjectKeys } from '../../../../types/objectKeys';
import Checkbox from '../../../../components/checkbox';

type Props = {
    t: Function;
    setOpenEInvoiceModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: ObjectKeys;
    onEinvoiceSent: () => void;
};

const EInvoiceModal: FunctionComponent<Props> = ({ t, setOpenEInvoiceModal, clickedInvoice, onEinvoiceSent }) => {
    const [eInvoiceDate, setEInvoiceDate] = useState(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const [showDateError, setShowDateError] = useState(false);
    const type = clickedInvoice.client.data.type;
    const isAdvance = clickedInvoice.is_prepaid;
    const [setToCir, setSetToCir] = useState(false);
    const [showCirError, setShowCirError] = useState<boolean | null>(null);
    //EInvoice
    const handleEInvoice = (invoiceId: string): void => {
        if (type === 'public' && !setToCir && !showCirError) {
            if (eInvoiceDate === null) {
                setShowDateError(true);
            }
            setShowCirError(true);
            return;
        } else {
            if (eInvoiceDate !== null) {
                setOpenEInvoiceModal(false);
                communication
                    .prepaidInvoiceCheck(invoiceId)
                    .then((response: ObjectKeys) => {
                        const prepaids = response.data.data;
                        if (prepaids.length > 0) {
                            let advances = '';
                            for (let i = 0; i < prepaids.length; i++) {
                                advances = advances + ' ' + prepaids[i].invoice_number + ';';
                            }
                            setOpenEInvoiceModal(false);
                            setGlobalModal(
                                <ModalWrapper>
                                    <ModalText>
                                        <h4>{t('modal.error.title').text}</h4>
                                        <p>
                                            {t('pages.eInvoices.prepaidCheck').text} {advances}
                                        </p>
                                        <p>{t('pages.eInvoices.prepaidUnsent').text}</p>
                                    </ModalText>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        size={'100%'}
                                        onClick={() => {
                                            closeGlobalModal();
                                        }}
                                    >
                                        {t('buttons.ok').text}
                                    </Button>
                                </ModalWrapper>,
                            );
                        } else {
                            communication
                                .eInvoice({
                                    invoice_id: invoiceId,
                                    due_date: moment(eInvoiceDate).format('YYYY-MM-DD'),
                                    send_to_cir: setToCir,
                                })
                                .then((res: ObjectKeys) => {
                                    if (res.status === 200) {
                                        onEinvoiceSent();
                                    }
                                })
                                .catch((error: ObjectKeys) => {
                                    if (error) {
                                        setOpenEInvoiceModal(false);
                                        setGlobalModal(
                                            <ModalWrapper>
                                                <ModalText>
                                                    <h4>{t('modal.error.title').text}</h4>
                                                    <p>{error.response.data.message}</p>
                                                </ModalText>
                                                <Button
                                                    variant={ButtonVariant.solid}
                                                    color={colors.purple}
                                                    size={'100%'}
                                                    onClick={() => {
                                                        closeGlobalModal();
                                                    }}
                                                >
                                                    {t('buttons.ok').text}
                                                </Button>
                                            </ModalWrapper>,
                                        );
                                    }
                                });
                        }
                    })
                    .catch((error: ObjectKeys) => {
                        if (error) {
                            setOpenEInvoiceModal(false);
                            setGlobalModal(
                                <ModalWrapper>
                                    <ModalText>
                                        <h4>{t('modal.error.title').text}</h4>
                                        <p>{error.response.data.message}</p>
                                    </ModalText>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        size={'100%'}
                                        onClick={() => {
                                            closeGlobalModal();
                                        }}
                                    >
                                        {t('buttons.ok').text}
                                    </Button>
                                </ModalWrapper>,
                            );
                        }
                    });
            } else setShowDateError(true);
        }
    };
    return (
        <EInvoiceModalWrapper>
            {isAdvance ? (
                <ModalText>
                    <h4>{t('pages.advanceInvoices.eAdvanceModal.title').text}</h4>
                    <p>{t('pages.advanceInvoices.eAdvanceModal.description').text}</p>
                    <p className="warning">{t('pages.advanceInvoices.eAdvanceModal.attention').text}</p>
                </ModalText>
            ) : (
                <ModalText>
                    <h4>{t('pages.invoices.eInvoiceModal.title').text}</h4>
                    <p>{t('pages.invoices.eInvoiceModal.description1').text}</p>
                    <p>{t('pages.invoices.eInvoiceModal.description2').text}</p>
                </ModalText>
            )}
            <DateWrapper>
                <Input
                    type={InputComponentType.Date}
                    label={
                        isAdvance
                            ? t('pages.advanceInvoices.eAdvanceModal.inputLabel').text
                            : t('pages.invoices.eInvoiceModal.inputLabel').text
                    }
                    date={eInvoiceDate}
                    onChange={(value: any) => {
                        setEInvoiceDate(value);
                        setShowDateError(false);
                    }}
                />
                {showDateError && <span className="error">{t('pages.eInvoices.due_date').text}</span>}
                {type === 'public' && !isAdvance && (
                    <PublicWrapper>
                        <CheckBoxWrapper>
                            <Checkbox
                                defaultChecked={false}
                                onChange={() => {
                                    setSetToCir(!setToCir);
                                }}
                                label={t('pages.eInvoices.cir_send').text}
                            />
                        </CheckBoxWrapper>
                        {showCirError && !setToCir ? (
                            <span className="error">
                                {isAdvance
                                    ? t('pages.eInvoices.send_to_cir_eadvance').text
                                    : t('pages.eInvoices.send_to_cir').text}
                            </span>
                        ) : null}
                    </PublicWrapper>
                )}
            </DateWrapper>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleEInvoice(clickedInvoice.id);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    onClick={() => setOpenEInvoiceModal(false)}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </EInvoiceModalWrapper>
    );
};

const EInvoiceModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
    .error {
        color: red;
        font-size: 12px;
        margin-top: 3px;
        display: block;
    }
    .warning {
        color: red;
        font-size: 12px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--light-gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        line-height: 1.3;
        color: var(--gray);
        font-size: 14px;
        margin-bottom: 10px;
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    padding: 10px 50px 35px 50px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const CheckBoxWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const PublicWrapper = styled.div`
    text-align: center;
`;
export default EInvoiceModal;
