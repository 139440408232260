import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PackageCard from './packageCard';
import communication from '../../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../../components/loader';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import { useNavigate, useParams } from 'react-router-dom';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

interface StepOneProps {
    nextStep: Function;
    finallCart: Function;
    mainCart: ObjectKeys;
}

const StepOne: React.FC<StepOneProps> = ({ nextStep, finallCart }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const paramPackage = useParams().cartId;

    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(paramPackage && !paramPackage.includes('-') ? 2 : 1);

    const { agency } = useAgencyStore();
    const isTrialUser = agency.user.data.trial;
    const isActiveUser = agency.user.data.active;
    const userPackage = agency.user.data.payment_package.data;
    const minimumMonths = agency.user.data.upgrade_package_data.upgrade_min_months;

    const [loader, setLoader] = useState<boolean>(false);
    const [packages, setPackages] = useState<ObjectKeys[]>([]);

    const [renderCartKey, setRenderCartKey] = useState<number>(0);

    const [cart] = useState<ObjectKeys>({
        user_id: agency.user.data.id,
        status: '',
        total_price: 0,
        type: 'package',
        note: '',
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    useEffect(() => {
        setRenderCartKey(renderCartKey + 1);
        finallCart(cart);
    }, [cart]);

    const getAllPackages = (): void => {
        setLoader(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                const activePackages = response.data.data.filter((item: ObjectKeys) => item.active);
                setPackages(activePackages);
                if (paramPackage && !paramPackage.includes('-')) {
                    setLoader(false);
                    nextStep();
                } else {
                    setLoader(false);
                }
            })
            .catch((error: any) => {
                setLoader(false);
                console.error('Error:', error);
            });
    };

    const handleNextStep = (): void => {
        setCurrentStep(currentStep + 1);
        nextStep();
        navigate('/settings/subscription/2');
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    return (
        <>
            {loader && <Loader />}
            <Title>
                {!isActiveUser ? (
                    <h1>{t('pages.agency.subscription.accountExpired').text}</h1>
                ) : isTrialUser ? (
                    <>
                        <h1> {t('pages.agency.subscription.title_2').text}</h1>
                        <h1>{t('pages.agency.subscription.trialPeriod').text}</h1>
                    </>
                ) : (
                    <>
                        <h1> {t('pages.agency.subscription.title_2').text}</h1>
                        <h2>
                            {t('pages.agency.subscription.currentPackagePartOne').text}
                            {userPackage.name}
                            {t('pages.agency.subscription.currentPackagePartTwo').text}
                        </h2>
                    </>
                )}
            </Title>

            <Content>
                <div className="packages">
                    {packages.map((item, index) => (
                        <PackageCard
                            title={item.name}
                            price={item.monthly}
                            monthlyObligatory={item.months}
                            isClicked={true}
                            prevent={!isTrialUser && isActiveUser && userPackage.id === item.id}
                            onClicked={() => {
                                const period = item.months > minimumMonths ? item.months : minimumMonths;
                                finallCart({
                                    ...cart,
                                    items: [
                                        {
                                            id: item.id,
                                            type: 'package',
                                            price: item.monthly,
                                            months: period,
                                        },
                                    ],
                                });
                                handleNextStep();
                            }}
                            item={packages[index]}
                            key={index}
                        />
                    ))}
                </div>
            </Content>
        </>
    );
};
const Title = styled.div`
    width: 95%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    h1 {
        font-size: 24px;
        color: var(--adjust);
    }
    h2 {
        font-size: 20px;
        color: var(--purple);
    }
`;
const Content = styled.div`
    width: 95%;
    text-align: center;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        width: 100%;
    }
    .packages {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
    }
`;
export default StepOne;
