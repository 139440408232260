import { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../../types/objectKeys';
import styled from 'styled-components';
import useAllUsersStore from '../../../../store/admin/allUsers';
import communication from '../../../../communication';
import SelectComponent from '../../../../components/select';
import Sidebar from '../../../../components/sideModal';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import moment from 'moment';
import SearchCustomComponent from '../../../../components/customSelect';
import useUserStore from '../../../../store/user';
import Modal from '../../../../components/modal';
import ConfirmModal from '../../../../components/confirmModal';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

type NewTransactionTypes = {
    amount: string;
    transaction_date: Date | undefined | null | string;
    type: string;
    package: string;
    user_id: string;
};
interface Props {
    t: Function;
    closeModal: Function;
    packages: ObjectKeys[];
    success: Function;
}

const AddPayment: FunctionComponent<Props> = ({ t, closeModal, packages, success }) => {
    const { userBOid } = useUserStore();
    const { allUsers } = useAllUsersStore();
    const transactionDataInitial = {
        amount: '830',
        transaction_date: new Date().toISOString().split('T')[0],
        type: 'payment',
        package: '1',
        user_id: '',
    };

    const [addTransactionModal, setAddTransactionModal] = useState(false);

    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');

    const [renderKey, setRenderKey] = useState(0);

    const [selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>(packages[0]);
    const [cart, setCart] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        status: '',
        total_price: 0,
        type: 'package',
        payment_type: 'transfer',
        note: '',
        is_upgrade: false,
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    const [manualTransactionData, setManualTransactionData] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        package_id: '',
        amount: '',
        months: 1,
        transaction_date: '',
    });

    const [confirmSendModal, setConfirmSendModal] = useState(false);

    const resetManualData = (): void => {
        setManualTransactionData({
            user_id: selectedUserId ? selectedUserId : '',
            package_id: '',
            amount: '',
            months: 1,
            transaction_date: '',
        });
    };

    const [errorManualData, setErrorManualData] = useState(false);

    const [newTransactionData] = useState<NewTransactionTypes>(transactionDataInitial);

    const monthsOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ];

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        setSelectedUserId(userBOid);
    }, [userBOid]);

    useEffect(() => {
        if (selectedUserId) {
            if (!addTransactionModal) {
                setSelectedPackageOption(packages[0]);
                setCart({
                    ...cart,
                    user_id: selectedUserId,
                    items: cart.items.map((prev: ObjectKeys) => ({
                        ...prev,
                        id: selectedPackageOption?.id,
                        price: selectedPackageOption?.monthly,
                        months: selectedPackageOption?.name === 'Basic' ? 1 : 6,
                    })),
                });
            }
        }
    }, [selectedUserId]);

    const isDateValid =
        newTransactionData.transaction_date === undefined || newTransactionData.transaction_date === null
            ? 'Datum je obavezan'
            : '';

    const handleCreateManualTransaction = async (): Promise<void> => {
        if (
            !manualTransactionData.user_id ||
            !manualTransactionData.package_id ||
            !manualTransactionData.amount ||
            !manualTransactionData.months ||
            !manualTransactionData.transaction_date
        ) {
            setErrorManualData(true);
            return;
        }
        closeModal(false);
        await communication.createManualTransaction(manualTransactionData).then((response: ObjectKeys) => {
            if (response.status === 200) {
                success();
                closeModal(false);
                resetManualData();
            } else {
                console.error('Error');
            }
        });
    };

    return (
        <>
            {confirmSendModal ? (
                <Modal modalVisible={confirmSendModal} closeModal={() => setConfirmSendModal(false)}>
                    <ConfirmModal
                        action={async () => {
                            setConfirmSendModal(false);
                            handleCreateManualTransaction();
                        }}
                        close={() => {
                            setAddTransactionModal(false);
                            setConfirmSendModal(false);
                        }}
                        active={confirmSendModal}
                        message={'Da li ste sigurni da zelite da produzite pretplatu?'}
                    />
                </Modal>
            ) : (
                <Sidebar
                    close={() => {
                        closeModal(false);
                        resetManualData();
                    }}
                >
                    <SidebarContent>
                        <div className="header">
                            <h1>Produži pretplatu</h1>
                            <button
                                className={'close_button'}
                                onClick={() => {
                                    closeModal(false);
                                    resetManualData();
                                }}
                            />
                        </div>
                        <div className="content">
                            <p className="user-select">{t('pages.admin.users.chooseUser').text}:</p>
                            <div className="choose-user">
                                <SearchCustomComponent
                                    value={allUsersOptions.find((v) => v.value === userBOid)}
                                    allUsersOptions={allUsersOptions}
                                    handleSelectedValue={(data: { value: string; label: string }) => {
                                        useUserStore.setState({ userBOid: data.value });
                                        setSelectedUserId(data.value);
                                        setManualTransactionData((prevState) => ({
                                            ...prevState,
                                            user_id: data.value,
                                        }));
                                        setSelectedPackageOption(packages[0]);
                                        setRenderKey((prev) => prev + 1);
                                    }}
                                    placeholder={t('pages.admin.users.chooseUser').text}
                                    className="user-select-transaction"
                                />
                            </div>
                            {selectedUserId ? (
                                <div className="create-transaction-form">
                                    <Packages>
                                        <p className="user-select">ODABERI PAKET:</p>
                                        <div className="packages">
                                            {packages.map((item, index) => (
                                                <Package
                                                    key={index}
                                                    isSelected={item.id === manualTransactionData.package_id}
                                                    onClick={() => {
                                                        setManualTransactionData((prevState) => ({
                                                            ...prevState,
                                                            package_id: packages[index].id,
                                                        }));
                                                    }}
                                                >
                                                    {item.name}
                                                </Package>
                                            ))}
                                        </div>
                                    </Packages>
                                    <p className="user-select">ODABERI PERIOD (MESECI):</p>
                                    <SelectComponent
                                        value={cart.items[0].months}
                                        key={renderKey}
                                        optionList={monthsOptions}
                                        handleSelectedValue={(value: string) => {
                                            setManualTransactionData((prevState) => ({
                                                ...prevState,
                                                months: parseInt(value),
                                            }));
                                        }}
                                    />
                                    <Input
                                        type={InputComponentType.Amount}
                                        validation={''}
                                        label={'IZNOS (RSD)'}
                                        value={manualTransactionData.amount}
                                        readOnly={false}
                                        onChange={(e: any) => {
                                            setManualTransactionData((prevState) => ({
                                                ...prevState,
                                                amount: e,
                                            }));
                                        }}
                                    />
                                    <Input
                                        type={InputComponentType.Date}
                                        label={'Datum transakcije:'}
                                        validation={isDateValid}
                                        date={
                                            !manualTransactionData.transaction_date
                                                ? null
                                                : moment(manualTransactionData.transaction_date).toDate()
                                        }
                                        onChange={(value: Date | undefined | null) =>
                                            setManualTransactionData((prevState) => ({
                                                ...prevState,
                                                transaction_date: moment(value).format('YYYY-MM-DD').toString(),
                                            }))
                                        }
                                        maxDate={new Date()}
                                    />
                                    {errorManualData ? <p className="error">Sva polja su obavezna</p> : null}
                                    <div className={'button-wrapper'}>
                                        <Button
                                            variant={ButtonVariant.solid}
                                            color={colors.purple}
                                            onClick={() => {
                                                setConfirmSendModal(true);
                                            }}
                                        >
                                            Produži pretplatu
                                        </Button>
                                    </div>
                                    <p className="warning">
                                        Upozorenje: za kreiranu transakciju NEĆE BITI izdat fiskalni račun.
                                    </p>
                                </div>
                            ) : (
                                <p>Izaberi korisnika</p>
                            )}
                        </div>
                    </SidebarContent>
                </Sidebar>
            )}
        </>
    );
};

const Packages = styled.div`
    p {
        color: var(--gray);
        margin-top: 20px;
    }
    .packages {
        display: flex;
        gap: 5px;
        padding: 10px;
        justify-content: space-evenly;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: center;
        }
    }
`;

const Package = styled.div<{ isSelected: boolean }>`
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--purple);
    font-size: 20px;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? 'var(--purple)' : 'white')};
    color: ${(props) => (props.isSelected ? 'white' : 'var(--purple)')};
`;

const SidebarContent = styled.div`
    .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            border-radius: 100px;
        }
    }
    .header {
        position: relative;
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: #4c595f;
            font-weight: 400;
            display: inline;
        }
        .close_button {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
                no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            border: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .choose-user {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .user-select {
        font-size: 16px;
        color: var(--gray);
    }
    .total-amount {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 10px;
    }
    .content {
        .select-search {
            margin-bottom: 20px;
        }
        .select-wrapper {
            width: 100%;
            label {
                font-size: 15px;
                color: var(--gray);
                padding-left: 0;
            }
            .select__control {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .search-wrapper {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .options-list {
            z-index: 1;
            top: 9em;
        }
    }
    .selected-user {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 15px;
        color: var(--gray);
        .delete-selected-user {
            float: right;
            cursor: pointer;
        }
    }
    .warning {
        color: red;
        font-size: 12px;
        margin-top: 10px;
    }
    .error {
        color: red;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
`;

export default AddPayment;
