import styled from 'styled-components';
import { breakpoints, MQ_BREAKPOINTS } from '../../constants/breakpoints';

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

export const ContentWrapper = styled.div`
    display: flex;
`;

export const CheckboxSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 23.8px;
`;

export const StyledInvoiceCard = styled.div<DarkThemeProps>`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    width: 100%;
    &:hover {
        box-shadow: 0 0 5px 5px ${({ isDarkTheme }) => (isDarkTheme ? 'var(--body-gray)' : '#eee')};
    }

    &.card-display-grid {
        display: grid;
        gap: 16px;
        height: 100%;
    }

    /* .cursor-pointer > * {
        height: 100%;
    } */
`;

export const CardContent = styled.div<DarkThemeProps>`
    .grid-view {
        display: flex;
        flex-direction: column;

        .name-col,
        .prefix-col,
        .number-col,
        .date-col,
        .amount-col,
        .status-col {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
        }

        .name-col .name {
            white-space: normal;
        }

        .status-test {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
            height: 80px;
        }

        .statusAndAction .status-test .status-col {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .e-invoice-status-wrapper {
            display: flex;
            flex-direction: column;
        }

        .statusAndAction {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        @media screen and (max-width: 1023px) {
            .name-col .name {
                width: 80%;
                text-align: right;
            }
            .name-col {
                display: flex;

                align-items: center;
            }

            .mainActionWrapper {
                width: 100%;
            }
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
            .name-col .name,
            .prefix,
            .number,
            .date,
            .amount,
            .status {
                width: 60%;
                text-align: right;
            }
            .name-col {
                display: flex;

                align-items: center;
            }

            .mainActionWrapper {
                width: 100%;
            }
            .statusAndAction {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }
        @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) {
            .name-col .name {
                width: 80%;
            }
        }

        .label {
            font-size: 14px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .prefix {
        min-height: 18px;
    }

    div {
        transition: opacity 0.2s ease;

        &.number {
            text-transform: capitalize;
        }
        &.status {
            text-transform: capitalize;
            &.sent {
                span {
                    background: var(--blue);
                }
            }
            &.paid {
                span {
                    background: var(--green);
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--green);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            &.issued {
                span {
                    background: var(--purple);
                }
            }
            span {
                padding: 0 10px 2px;
                background: #92a5ac;
                color: white;
                border-radius: 50px;
                font-size: 12px;
            }
        }
        &.status:hover {
            opacity: 0.5;
        }
        &.noHover:hover {
            opacity: 1;
        }
        &.estatus {
            text-transform: capitalize;
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
            &.sent {
                span {
                    background: var(--blue);
                    color: #fff;
                }
            }
            &.paid,
            &.sending {
                span {
                    background: #92a5ac;
                    color: #fff;
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--turquoise);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            &.mistake {
                span {
                    background: var(--danger);
                }
            }
            &.null {
                span {
                    background: #92a5ac;
                    color: #fff;
                }
            }
            span {
                padding: 0 10px 2px;
                background: #92a5ac;
                color: white;
                border-radius: 50px;
                font-size: 12px;
            }
        }
    }
`;

export const InfoWrapper = styled.div<DarkThemeProps>`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        grid-template-columns: 1fr;
        padding: 10px;

        .name-col .name {
            white-space: normal;
        }

        .name-col,
        .prefix-col,
        .number-col,
        .date-col,
        .amount-col,
        .status-col {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            font-size: 16px;
            justify-content: space-between;
        }

        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            .name-col .name {
                width: 80%;
                text-align: right;
            }
            .name-col {
                display: flex;

                align-items: center;
            }
        }

        .label {
            font-size: 14px;
            color: var(--gray);
            line-height: 20px;
        }
    }

    @media screen and (min-width: 769px) {
        display: flex;
        width: 100%;

        .name-col {
            width: 20%;
        }

        .prefix-col {
            width: 15%;
            padding-left: 0;
        }

        .number-col {
            width: 15%;
            padding-left: 0;
        }

        .date-col {
            width: 15%;
        }

        .amount-col {
            width: 10%;
        }

        .mainActionWrapper {
            margin-left: auto;
        }

        .statusAndAction {
            display: flex;
            margin-left: auto;
        }

        .status-test {
            display: flex;
            justify-content: space-between;
        }

        .status-test,
        .e-invoice-status-wrapper {
            display: flex;
            min-width: 173px;
            gap: 10px;
        }

        .status-test .status-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .status-test .status-col .estatus {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 3px;
        }

        .status-col .label {
            white-space: nowrap;
        }

        .action {
            justify-content: space-between;

            @media screen and (min-width: 2120px) {
                min-width: 182.5px;
            }
        }
    }

    @media screen and (min-width: ${breakpoints.laptop}px) {
        .status-test,
        .e-invoice-status-wrapper {
            min-width: 130px;
        }
    }
    @media screen and (min-width: ${breakpoints.laptop + 124}px) {
        .status-test,
        .e-invoice-status-wrapper {
            min-width: 160px;
        }
    }

    @media screen and (min-width: 1601px) {
        .prefix-col {
            flex: 1;
        }

        .number-col {
            flex: 1;
        }

        .date-col {
            flex: 1;
        }

        .amount-col {
            flex: 1;
        }

        .statusAndAction {
            width: 450px;
        }
    }

    .label {
        font-size: 12px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        line-height: 25px;
    }
`;

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;

export const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;

export const DropdownContainer = styled.div`
    position: absolute;
    right: 0;
    &.down {
        top: 55px;
    }
    &.up {
        bottom: 55px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        p {
            font-size: 16px;
        }
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;

export const ActionWithoutDropdown = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 13px;
`;
export const InvoiceActionsWithoutDropdown = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 10px;
`;
