import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import communication from '../../../../communication';
import Button, { ButtonVariant } from '../../../../components/button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuillToolbar from '../common/toolbar';
import Input, { InputComponentType } from '../../../../components/input';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCheckCircle, faDownload, faEnvelope, faSave } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../../components/loader';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import SendMemoModal from '../common/sendModal';
import Sidebar from '../../../../components/sideModal';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { ObjectKeys } from '@/types/objectKeys';
import useMemoStore from '../../../../store/memos';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import { breakpoints } from '../../../../constants/breakpoints';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Dropdown from '../../../../components/dropdown';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useAgencyStore from '../../../../store/agency';
import { toast } from 'react-toastify';
import { useMemosQuery } from '../../../../react-query/useMemosQuery';
import useUserStore from '../../../../store/user';

const EditMemorandum: FunctionComponent = () => {
    const [memorandum, setMemorandum] = useState<any>();
    const [editorValue, setEditorValue] = useState<any>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const { agency } = useAgencyStore();

    const [memorandumName, setMemorandumName] = useState<string>('');
    const [memorandumDescription, setMemorandumDescription] = useState<string>('');
    const [isPreferred, setIsPreferred] = useState<boolean>(false);

    const isMemoNameValid = useCharLengthValidation(memorandumName, 3, true);

    const { pathname } = useLocation();
    const isPatternMemo = pathname.includes('pattern');

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const quillRef = useRef<any>();

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const [isDropdownVisible1, setIsDropdownVisible1] = useState(false);
    const [isDropdownVisible2, setIsDropdownVisible2] = useState(false);
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const { handleClickOutside1 } = useOutsideClick(ref1, setIsDropdownVisible1);
    const { handleClickOutside2 } = useOutsideClick(ref2, setIsDropdownVisible2);

    const appUrl = process.env.REACT_APP_URL;
    const logoUrl = agency.logo ? `${appUrl}/${agency.logo}` : null;

    const [param] = useState<any>({
        page: '1',
        limit: '10',
        status: 'all',
        find: '',
    });

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { userBOid } = useUserStore();
    const role = localStorage.getItem('role') === 'admin' ? 'admin' : 'user';
    const { refetch } = useMemosQuery(role, userBOid);

    //QUILL Editor Configuration
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt'];
    const hr = Quill.import('blots/block/embed');
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    hr.blotName = 'divider';
    hr.tagName = 'hr';
    Quill.register(Size, true);
    const Break = Quill.import('blots/break');
    Break.blotName = 'break';
    Break.tagName = 'BR';
    Quill.register(Block, true);
    Quill.register({
        'formats/hr': hr,
    });

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'indent',
        'italic',
        'align',
        'list',
        'bullet',
        'image',
        'divider',
        'link',
        'h1',
        'p',
        'img',
    ];

    const handleChange = (value: any): void => {
        setEditorValue(value);
    };

    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;

    const getSpecificMemo = async (): Promise<void> => {
        setIsLoader(true); // Show loader before the async operation
        try {
            const { data } = await communication.getSpecificMemorandum(id);
            // Process the template with the logoUrl
            const updatedTemplate = processTemplateWithLogo(data.data.template);
            // Update memorandum with the modified template
            setMemorandum({
                ...data.data,
                template: updatedTemplate, // Replace template with the updated version
            });

            setEditorValue(updatedTemplate); // Use the processed template here
            setMemorandumName(data.data.heading);
            setMemorandumDescription(data.data.description);
        } catch (error) {
            console.error('Error fetching memorandum:', error);
            // Handle error (optional)
        } finally {
            setIsLoader(false); // Hide loader after the operation completes
        }
    };

    const processTemplateWithLogo = (html: string): string => {
        const imgTag = logoUrl ? `<p class="ql-align-center"><img src="${logoUrl}" /></p>` : '';
        const regex =
            /<(div|p)[^>]*class=["']ql-align-center["'][^>]*>\s*<img[^>]*src=["'][^"']*["'][^>]*>\s*<\/(div|p)>/gi;

        // Remove any existing <div> or <p> with <img>
        const cleanedHtml = html.replace(regex, '');

        if (logoUrl) {
            // Insert the new <p> tag at the beginning
            return `${imgTag}\n${cleanedHtml}`;
        }

        // If logoUrl doesn't exist, just return the cleaned HTML
        return cleanedHtml;
    };

    useEffect(() => {
        getSpecificMemo();
    }, []);

    useEffect(() => {
        setMemorandum({
            ...memorandum,
            template: editorValue,
            heading: memorandumName,
            description: memorandumDescription,
            preferred: isPreferred,
        });
    }, [isPreferred, editorValue, memorandumName, memorandumDescription]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsPreferred(event.target.checked);
    };

    const handleSave = async (): Promise<void> => {
        if (isMemoNameValid) {
            return Promise.reject();
        } else {
            setIsLoader(true);
            if (isPreferred) {
                const template_data = {
                    template: editorValue,
                    heading: memorandumName,
                    description: memorandumDescription,
                    preferred: true,
                };
                communication.createMemorandum(template_data);
            }
            const data = {
                id: memorandum.id,
                template: editorValue,
                heading: memorandumName,
                description: memorandumDescription,
                preferred: false,
            };
            const res = await communication.updateMemorandum(data);
            refetch();
            return res.data.data;
        }
    };

    const handleUpdate = async (): Promise<void> => {
        if (isMemoNameValid) {
            return;
        } else {
            setIsLoader(true);
            handleSave().then(() => {
                navigate(-1);
                setIsLoader(false);
            });
        }
    };

    const handlePrintPdf = async (): Promise<void> => {
        if (isMemoNameValid) {
            return;
        } else {
            setIsLoader(true);
            handleSave().then(() => {
                communication.downloadMemorandum(memorandum, token).then(() => {
                    navigate('/memorandums/sent');
                    setIsLoader(false);
                });
            });
        }
    };

    const handlePrintWord = async (): Promise<void> => {
        if (isMemoNameValid) {
            return;
        } else {
            setIsLoader(true);
            handleSave().then(() => {
                if (memorandum.id) {
                    communication.downloadMemosWord(memorandum, token).then(() => {
                        navigate('/memorandums/sent');
                        setIsLoader(false);
                    });
                } else {
                    console.error('Failed to retrieve memorandum ID');
                }
            });
        }
    };

    const handleSend = (): void => {
        if (isMemoNameValid) {
            return;
        } else {
            setModalVisible(true);
        }
    };
    const getMemorandums = async (): Promise<void> => {
        setIsLoader(true);
        try {
            const res: ObjectKeys = await communication.getAllMemorandums('1', '10', 'any', '');
            useMemoStore.setState({ memoData: res.data });
            return Promise.resolve();
        } catch (error) {
            console.error('Error fetching memorandums:', error);
            return Promise.reject(error);
        } finally {
            setIsLoader(false);
        }
    };

    const handleReady = async (): Promise<void> => {
        if (isMemoNameValid) {
            return;
        } else {
            setIsLoader(true);
            handleSave().then(() => {
                communication.createMemoReady(memorandum.id, token).then(() => {
                    navigate('/memorandums/sent');
                    setIsLoader(false);
                });
            });
        }
    };
    // Modules object for setting up the Quill editor
    const modules = {
        toolbar: {
            container: '#toolbar',
            history: {
                delay: 0,
                maxStack: 100,
                userOnly: true,
            },
        },
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendMemoModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            toast.success(t('pages.memos.sendMemoSuccess').text);
                            getMemorandums()
                                .then(() => {
                                    navigate('/memorandums/sent');
                                })
                                .catch((error) => {
                                    console.error('Error in success:', error);
                                });
                        }}
                        memorandum={memorandum}
                        handleUpdateMemorandum={handleSave}
                        loading={() => setIsLoader(true)}
                        memoId={memorandum.id}
                        modalVisible={modalVisible}
                    />
                </Sidebar>
            )}

            <Header>
                <div className="title">
                    <div
                        className={'back-button'}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon className={'icon'} icon={faAngleLeft} color={colors.gray} />
                    </div>
                    <h1> {t('pages.memos.titleSingle').text}</h1>
                </div>
                {!isPatternMemo ? (
                    <ButtonWrapper>
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleUpdate();
                            }}
                            color={colors.green}
                            variant={ButtonVariant.solid}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                            {t('pages.editInvoice.save').text}
                        </Button>{' '}
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleReady();
                            }}
                            color={colors.purple}
                            variant={ButtonVariant.outlined}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faCheckCircle} />
                            {t('pages.memos.ready').text}
                        </Button>{' '}
                        <div className="header-options-buttons">
                            <Button
                                className={'action-button'}
                                onClick={() => setIsDropdownVisible1(!isDropdownVisible1)}
                                color={colors.purple}
                                variant={ButtonVariant.outlined}
                                size={'20px'}
                                height={'20px'}
                                icon={true}
                            >
                                <FontAwesomeIcon className="icon" color={colors.purple} icon={faDownload} />
                                {t('pages.invoiceCard.dropdownItems.download').text}
                            </Button>
                            {isDropdownVisible1 && (
                                <DropdownContainer ref={ref1} onClick={handleClickOutside1}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: 'PDF',
                                                handler: () => handlePrintPdf(),
                                            },
                                            {
                                                label: 'Word',
                                                handler: () => handlePrintWord(),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </div>
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleSend();
                            }}
                            color={colors.blue}
                            variant={ButtonVariant.solid}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                            {t('pages.editInvoice.send').text}
                        </Button>
                    </ButtonWrapper>
                ) : (
                    <ButtonWrapper>
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleUpdate();
                            }}
                            color={colors.green}
                            variant={ButtonVariant.solid}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                            {t('pages.editInvoice.save').text}
                        </Button>{' '}
                    </ButtonWrapper>
                )}
            </Header>
            <Input
                type="text"
                label={t('pages.memos.name').text}
                className={'input'}
                value={memorandumName}
                onChange={(value: string) => setMemorandumName(value)}
                validation={isMemoNameValid}
                maxLength={100}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.memos.description').text}
                className={'input'}
                value={memorandumDescription}
                onChange={(value: string) => {
                    setMemorandumDescription(value);
                }}
                maxLength={140}
            />
            <QuillToolbar quillRef={quillRef} />
            <ReactQuill
                className={'ql-editor'}
                ref={quillRef}
                theme="snow"
                value={editorValue}
                onChange={handleChange}
                placeholder={''}
                modules={modules}
                formats={formats}
            />
            {!isPatternMemo ? (
                <>
                    <TooltipSection>
                        <div>
                            <p>{t('pages.memos.templateCreation').text}</p>
                            <input type="checkbox" checked={isPreferred} onChange={handleCheckboxChange} />
                        </div>
                    </TooltipSection>
                    <ButtonWrapper>
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleUpdate();
                            }}
                            color={colors.green}
                            variant={ButtonVariant.solid}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                            {t('pages.editInvoice.save').text}
                        </Button>{' '}
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleReady();
                            }}
                            color={colors.purple}
                            variant={ButtonVariant.outlined}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faCheckCircle} />
                            {t('pages.memos.ready').text}
                        </Button>{' '}
                        <div className="header-options-buttons">
                            <Button
                                className={'action-button'}
                                onClick={() => setIsDropdownVisible2(!isDropdownVisible2)}
                                color={colors.purple}
                                variant={ButtonVariant.outlined}
                                size={'20px'}
                                height={'20px'}
                                icon={true}
                            >
                                <FontAwesomeIcon className="icon" color={colors.purple} icon={faDownload} />
                                {t('pages.invoiceCard.dropdownItems.download').text}
                            </Button>
                            {isDropdownVisible2 && (
                                <DropdownUpContainer ref={ref2} onClick={handleClickOutside2}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: 'PDF',
                                                handler: () => handlePrintPdf(),
                                            },
                                            {
                                                label: 'Word',
                                                handler: () => handlePrintWord(),
                                            },
                                        ]}
                                        caretDown={true}
                                    />
                                </DropdownUpContainer>
                            )}
                        </div>
                        <Button
                            className={'action-button'}
                            onClick={() => {
                                handleSend();
                            }}
                            color={colors.blue}
                            variant={ButtonVariant.solid}
                            size={'20px'}
                            height={'20px'}
                            icon={true}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                            {t('pages.editInvoice.send').text}
                        </Button>
                    </ButtonWrapper>
                </>
            ) : (
                <ButtonWrapper>
                    <Button
                        className={'action-button'}
                        onClick={() => {
                            handleUpdate();
                        }}
                        color={colors.green}
                        variant={ButtonVariant.solid}
                        size={'20px'}
                        height={'20px'}
                        icon={true}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                        {t('pages.editInvoice.save').text}
                    </Button>{' '}
                </ButtonWrapper>
            )}
        </PageWrapper>
    );
};
const PageWrapper = styled.div`
    height: 100%;
    width: 60.67vw;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        width: 100%;
    }
    .back-button {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 3px;
        text-align: center;
        display: block;
    }
    .action-button {
        width: auto;
        padding: 15px 20px;
        margin-left: 20px;

        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            margin-left: 0;
            width: -webkit-fill-available;
        }
    }
    .quill {
        width: 60.67vw;
    }

    .ql-container {
        height: 100vh;
    }

    .ql-editor {
        background-color: var(--white);
        padding: 0px;
        //display: grid;
        height: 100%;
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            width: 100%;
        }
        .ql-container {
            box-sizing: border-box;
            line-height: 1.42;
            height: 100%;
            outline: none;
            overflow-y: auto;
            padding: 12px 15px;
            tab-size: 4;
            text-align: left;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
        .span {
            font-size: 12pt;
            line-height: 2;
        }
        img {
            height: auto;
            width: 6.7067vw;
            margin-left: auto !important;
            margin-right: auto !important;
            display: block;
        }
        h1 {
            line-height: 1.3em;
            text-align: center;
            color: #000;
            font-weight: 700;
            margin: 0.67em 0;
        }
        p {
            font-size: 1.38vw;
            line-height: 1.785vw;
            color: #000;
        }
        hr {
            height: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    .input {
        input {
            background-color: var(--white);
            &:hover {
                border-bottom: 1px solid var(--border-color);
            }
            &:focus {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .headerButtons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 10px;
        }
        .header-options-buttons {
            position: relative;
            display: inline-block;
        }
    }
    .title {
        display: contents;
        align-items: end;
        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            display: flex;
        }
    }
    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        display: block;
    }
`;

const TooltipSection = styled.div`
    padding: 10px;
    font-size: 14px;
    div {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
    }
`;
const ButtonWrapper = styled.div`
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;

    flex-wrap: wrap;
    gap: 10px;

    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 10px;
    }
`;
export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    width: 100px;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }

    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        top: 130px;
        left: 110px;
    }
    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
        top: 130px;
        left: 30px;
    }
    @media screen and (max-width: ${breakpoints.mobileL - 1}px) {
        left: 15px;
    }
    @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
        left: 2px;
    }
    @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
        left: -6px;
    }
`;
export const DropdownUpContainer = styled.div`
    position: absolute;
    z-index: 1;
    width: 100px;
    bottom: 70px;
    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }

    @media screen and (max-width: ${breakpoints.laptop - 1}px) {
        bottom: 40px;
        left: 100px;
    }

    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
        bottom: 40px;
        left: 20px;
    }
    @media screen and (max-width: ${breakpoints.mobileL - 1}px) {
        left: 5px;
    }
    @media screen and (max-width: ${breakpoints.mobileM - 1}px) {
        left: -10px;
    }
`;
export default EditMemorandum;
