import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAgencyStore from '../../../store/agency';
import Modal from '../../../components/modal';
import NoItems from '../../../components/noItems';
import Button, { ButtonVariant } from '../../../components/button';
import SelectComponent from '../../../components/select';
import Search from '../../../components/input/search';
import Pagination from '../../../components/pagination';
import Loader from '../../../components/loader';
import Sidebar from '../../../components/sideModal';
import Success from '../../../components/successModal';
import ArchiveModal from './archiveModal';
import EditArticleModal from './editArticleModal';
import AddArticleModal from './addArticleModal';
import Warning from '../../../components/successModal';
import ArticleCard from './articleCard';
import { getAllMeasurementUnits } from '../../pausal/common/measurementUnits';
import useUnitsStore from '../../../store/measurementUnits';
import { getUnitByValue } from '../../pausal/common/measurementUnits';
import useResponsive from '../../../hooks/responsive/useResponsive';
import { breakpoints, MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';
import Checkbox from '../../../components/checkbox';
import ConfirmModal from '../../../components/confirmModal';
import { toast } from 'react-toastify';

const ListOfArticles: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const articleTypeOptions = [
        { label: t('pages.editInvoice.addServiceModal.product').text, value: 'product' },
        { label: t('pages.editInvoice.addServiceModal.service').text, value: 'service' },
    ];
    const [measurementUnits, setMeasurementUnits] = useState<Array<{ value: string; label: string }>>([]);
    const [successModal, setSuccessModal] = useState(false);
    const [addArticleDropdown, setAddArticleDropdown] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [clickedArticle, setClickedArticle] = useState<ObjectKeys>({});
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const [articles, setArticles] = useState<ObjectKeys>({});
    const { isMobile, isTabletAndDown } = useResponsive();

    const [warningModal, setWarningModal] = useState(false);

    const [showSearch, setShowSearch] = useState(false);
    const [searchWord, setSearchWord] = useState('');

    const { units } = useUnitsStore();

    const isDarkTheme = useTheme();

    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [selectedArticlesArray, setSelectedArticlesArray] = useState<string[]>([]);

    const [actionModal, setActionModal] = useState<boolean>(false);

    const tabs = [
        t('pages.listOfArticles.tabs.articlesList').text,
        t('pages.listOfArticles.tabs.archivedArticles').text,
    ];

    const mobileTabs: any = [
        { value: 0, label: t('pages.listOfArticles.tabs.articlesList').text },
        { value: 1, label: t('pages.listOfArticles.tabs.archivedArticles').text },
    ];

    const [searchValue, setSearchValue] = useState('');

    const [showItems] = useState([
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
    ]);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: '',
        searchFields: 'name:=;',
        orderBy: 'created_at',
        sortedBy: 'desc',
    });

    const getArticles = (): void => {
        setLoaderVisible(true);
        setSelectedArticlesArray([]);
        setIsAllChecked(false);
        if (tabIndex === 0) {
            communication
                .getAllArticles({
                    ...params,
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        if (res?.data?.data?.length === 0 && params.page > 1) {
                            setParams({ ...params, page: params.page - 1 });
                        } else {
                            const newUnits = res?.data.data
                                .map((item: ObjectKeys) => item.default_measurement_unit)
                                .filter((unit: any) => !units.includes(unit));

                            useUnitsStore.setState({ units: [...units, ...newUnits] });
                            setLoaderVisible(false);
                            setArticles(res?.data);
                        }
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setArticles([]);
                    }
                });
        } else if (tabIndex === 1) {
            const updatedParams = { ...params, orderBy: 'updated_at' };
            communication
                .getArchivedArticles(updatedParams)
                .then((res: ObjectKeys) => {
                    if (res?.data?.data?.length === 0 && params.page > 1) {
                        setParams({ ...params, page: params.page - 1 });
                    } else {
                        setLoaderVisible(false);
                        setArticles(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setArticles([]);
                    }
                });
        }
    };

    const handlePageClick = (e: any): void => {
        setParams({ ...params, page: e.selected + 1 });
    };

    //Client Change
    const handleChange = (service: ObjectKeys): void => {
        setClickedArticle(service);
        setEditClientModal(true);
    };

    //Client Archive Modal - not archived clients
    const handleArchiveModal = (service: ObjectKeys): void => {
        setGlobalModal(
            <ArchiveModal
                t={t}
                clickedService={service}
                closeGlobalModal={closeGlobalModal}
                setClients={setArticles}
                tabIndex={tabIndex}
                success={() => {
                    getArticles();
                }}
            />,
        );
    };

    const handleActivate = (serviceId: string): void => {
        setLoaderVisible(true);
        communication
            .activateArticle(serviceId)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setSuccessModal(true);
                }
            })
            .then(() => {
                if (tabIndex === 1) {
                    communication
                        .getArchivedArticles({
                            ...params,
                            search: '',
                        })
                        .then((res: ObjectKeys) => {
                            if (res.status === 200) {
                                setLoaderVisible(false);
                                setArticles(res?.data);
                            }
                        });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };

    const handleClickArticle = (service: ObjectKeys): void => {
        setClickedArticle(service);
    };

    const handleSelectAllArticles = (isChecked: boolean): void => {
        setIsAllChecked(isChecked);
        if (isChecked) {
            const newArticlesIds = articles?.data?.map((separateObject: any) => separateObject.id) || [];
            setSelectedArticlesArray(newArticlesIds);
        } else {
            setSelectedArticlesArray([]);
        }
    };

    const handleSelectedCard = (id: string): void => {
        const isArticleInArray = selectedArticlesArray.find((selectedArticle) => selectedArticle === id);
        if (isArticleInArray) {
            const filteredArticlesList = selectedArticlesArray.filter((oneArticle) => oneArticle !== id);
            setSelectedArticlesArray(filteredArticlesList);
        } else {
            setSelectedArticlesArray((prevState) => [...prevState, id]);
        }
    };

    const handleArchiveArticles = () => {
        const articlesObject = {
            ids: selectedArticlesArray,
        };
        setLoaderVisible(true);
        try {
            communication.archiveArticles(articlesObject).then((res: ObjectKeys) => {
                if (res.status === 204) {
                    getArticles();
                    setSelectedArticlesArray([]);
                    handleSelectAllArticles(false);
                    toast.success(t('pages.listOfArticles.successes').text);
                }
            });
        } catch (error) {
            setLoaderVisible(false);
            console.error('Error', error);
        }
    };
    const handleActivateArticles = async () => {
        const articlesObject = {
            ids: selectedArticlesArray,
        };
        setLoaderVisible(true);
        try {
            communication.activateArticles(articlesObject).then((res: ObjectKeys) => {
                if (res.status === 204) {
                    getArticles();
                    setSelectedArticlesArray([]);
                    handleSelectAllArticles(false);
                    toast.success(t('pages.listOfArticles.successActivates').text);
                }
            });
        } catch (error) {
            console.error('Error', error);
        }
    };

    useEffect(() => {
        getArticles();
    }, [params]);

    useEffect(() => {
        setParams({
            ...params,
            page: 1,
        });
        if (tabIndex === 1) setAddArticleDropdown(false);
    }, [tabIndex]);

    useEffect(() => {
        const unitMeasurementData = getAllMeasurementUnits(t, 'domestic');
        setMeasurementUnits(unitMeasurementData as { value: string; label: string }[]);
    }, []);

    useEffect(() => {
        setMeasurementUnits(() => {
            const updatedMeasurementUnits: { value: string; label: string }[] = [];
            units.forEach((item) => {
                const label = getUnitByValue(item, false, false);
                if (label !== null) {
                    updatedMeasurementUnits.push({ value: item, label: label });
                }
            });
            return updatedMeasurementUnits;
        });
    }, [units]);

    useEffect(() => {
        if (searchWord !== '') {
            setParams({
                ...params,
                search: `name:${searchWord}`,
                page: 1,
                searchFields: 'name:like',
            });
        } else {
            setParams({
                ...params,
                search: '',
                page: 1,
                searchFields: 'name:=;',
            });
        }
    }, [searchWord]);

    return (
        <>
            {successModal && (
                <Modal modalVisible={true} closeModal={() => setSuccessModal(false)}>
                    <Success
                        close={() => setSuccessModal(false)}
                        message={t('pages.listOfArticles.successActivate').text}
                    />
                </Modal>
            )}
            {warningModal && (
                <Modal modalVisible={warningModal} closeModal={() => setWarningModal(false)}>
                    <Warning
                        close={() => {
                            setWarningModal(false);
                        }}
                    >
                        {/* <WarningArticleCard clients={clientsDuplicated} /> */}
                    </Warning>
                </Modal>
            )}
            {editClientModal && clickedArticle && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditArticleModal
                        t={t}
                        clickedArticle={clickedArticle}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setArticles}
                        tabIndex={tabIndex}
                        success={() => {
                            getArticles();
                        }}
                        measurementUnits={measurementUnits}
                        articleTypeOptions={articleTypeOptions}
                    />
                </Sidebar>
            )}
            {actionModal && (
                <Modal modalVisible={actionModal} closeModal={() => setActionModal(!actionModal)}>
                    <ConfirmModal
                        action={() => {
                            setActionModal(false);
                            tabIndex === 0 ? handleArchiveArticles() : handleActivateArticles();
                        }}
                        close={() => setActionModal(false)}
                        active={actionModal}
                        message={
                            tabIndex !== 1
                                ? t('pages.healthCards.newCardModal.archiveArticles').text
                                : t('pages.healthCards.newCardModal.activateArticles').text
                        }
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="articles page">
                <Header isDarkTheme={isDarkTheme}>
                    <div>
                        <h1>{t('pages.listOfArticles.title').text}</h1>
                    </div>
                    <AddButtonContainer>
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            icon={!isMobile ? true : false}
                            size={'auto'}
                            onClick={() => {
                                setAddArticleDropdown((prev) => !prev);
                                setTabIndex(0);
                            }}
                        >
                            <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                            {!isMobile && t('pages.listOfArticles.addArticle').text}
                        </Button>
                    </AddButtonContainer>
                </Header>

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabsItems isDarkTheme={isDarkTheme}>
                        <TabList>
                            {isTabletAndDown ? (
                                <div style={{ marginTop: '10px' }}>
                                    <SelectComponent
                                        className="select-container tabs-select"
                                        optionList={mobileTabs}
                                        handleSelectedValue={(value: any) => setTabIndex(value)}
                                        defaultSelectedOption={tabs[0]}
                                        placeholder={t('pages.transfers.choose').text}
                                    />
                                </div>
                            ) : (
                                <>
                                    {tabs?.map((tab, index) => (
                                        <Tab key={index}>{tab}</Tab>
                                    ))}
                                </>
                            )}
                        </TabList>
                    </TabsItems>
                </Tabs>
                <Filters>
                    <ShowSelect isDarkTheme={isDarkTheme}>
                        <SelectComponent
                            optionList={showItems}
                            label={t('pages.listOfArticles.filters.show.label').text}
                            handleSelectedValue={(value: string) => setParams({ ...params, limit: value })}
                            defaultSelectedOption={showItems[0]}
                        />
                        <p>{t('pages.listOfArticles.filters.show.articles').text}</p>
                    </ShowSelect>
                    <Search
                        placeholder={t('pages.invoices.filters.search').text}
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            setShowSearch(true);
                        }}
                        onLeaveOrEnter={(value: string) => {
                            setSearchWord(value);
                            setShowSearch(false);
                            setSearchValue(value);
                        }}
                        showSearchNote={showSearch}
                        className="search-wrapper"
                    />
                </Filters>
                {addArticleDropdown && (
                    <Sidebar close={() => setAddArticleDropdown(false)}>
                        <AddArticleModal
                            t={t}
                            setEditClientModal={setAddArticleDropdown}
                            success={() => {
                                setAddArticleDropdown(false);
                                getArticles();
                            }}
                            type={'service'}
                            measurementUnits={measurementUnits}
                        />
                    </Sidebar>
                )}

                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    {tabs?.map((tab: string, index: number) => (
                        <TabPanel key={index} className={tab}>
                            {articles?.data?.length > 0 ? (
                                <>
                                    <SelectAllSection>
                                        <div
                                            className="selectCheckbox"
                                            onClick={() => handleSelectAllArticles(!isAllChecked)}
                                        >
                                            <Checkbox
                                                onChange={(value: any) => handleSelectAllArticles(value)}
                                                label={t('pages.agency.extra_services.select_all').text}
                                                defaultChecked={isAllChecked}
                                            />
                                        </div>
                                        {selectedArticlesArray.length > 0 && (
                                            <div className="userControlSection">
                                                <div className="selectionDiv" onClick={() => setActionModal(true)}>
                                                    <span>
                                                        {tabIndex === 0
                                                            ? t('pages.clientCard.dropdownItems.archive').text
                                                            : t('pages.agency.subscription.activate').text}
                                                    </span>
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={tabIndex === 0 ? colors.danger : colors.green}
                                                        icon={tabIndex === 0 ? faTrashCan : faCircleCheck}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </SelectAllSection>
                                    {articles?.data?.map((service: ObjectKeys) => (
                                        <div
                                            key={service.id}
                                            onClick={() => {
                                                tabIndex === 0 && handleChange(service);
                                            }}
                                        >
                                            {tabIndex === 0 ? (
                                                <ArticleCard
                                                    cardData={service}
                                                    cardType={'sd'}
                                                    handleChange={() => handleChange(service)}
                                                    handleDelete={(
                                                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    ) => {
                                                        handleArchiveModal(service);
                                                        e.stopPropagation();
                                                    }}
                                                    handleClick={() => handleClickArticle(service)}
                                                    selectedClientCard={handleSelectedCard}
                                                    clientIdArray={selectedArticlesArray}
                                                />
                                            ) : (
                                                <ArticleCard
                                                    cardData={service}
                                                    cardType={'archived'}
                                                    handleActivate={(
                                                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                                    ) => {
                                                        handleActivate(service?.id);
                                                        e.stopPropagation();
                                                    }}
                                                    handleClick={() => {
                                                        console.error('test');
                                                    }}
                                                    selectedClientCard={handleSelectedCard}
                                                    clientIdArray={selectedArticlesArray}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </>
                            ) : tabIndex === 0 ? (
                                <NoItems text={t('pages.listOfArticles.noArticles').text} />
                            ) : (
                                <NoItems text={t('pages.listOfArticles.noAchivedArticles').text} />
                            )}
                        </TabPanel>
                    ))}
                </Tabs>

                {articles?.data?.length > 0 && articles?.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={articles?.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            articles?.meta?.pagination?.current_page === articles?.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${articles?.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={articles?.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}
const PageWrapper = styled.div``;

const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    button {
        margin-left: 35px;
        height: 35px;
        font-size: 12px;
        line-height: initial;
    }
    > div {
        display: flex;
        align-items: center;
    }
    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        justify-content: space-between;
    }
`;
const AddButtonContainer = styled.div`
    position: relative;
`;

const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;

const TabsItems = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    background: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    text-transform: uppercase;
    padding: 10px 30px;
    margin-bottom: 20px;
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    > p {
        margin-right: 6px;
        font-size: 14px;
    }
    .react-tabs__tab-list {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        .react-tabs__tab {
            padding: 11px 20px;
            font-size: 14px;
            border-bottom: 3px solid transparent;
            list-style: none;
            cursor: pointer;
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                border-color: var(--white);
                color: white;
                border-radius: 0;
                border-bottom: 3px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--white)')};
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
`;
const Filters = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
    .search-wrapper {
        input {
            width: 100%;
        }
        width: 150px;
        height: 42px;
    }
`;
const ShowSelect = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    .select-wrapper {
        width: auto;
        label {
            color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        }
    }
    p {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        margin-left: 6px;
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92a5ac')};
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: none;
        }
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
export default ListOfArticles;
