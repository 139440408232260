import { FunctionComponent, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip, faPaperPlane, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import TextArea from '../../../../components/input/textarea';
import useEmailValidation from '../../common/useEmailValidation';
import communication from '../../../../communication';
import Checkbox from '../../../../components/checkbox';
import useProfileStore from '../../../../store/profile';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllProforma from '../getAllProforma';
import useSendMeEmail from '../../../../hooks/useSendMeEmail';
import { generateQrCode } from '../../../../communication/qr';
import useAgencyStore from '../../../../store/agency';
import { toast } from 'react-toastify';
import { useMemosQuery } from '../../../../react-query/useMemosQuery';
import SelectSearch from '../../../../components/selectSearch';
import useUserStore from '../../../../store/user';

interface Props {
    t: Function;
    formData: ObjectKeys;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    clientEmails?: Array<string>;
    id?: string;
    setProforma: Dispatch<SetStateAction<ObjectKeys>>;
    modalVisible: boolean;
    handleAddNewProforma?: Function;
    handleUpdateProforma?: Function;
    qrCode?: string;
    proforma: ObjectKeys;
    editInvoice?: Function;
}

const SendModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    id,
    clientEmails,
    setProforma,
    handleAddNewProforma,
    handleUpdateProforma,
    qrCode,
    modalVisible,
    proforma,
    editInvoice,
}) => {
    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(true);

    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>([]);
    const [sendMe, setSendMe] = useState(false);
    const [addedFiles, setAddedFiles] = useState<Array<File>>([]);
    const { profile } = useProfileStore();
    const [userEmail, setUserEmail] = useState('');
    const isEmailValid = useEmailValidation(formData.recipient, emails);
    const [fromInput, setFromInput] = useState(false);
    const [memoForSelect, setMemoForSelect] = useState([]);
    const [filteredMemos, setFilteredMemos] = useState<string[]>([]);
    const isProformaMemorandumLimitReached = proforma?.memorandums?.data
        ? proforma?.memorandums?.data?.length >= 3
        : proforma?.memorandums?.length >= 3;

    const { agency } = useAgencyStore();
    const user = agency && agency.user.data;

    const { userBOid } = useUserStore();
    const role = localStorage.getItem('role') === 'admin' ? 'admin' : 'user';
    const { memos } = useMemosQuery(role, userBOid);

    useEffect(() => {
        if (memos) {
            setMemoForSelect(
                memos.map((memo: ObjectKeys) => {
                    return {
                        value: memo.id,
                        label: memo.heading,
                    };
                }),
            );
        }
    }, [memos]);

    useEffect(() => {
        if (!proforma) return;
        if (proforma.memorandums?.data) {
            const prevMemorandums = proforma.memorandums.data.map((memo: ObjectKeys) => memo.id);
            setFilteredMemos(prevMemorandums);
        } else if (proforma.memorandums && proforma.memorandums.length > 0) {
            const prevMemorandums = Array.isArray(proforma.memorandums) ? proforma.memorandums : [];
            setFilteredMemos(prevMemorandums);
        } else {
            setFilteredMemos([]);
        }
    }, [proforma]);

    useEffect(() => {
        if (!proforma) return;
        setFormData({
            recipient: '',
            subject: `${proforma.type === 'foreign' ? 'Proforma invoice for' : 'Profaktura za'} ${
                proforma.client.data.company_name
            }`,
            message: `${proforma.type === 'foreign' ? 'Dear' : 'Poštovani'} ${proforma.client.data.company_name},\n\n${
                proforma.type === 'foreign'
                    ? 'Please find attached the proforma invoice for your review. Should you have any questions or require any changes, feel free to contact me.'
                    : 'U prilogu ovog mejla vam  šaljem profakturu. Molim vas da je pregledate, a ukoliko imate bilo kakvih pitanja ili potrebne izmene, slobodno me kontaktirajte.'
            } ${
                proforma.type === 'foreign'
                    ? '\n\nIf this e-mail was sent by mistake, please contact'
                    : '\n\nAko je ovaj mejl poslat greškom molimo Vas kontaktirajte'
            } ${agency.agency_email}\n\n${proforma.type === 'foreign' ? 'Best regards' : 'S poštovanjem'},\n${
                user.first_name
            } ${user.last_name}`,
        });
    }, [modalVisible]);

    const handleDataToSend = async (proformaId?: string, qr_code?: string): Promise<void> => {
        if (proformaId) {
            loading(true);
            const form = new FormData();
            for (let i = 0; i < addedFiles.length; i++) {
                const file = addedFiles[i];
                form.append('attachments[' + i + ']', file);
            }
            const requestData = {
                message: formData.message,
                subject: formData.subject,
                emails: emails,
                qr_code:
                    qr_code !== '/'
                        ? `data:image/jpeg;base64,${qr_code}`
                        : qrCode
                        ? `data:image/jpeg;base64,${qrCode}`
                        : '',
            };
            form.append('request_body', JSON.stringify(requestData));
            try {
                const res = await communication.sendProforma(proformaId, form);

                if (res.status === 200) {
                    loading(false);
                    success();
                    close();
                    getAllProforma(setProforma);
                }
            } catch (e: any) {
                console.error(e.response.data.message);
                loading(false);
            }
        } else setShowError(true);
    };

    const sendEmail = async (): Promise<void> => {
        if (isEmailValid === '' && emails?.length > 0) {
            if (handleAddNewProforma !== undefined) {
                const newProforma = await handleAddNewProforma();
                if (newProforma) {
                    if (!newProforma.foreign) {
                        try {
                            generateQrCode(
                                agency.full_name,
                                newProforma.client,
                                newProforma.amount,
                                newProforma.number,
                                newProforma.account,
                                newProforma.currency,
                                true,
                                newProforma.number ? `00${newProforma.number?.replace('/', '-')}` : null,
                                '221',
                            )
                                .then((response: ObjectKeys | any) => {
                                    if (response.i) {
                                        handleDataToSend(newProforma.id, response.i);
                                    } else {
                                        handleDataToSend(newProforma.id, '/');
                                    }
                                })
                                .catch((error: any) => {
                                    console.error(error);
                                    handleDataToSend(newProforma.id, '/');
                                });
                        } catch (e: any) {
                            console.error(e);
                            handleDataToSend(newProforma.id, '/');
                        }
                    } else {
                        handleDataToSend(newProforma.id, '/');
                    }
                }
            } else if (handleUpdateProforma !== undefined) {
                const updatedProforma = await handleUpdateProforma();
                if (updatedProforma) {
                    if (!updatedProforma.foreign) {
                        try {
                            generateQrCode(
                                agency.full_name,
                                updatedProforma.client.data.company_name,
                                updatedProforma.value_in_rsd,
                                updatedProforma.quotation_number,
                                updatedProforma.bank_account,
                                updatedProforma.currency,
                                true,
                                updatedProforma.quotation_number
                                    ? `00${updatedProforma?.quotation_number?.replace('/', '-')}`
                                    : null,
                                '221',
                            )
                                .then((response: ObjectKeys | any) => {
                                    if (response.i) {
                                        handleDataToSend(updatedProforma.id, response.i);
                                    } else {
                                        handleDataToSend(updatedProforma.id, '/');
                                    }
                                })
                                .catch((error: any) => {
                                    console.error(error);
                                    handleDataToSend(updatedProforma.id, '/');
                                });
                        } catch (e: any) {
                            console.error(e);
                            handleDataToSend(updatedProforma.id, '/');
                        }
                    } else {
                        handleDataToSend(updatedProforma.id, '/');
                    }
                }
            } else {
                handleDataToSend(id, '/');
            }
        } else setShowError(true);
    };
    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );

    const validateEmail = (email: string, emailsArray: string[]): boolean => {
        if (!email) return false; // Reject empty emails

        // Check for duplicates (excluding the current email being checked)
        const duplicateCount = emailsArray.filter((e) => e === email).length;
        if (duplicateCount > 1) return false; // If email appears more than once, reject it

        // Validate email format
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailRegex.test(String(email).toLowerCase());
    };

    const handleAttachInvoice = (event: any): void => {
        if (event && event.target && event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > 5) {
                toast.error(t('pages.error.overSize').text);
                event.target.value = null;
            } else {
                setAddedFiles([...addedFiles, file]);
            }
        }
    };

    const handleRemoveFile = (indexToRemove: number): void => {
        const updatedFiles = addedFiles.filter((file, index) => index !== indexToRemove);
        setAddedFiles(updatedFiles);
    };

    useEffect(() => {
        let selectedMemorandums: string[] = [];

        if (proforma?.memorandums?.data) {
            selectedMemorandums = proforma.memorandums.data.map((item: { id: string }) => item.id);
        } else if (proforma?.memorandums) {
            selectedMemorandums = Array.isArray(proforma.memorandums) ? proforma.memorandums : [];
        }

        // Filter out memorandums that are already selected
        const filteredMemorandums = memos?.filter((memo: ObjectKeys) => !selectedMemorandums.includes(memo.id));

        setMemoForSelect(
            filteredMemorandums?.map((memo: ObjectKeys) => ({
                value: memo.id,
                label: memo.heading,
            })),
        );
    }, [proforma?.memorandums, memos]);

    useEffect(() => {
        if (clientEmails && clientEmails?.length > 0) {
            const validatedEmails = (clientEmails || []).filter((email: string) => validateEmail(email, clientEmails));
            setEmails(validatedEmails);
        }
    }, [clientEmails]);

    return (
        <>
            <Container>
                <Header>
                    <h2>{t('pages.proforma.sendModal.title').text}</h2>
                    <Icon onClick={() => close()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>
                <label>{t('pages.proforma.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.length > 0 &&
                        emails
                            ?.filter((email: string) => email !== '')
                            .map((e: string) => (
                                <Email key={e}>
                                    {e}
                                    <span onClick={() => handleRemoveEmail(e)}>x</span>
                                </Email>
                            ))}
                </div>
                <Input
                    className="email"
                    type={InputComponentType.Text}
                    value={formData.recipient ? formData.recipient : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, recipient: value });
                    }}
                    onEnter={() => {
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    validation={showError ? isEmailValid : ''}
                    handleBlurAction={() => {
                        if (emails.includes(formData.recipient)) {
                            setFormData({ ...formData, recipient: '' });
                            return;
                        }
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setFromInput(true);
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                />

                <Input
                    type={InputComponentType.Text}
                    label={t('pages.proforma.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                />
                <TextArea
                    label={t('pages.proforma.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    height={'350px'}
                />
                <Footer>
                    <div>
                        <Icon className="attach">
                            <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                            {t('pages.proforma.sendModal.attached').text}
                            {addedFiles.length > 0 ? (
                                <AttachedFiles>
                                    {addedFiles.map((file: ObjectKeys, index: number) => {
                                        return (
                                            <div key={index} className="attached-files">
                                                <p>
                                                    <FontAwesomeIcon
                                                        icon={faPaperclip}
                                                        style={{ color: 'var(--gray)', marginRight: '3px' }}
                                                    />
                                                    {file.name}
                                                </p>
                                                <button
                                                    onClick={() => handleRemoveFile(index)}
                                                    className="delete_button"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrashCan}
                                                        style={{ color: colors.danger }}
                                                    />
                                                </button>
                                            </div>
                                        );
                                    })}
                                </AttachedFiles>
                            ) : (
                                <></>
                            )}
                        </Icon>
                        <div className="send-copy">
                            <Checkbox onChange={() => setSendMe(!sendMe)} />
                            {t('pages.proforma.sendModal.sendMeCopy').text}
                        </div>
                    </div>

                    <UploadFiles>
                        <input type="file" onChange={(e: any) => handleAttachInvoice(e)} />
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--black)', marginRight: '3px' }} />
                        <span>{t('pages.invoices.sendModal.attach').text}</span>
                    </UploadFiles>
                </Footer>
                <GroupedMemoSection>
                    {filteredMemos?.length > 0 ? (
                        <MemoDisplaySection>
                            <div>
                                <div className="selected-memos">
                                    {filteredMemos.map((memo: string) => {
                                        const selectedMemo = memos?.find((m: ObjectKeys) => m.id === memo);
                                        return (
                                            <div key={memo} className="selected-memo">
                                                <span>{selectedMemo?.heading}</span>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={() => {
                                                        let prevMemorandums: string[] = [];
                                                        if (proforma?.memorandums?.data) {
                                                            prevMemorandums = proforma.memorandums.data.map(
                                                                (item: { id: string }) => item.id,
                                                            );
                                                        } else if (proforma?.memorandums) {
                                                            prevMemorandums = Array.isArray(proforma.memorandums)
                                                                ? proforma.memorandums
                                                                : [];
                                                        } else {
                                                            prevMemorandums = [];
                                                        }
                                                        const updatedMemorandums = prevMemorandums.filter(
                                                            (m: string) => m !== memo,
                                                        );
                                                        const updatedInvoice = {
                                                            ...proforma,
                                                            memorandums: updatedMemorandums,
                                                        };
                                                        editInvoice && editInvoice(updatedInvoice);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </MemoDisplaySection>
                    ) : null}
                    <MemoSelect>
                        <div className="select-memos">
                            <h3>
                                {memoForSelect?.length > 0
                                    ? t('pages.editInvoice.previewModal.addedMemorandums').text
                                    : t('pages.editInvoice.previewModal.createFirstMemoNote').text}
                            </h3>
                            {memoForSelect?.length > 0 ? (
                                <>
                                    <SelectSearch
                                        optionList={memoForSelect}
                                        placeholder={t('pages.memos.chooseMemo').text}
                                        handleSelectedValue={(option: { label: string; value: string }) => {
                                            let prevMemorandums: string[] = [];
                                            if (proforma?.memorandums?.data) {
                                                prevMemorandums = proforma.memorandums.data.map(
                                                    (item: { id: string }) => item.id,
                                                );
                                            } else if (proforma?.memorandums) {
                                                prevMemorandums = Array.isArray(proforma.memorandums)
                                                    ? proforma.memorandums
                                                    : [];
                                            } else {
                                                prevMemorandums = [];
                                            }
                                            const updatedMemorandums = prevMemorandums.includes(option.value)
                                                ? prevMemorandums // If the value already exists, keep it as is
                                                : [...prevMemorandums, option.value]; // Add new value

                                            const updatedInvoice = { ...proforma, memorandums: updatedMemorandums };
                                            editInvoice && editInvoice(updatedInvoice);
                                        }}
                                        maxHeight={170}
                                        disabled={isProformaMemorandumLimitReached}
                                    />
                                    {isProformaMemorandumLimitReached ? (
                                        <p className="validation-message">
                                            {t('pages.editInvoice.previewModal.createMemoLimitation').text}
                                        </p>
                                    ) : null}
                                </>
                            ) : (
                                <p>{t('pages.editInvoice.previewModal.noCreatedMemos').text}</p>
                            )}
                        </div>
                    </MemoSelect>
                </GroupedMemoSection>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={sendEmail}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'var(--white)', marginRight: '10px' }} />
                        {t('pages.proforma.sendModal.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }
`;

const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    margin-bottom: 30px;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;

export const Footer = styled.div`
    > div {
        margin-top: 20px;
        font-size: 13px;
        color: var(--gray);
        display: flow-root;
        .send-copy {
            float: right;
            display: flex;
        }
    }
`;

export const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const UploadFiles = styled.label`
    cursor: pointer;
    font-size: 13px;
    span {
        font-size: 13px;
        color: var(--black);
    }
    &:hover {
        span {
            color: var(--purple);
        }
    }
    input[type='file'] {
        display: none;
    }
`;
const AttachedFiles = styled.div`
    margin-top: 10px;
    p {
        font-size: 13px;
        color: var(--gray);
        margin-bottom: 5px;
    }
    .attached-files {
        display: flex;
    }
    .delete_button {
        border: none;
        background: none;
        padding-left: 10px;
        margin-top: -5px;
    }
`;
const GroupedMemoSection = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

const MemoDisplaySection = styled.div`
    width: 100%;
    margin-left: auto;

    .selected-memo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 1rem;
        background-color: var(--light-gray);
        color: var(--black);

        span {
            margin-right: 10px;
            flex: 1;
            word-wrap: break-word;
        }

        svg {
            cursor: pointer;
            color: var(--purple);
            transition: color 0.3s;

            &:hover {
                color: #900;
            }
        }
    }

    .select-memos {
        margin-top: 10px;
    }

    .select-memos select {
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        color: #333;
        outline: none;
        transition: border-color 0.3s;

        &:focus {
            border-color: #0056b3;
        }

        &:hover {
            border-color: #888;
        }
    }

    .select-memos select:disabled {
        background-color: #f0f0f0;
        cursor: not-allowed;
    }
`;

const MemoSelect = styled.div`
    width: 100%;
    margin-top: 20px;
    min-height: 105px;
    border-radius: 8px;
    h3 {
        font-size: 18px;
        color: var(--black);
        margin-bottom: 20px;
        font-weight: 600;
    }

    .selected-memos {
        margin-bottom: 20px;
    }
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;
