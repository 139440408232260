import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import successImg from '../../../assets/successful-purple.png';
import { Link } from 'react-router-dom';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import communication from '../../../communication';
import { ObjectKeys } from '@/types/objectKeys';
import useAgencyStore from '../../../store/agency';
import Loader from '../../../components/loader';
import { formatTwoDecimals } from '../../../functions/format';
import CartCard from './cartCard';
import useProfileStore from '../../../store/profile';
import useServicesStore from '../../../store/services';
import getCurrentLanguage from '../../../functions/getCurrentLanguage';
import useCartStore from '../../../store/cart';

const PaymentSuccess: React.FC = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const lang = getCurrentLanguage();

    const [agency, setAgency] = useState<any>({});

    const [validation, setValidation] = useState(false);
    const [cart, setCart] = useState<any>({});
    const clearCart = useCartStore((state) => state.clearCart);
    const fetchCart = useCartStore((state) => state.fetchCart);

    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const urlParams = new URLSearchParams(window.location.search);

    const [role, setRole] = useState<string>('guest');

    const userThemeChoice = localStorage.getItem('theme');

    const isDarkTheme = userThemeChoice === 'dark';

    const params = {
        cart_id: urlParams.get('ShoppingCartID') || '',
        success: urlParams.get('Success') || '',
        approval_code: urlParams.get('ApprovalCode') || null,
        signature: urlParams.get('Signature') || '',
        ErrorMessage: urlParams.get('ErrorMessage') || '',
    };

    const transactionDetails = {
        cart_id: urlParams.get('ShoppingCartID') || '',
        order_id: urlParams.get('WsPayOrderId') || '',
        date_time: urlParams.get('DateTime') || '',
        approval_code: urlParams.get('ApprovalCode') || null,
        amount: urlParams.get('Amount') || '',
        first_name: urlParams.get('CustomerFirstname') || '',
        last_name: urlParams.get('CustomerSurname') || '',
        email: urlParams.get('CustomerEmail') || '',
    };

    const allParams: {
        [key: string]: string | string[];
    } = {};
    urlParams.forEach((value, key) => {
        allParams[key] = value;
    });

    const getCart = async (id: string): Promise<any> => {
        try {
            const res: ObjectKeys = await communication.getGuestCartById(id);
            if (res.data.data.user_id) {
                setRole('user');
            } else {
                setRole('guest');
            }
            setCart(res.data.data);
            return res.data.data;
        } catch (err: any) {
            console.error('err', err);
            throw err;
        }
    };

    const getMyAgency = async (): Promise<void> => {
        setLoaderVisible(true);
        try {
            const res: ObjectKeys = await communication.getAgency();
            setAgency(res.data.data);
            useAgencyStore.setState({ agency: res.data.data });
            useProfileStore.setState({ profile: res.data.data.user.data });
            setLoaderVisible(false);
        } catch (err: any) {
            setLoaderVisible(false);
            console.error('err', err);
            throw err;
        }
    };

    useEffect(() => {
        clearCart();
        getCart(params.cart_id).then((cartResponse) => {
            if (cartResponse && cartResponse.user_id) {
                getMyAgency();
                setTimeout(() => {
                    communication.getAgency().then((res: ObjectKeys) => {
                        setAgency(res.data.data);
                        useAgencyStore.setState({ agency: res.data.data });
                        useProfileStore.setState({ profile: res.data.data.user.data });
                        const pending_cart = res?.data?.data?.user?.data?.pending_cart;
                        if (pending_cart) {
                            fetchCart(pending_cart);
                        } else {
                            clearCart();
                        }
                    });
                }, 4000);
            } else {
                setRole('guest');
                getAllGuestServices();
            }
        });
        setValidation(true);
    }, []);

    const getAllGuestServices = (): void => {
        setLoaderVisible(true);
        communication
            .getAllGuestServices({ page: 1, limit: 1000, orderBy: `name_${lang}`, sortedBy: 'asc' })
            .then((response: ObjectKeys) => {
                useServicesStore.setState(() => ({
                    servicesData: response.data.data,
                }));
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                console.error('Error:', error);
                setLoaderVisible(false);
            });
    };

    const transformDateTimeFormat = (dateString: string): string => {
        // Parsing the string into date components
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        const hour = dateString.slice(8, 10);
        const minute = dateString.slice(10, 12);
        const second = dateString.slice(12, 14);

        // Creating a new Date object

        // Formatting the date and time
        const formattedDate = `${day}.${month}.${year}.`;
        const formattedTime = `${hour}:${minute}:${second}`;

        return `${formattedDate} ${formattedTime}`;
    };

    useEffect(() => {
        if (role === 'guest') {
            localStorage.removeItem('guestCart');
        }
    }, []);

    return (
        <Container isDarkTheme={isDarkTheme}>
            {loaderVisible && <Loader />}
            {validation ? (
                <>
                    <SuccessText>{t('pages.payments.success.title').text}</SuccessText>
                    <SuccessImage src={successImg} alt="Success" />
                    {role === 'guest' ? (
                        <InfoText>{t('pages.payments.success.guestDescription').text}</InfoText>
                    ) : (
                        <InfoText>{t('pages.payments.success.description').text}</InfoText>
                    )}
                </>
            ) : null}
            {transactionDetails ? (
                <div className="params">
                    <h3>{t('pages.payments.success.details').text}</h3>
                    <div className="success">
                        <p>{t('pages.payments.success.subDetails').text}</p>
                    </div>
                    <div className="flex">
                        <p>{t('pages.payments.success.customer_data').text}</p>
                        <div className="param">
                            <p>{t('pages.clients.modal.form.name').text}:</p>
                            <p>{transactionDetails.first_name}</p>
                        </div>
                        <div className="param">
                            <p>{t('pages.agency.personalInfo.lastName').text}:</p>
                            <p>{transactionDetails.last_name}</p>
                        </div>
                        {role === 'guest' && cart && cart.detail ? (
                            <div className="param">
                                <p>{t('pages.clients.modal.form.email').text}:</p>
                                <p>{cart.detail.data.email}</p>
                            </div>
                        ) : (
                            <div className="param">
                                <p>{t('pages.clients.modal.form.email').text}:</p>
                                <p>{agency.agency_email}</p>
                            </div>
                        )}

                        {role === 'user' && agency ? (
                            <div className="param">
                                <p>{t('pages.payments.success.pib').text}</p>
                                <p>{agency.pib}</p>
                            </div>
                        ) : null}
                        {role === 'guest' && cart && cart.detail ? (
                            <div className="param">
                                <p>{t('pages.payments.success.pib').text}</p>
                                <p>{cart.detail?.data.pib}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className="flex">
                        <p>{t('pages.payments.success.merchant_data').text}</p>
                        <div className="param">
                            <p>{t('pages.memos.name').text}:</p>
                            <p>Clever doo</p>
                        </div>
                        <div className="param">
                            <p>{t('pages.clients.modal.form.address').text}:</p>
                            <p>Starine Novaka 22, Beograd</p>
                        </div>
                        <div className="param">
                            <p>{t('pages.payments.success.pib').text}</p>
                            <p>109240501</p>
                        </div>
                    </div>
                    <CartTable>
                        <thead>
                            <tr>
                                <TableHeader colSpan={2}>{t('pages.agency.wallet.payment').text}</TableHeader>
                                <TableHeader>{t('pages.agency.wallet.payment_date').text}</TableHeader>
                                <TableHeader>{t('pages.agency.wallet.total_amount').text}</TableHeader>
                            </tr>
                        </thead>
                        <tbody>{cart && cart.id ? <CartCard key={cart.id} cart={cart} /> : null}</tbody>
                    </CartTable>
                    <div className="param">
                        <p>{t('pages.payments.success.order').text}:</p>
                        <p>{transactionDetails.cart_id}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.transactionId').text}:</p>
                        <p>{transactionDetails.order_id}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.dateTime').text}:</p>
                        <p>{transformDateTimeFormat(transactionDetails.date_time)}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.approvalCode').text}:</p>
                        <p>{transactionDetails.approval_code}</p>
                    </div>
                    <div className="param">
                        <p>{t('pages.payments.success.amount').text}:</p>
                        <p>{formatTwoDecimals(transactionDetails.amount)} RSD</p>
                    </div>
                </div>
            ) : null}
            {role === 'guest' ? (
                <Link to="/login">
                    <span className="link">{t('pages.payments.success.button').text}</span>
                </Link>
            ) : (
                <Link to="/settings/wallet">
                    <span className="link">{t('pages.payments.success.button_leave').text}</span>
                </Link>
            )}
        </Container>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

// Styled components
const Container = styled.div<DarkThemeProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#212121' : 'var(--light-gray)')};
    color: ${({ isDarkTheme }) => (isDarkTheme ? '#fff' : 'var(--black)')};
    padding: 20px;
    .link {
        font-size: 1rem;
        cursor: pointer;
    }
    .params {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .success {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            color: var(--green);
            p {
                font-size: 1rem;
            }
        }
        .param {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;
            p {
                font-size: 1rem;
            }
        }
    }
    .flex {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
    }
`;

const SuccessText = styled.h1`
    font-size: 2rem;
    color: var(--purple);
    margin-bottom: 20px;
`;

const SuccessImage = styled.img`
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
`;

const InfoText = styled.p`
    font-size: 1rem;
    margin-bottom: 20px;
`;
const CartTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
        font-size: 15px;
        color: var(--purple);
        justify-content: flex-start;
        p {
            font-size: 15px;
        }
    }
`;
const TableHeader = styled.th`
    padding: 10px;
    font-weight: normal;
`;

export default PaymentSuccess;
