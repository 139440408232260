import './global/styles.css';

import * as Sentry from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState } from 'react';

import GlobalModal from './components/globalModal';
import GlobalStyle from './global';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import enJson from './components/language/en.json';
import { flatten } from './functions/flatten';
import { router } from './routes';
import ruJson from './components/language/ru.json';
import srJson from './components/language/rs.json';
import useAgencyStore from './store/agency';
import { useEffect } from 'react';
import useGeneralStore from './store/general';
import useLanguageStore from './store/language';
import CookieConsent from 'react-cookie-consent';
import useTranslations from './hooks/useTranslation';
import { ObjectKeys } from './types/objectKeys';
import communication from './communication';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

const App: React.FunctionComponent = () => {
    const currentLangName = localStorage.getItem('language');

    const { agency } = useAgencyStore();
    const oldUser = agency && agency.user?.data?.old_user;

    const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

    const [isAnalyticsConsentGiven, setIsAnalyticsConsentGiven] = useState(
        agency?.user?.data?.cookie?.data?.google_analytics ?? false,
    );

    const [isTawkToConsentGiven, setIsTawkToConsentGiven] = useState(agency?.user?.data?.cookie?.data?.tawkto ?? false);

    const [isCookiesConsentGiven, setIsCookiesConsentGiven] = useState(
        agency?.user?.data?.cookie?.data?.consent_given ?? false,
    );

    const trackingId = isAnalyticsConsentGiven ? process.env.REACT_APP_TRACKING_ID : '';
    const gtmId = isAnalyticsConsentGiven ? process.env.REACT_APP_GA_MEASUREMENT_ID : '';

    const googleAuth = process.env.REACT_APP_GOOGLE_AUTH;

    const propertyId = oldUser ? '66713e1c981b6c56477e5f16' : '6671476d981b6c56477e6247';
    const widgetId = oldUser ? '1i0l5l8h7' : '1i0l7u11p';

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { closeGlobalModal } = useGeneralStore();
    const track_id = trackingId ? trackingId : '';
    const gmt_id = gtmId ? gtmId : '';
    ReactGA.initialize(track_id);
    const tagManagerArgs = {
        gtmId: gmt_id,
    };
    TagManager.initialize(tagManagerArgs);

    Sentry.init({
        dsn: sentryDsn,
        tracesSampleRate: 0.5,
        beforeSend(event: any, hint: any) {
            const error = hint ? hint.originalException.message : '';
            if (
                (error && error.includes('Request failed with status code 401')) ||
                error.includes('websocket error') ||
                error.includes('Beacons are only supported over HTTP(S).')
            ) {
                return null; // This prevents the event from being sent to Sentry
            }
            // Send the event to Sentry
            return event;
        },
    });

    const getCurrentDateTime = (): string => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const userCookieChoice = async (userChoice: 'accept' | 'reject'): Promise<void> => {
        const choice = userChoice === 'accept' ? true : false;
        const dataObject: ObjectKeys = {
            consent_given: true,
            consent_type: 'Custom',
            consent_version: 'v4',
            google_analytics: choice,
            tawkto: choice,
            consent_date: getCurrentDateTime(),
            google_analytics_updated: getCurrentDateTime(),
            tawkto_updated: getCurrentDateTime(),
        };

        try {
            communication
                .authCookiesGlobal(dataObject)
                .then((res: ObjectKeys) => {
                    if (res) {
                        useAgencyStore.setState((prevState) => ({
                            agency: {
                                ...prevState.agency,
                                user: {
                                    ...prevState.agency.user,
                                    data: {
                                        ...prevState.agency.user.data,
                                        cookie: {
                                            data: res.data.data,
                                        },
                                    },
                                },
                            },
                        }));
                    }
                })
                .then(() => {
                    communication.getAgency().then((res: ObjectKeys) => {
                        useAgencyStore.setState({ agency: res.data.data });
                    });
                });
        } catch (error) {
            console.error('Error', error);
        }
    };

    useEffect(() => {
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else if (currentLangName && currentLangName === 'Русский') {
            const flattenData = flatten(ruJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
        }
    }, [currentLangName, localStorage.language]);

    useEffect(() => {
        console.log('isCookiesConsentGiven:', isCookiesConsentGiven);
        window.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                closeGlobalModal();
            }
        });
    }, []);

    useEffect(() => {
        if (agency?.user?.data?.cookie?.data) {
            const cookieData = agency.user.data.cookie.data;
            setIsAnalyticsConsentGiven(cookieData.google_analytics);
            setIsTawkToConsentGiven(cookieData.tawkto);
            setIsCookiesConsentGiven(cookieData.consent_given);
        }
    }, [agency]);

    return (
        <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                <GlobalStyle />
                <GoogleOAuthProvider clientId={googleAuth ? googleAuth : ''}>
                    <GlobalModal />
                    {isTawkToConsentGiven && <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />}
                    <RouterProvider router={router} />
                    {!isCookiesConsentGiven && agency?.user?.data ? (
                        <CookieConsent
                            location="bottom" // Options: "top", "bottom", "none"
                            buttonText={t('pages.invoices.conditions.acceptAll').text} // Text for the accept button
                            declineButtonText={t('pages.invoices.conditions.decline').text} // Text for the decline button
                            enableDeclineButton // Show a decline button
                            flipButtons // Flip the position of accept and decline buttons
                            hideOnAccept // Hides the banner after acceptance
                            hideOnDecline // Hides the banner after decline
                            setDeclineCookie // Stores the decline choice in cookies
                            onAccept={() => {
                                userCookieChoice('accept');
                            }}
                            onDecline={() => {
                                userCookieChoice('reject');
                            }}
                            onOverlayClick={() => {
                                console.log('Overlay clicked');
                            }}
                            cookieName="cookieConsent" // Name of the stored cookie
                            cookieValue="true" // Value stored in the cookie
                            declineCookieValue="false" // Value stored when declined
                            expires={365} // Cookie expiration in days
                            buttonClasses="accept-button" // Add custom class to accept button
                            declineButtonClasses="decline-button" // Add custom class to decline button
                            containerClasses="cookie-banner-class" // Add custom class to the entire banner
                            disableStyles={false} // If true, removes all built-in styles
                            debug={false} // If true, logs to console
                        >
                            {t('pages.invoices.conditions.cookiesDisclaimer').text}{' '}
                            <a
                                href="/USLOVI_KORIŠĆENJA_Clever.pdf"
                                style={{ color: '#fff', textDecoration: 'underline' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('pages.invoices.conditions.learnMore').text}
                            </a>
                        </CookieConsent>
                    ) : null}
                </GoogleOAuthProvider>
            </Sentry.ErrorBoundary>
        </QueryClientProvider>
    );
};

export default App;
