import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Clients {
    public getArchivedClients(params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.clients.archivedClients,
            params: params,
        });
    }
    public getClients(id: string, params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.clients.users(id),
            params: params,
        });
    }
    public deleteClient(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            url: routes.clients.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public activateClient(id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.clients.activate(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public updateClient(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.clients.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public getClient(id: string): ObjectKeys {
        return fetch({
            method: 'GET',
            url: routes.clients.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
        });
    }
    public addClient(options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            url: routes.clients.addClient,
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public editClient(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            url: routes.clients.byId(id),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            data: options,
        });
    }
    public archiveClient(clientsIds: ObjectKeys): ObjectKeys {
        return fetch({
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            method: 'DELETE',
            url: routes.clients.archiveClient,
            data: clientsIds,
        });
    }
    public activateClients(clientsIds: ObjectKeys): ObjectKeys {
        return fetch({
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            method: 'POST',
            url: routes.clients.activateClient,
            data: clientsIds,
        });
    }
}
