import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../global/colors';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { getAnalytics } from '../../../communication/admin/analytics';
import Button, { ButtonVariant } from '../../../components/button';
import { useGoogleLogin } from '@react-oauth/google';
import { useBackOfficePasswordProtect } from '../../../hooks/backOfficePasswordProtect';

const GoogleAnalytics: FunctionComponent = () => {
    const [token, setToken] = useState('');
    const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
    const [country, setCountry] = useState('');
    const [users, setUsers] = useState(0);
    useBackOfficePasswordProtect();
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            toast.success('Uspešno ste se ulogovali');
            setToken(codeResponse.access_token);
        },
    });
    const getanalitycs = async (): Promise<void> => {
        await getAnalytics(token)
            .then((res: any) => {
                setUsers(res.rows[0].metricValues[0].value);
                setCountry(res.rows[0].dimensionValues[0].value);
                toast.success('Uspešno ste ažurirali podatke');
                setLastUpdate(new Date());
            })
            .catch((error: any) => {
                if (error) {
                    console.error(error);
                    toast.error(
                        'Došlo je do greške. Pokušajte ponovo da se ulogujete na Google nalog ili nema podataka za prikazivanje. Kontaktirajte developere ako se greska ponavlja.',
                    );
                }
            });
    };

    return (
        <>
            <Header>
                <h1>Google Analitika</h1>
                <Google>
                    <Button
                        textTransformNone
                        variant={ButtonVariant.outlined}
                        color={'var(--purple)'}
                        onClick={() => {
                            login();
                        }}
                        size={'100%'}
                    >
                        Google Log In
                    </Button>
                </Google>
            </Header>

            <ButtonContainer>
                <Button
                    textTransformNone
                    variant={ButtonVariant.outlined}
                    color={'var(--purple)'}
                    onClick={() => {
                        getanalitycs();
                    }}
                    size={'40%'}
                >
                    <FontAwesomeIcon icon={faSync} style={{ color: colors.purple }} />
                    <p style={{ marginLeft: '5px' }}>Ažuriraj podatke</p>
                </Button>
                {lastUpdate ? (
                    <p>
                        Poslednje ažuriranje:{' '}
                        {lastUpdate.toLocaleString('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        })}
                    </p>
                ) : (
                    <p style={{ color: 'red' }}>Da biste pristupili analitici morate imati odobren pristup</p>
                )}
            </ButtonContainer>
            <Content>
                <h1>Podaci</h1>
                <div className="analytics">
                    <h3>U poslednjih 30 minuta</h3>
                    <p>Broj korisnika: {users}</p>
                    <p>Zemlja: {country}</p>
                </div>
            </Content>
        </>
    );
};

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    width: 600px;
`;
const Google = styled.div`
    width: 250px;
`;
const Content = styled.div`
    margin-top: 20px;
    h1 {
        background-color: var(--purple);
        color: white;
        padding: 10px;
        border-radius: 5px;
    }
    .analytics {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        h3 {
            font-size: 1.2em;
            margin-bottom: 5px;
        }
    }
`;

export default GoogleAnalytics;
