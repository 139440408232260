import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightToFile,
    faCircleCheck,
    faCircleQuestion,
    faDownload,
    faPlus,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import communication from '../../communication';
import { generateQrCode } from '../../communication/qr';
import colors from '../../global/colors';
import useTranslations from '../../hooks/useTranslation';
import useOutsideClick from '../../hooks/useOutsideClick';
import useProfileStore from '../../store/profile';
import useLanguageStore from '../../store/language';
import useGeneralStore from '../../store/general';
import { ObjectKeys } from '../../types/objectKeys';
import { SendDocumentFormData } from '../../types/communication/document';
import { formatParams } from '../../functions/formatParams';
import { getAccessToken } from '../../functions/auth';
import { formatNumber } from '../../functions/format';
import Button, { ButtonVariant } from '../../components/button';
import InvoiceCard from '../../components/invoiceCard';
import NoItems from '../../components/noItems';
import Loader from '../../components/loader';
import Modal from '../../components/modal';
import Sidebar from '../../components/sideModal';
import Pagination from '../../components/pagination';
import Dropdown from '../../components/dropdown';
import Success from '../../components/successModal';
import ErrorModal from '../../components/errorModal';
import Filters from './filters';
import useInvoicesStore from '../../store/invoices';
import useAgencyStore from '../../store/agency';
import useParamsStore from '../../store/params';
import { MQ_BREAKPOINTS, breakpoints } from '../../constants/breakpoints';
import FilterTrack from './filterTrack';
import moment from 'moment';
import AccountExpired from '../../components/accountExpired';
import Tooltip from '../../components/tooltipMain';
import useResponsive from '../../hooks/responsive/useResponsive';
import Checkbox from '../../components/checkbox';
import useTheme from '../../hooks/useTheme';
import InvoiceSkeleton from './skeleton/invoiceCardSkeleton';

const Invoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { invoiceData, desiredCardView } = useInvoicesStore();
    const { agency } = useAgencyStore();
    const isAdmin = profile?.role === 'admin' ? true : false;

    const token = getAccessToken();
    const isAccountActive = isAdmin ? true : profile?.active === 1 ? true : false;

    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [success, setSuccess] = useState(false);
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);
    const { isDesktopLargeAndDown } = useResponsive();
    const lang = localStorage.language;

    const [params, setParams] = useState({
        page: 1,
        search: '',
        orderBy: 'created_at',
        sortedBy: 'desc',
        year: moment().year(),
    });

    const [display, setDisplay] = useState(desiredCardView);

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const [showSkeleton, setShowSkeleton] = useState(false);

    const tooltipUpload = t('pages.invoices.upload_tooltip').text;

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [selectedInvoicesArray, setSelectedInvoicesArray] = useState<string[]>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

    const isDarkTheme = useTheme();

    useEffect(() => {
        useInvoicesStore.setState({ desiredCardView: display });
    }, [display]);

    return (
        <>
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}

            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                </Modal>
            )}

            {loaderVisible && <Loader />}
            <PageWrapper className="invoices page">
                <Header isDarkTheme={isDarkTheme}>
                    <div>
                        <h1>Ulazne fakture</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color="var(--purple)"
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                Nova ulazna faktura
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown arrowRight="4px" itemList={[]} />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                    {isAccountActive && (
                        <div className="userInteraction">
                            <div className="uploadInvoice">
                                <Icon>
                                    <FontAwesomeIcon
                                        icon={faArrowRightToFile}
                                        fontSize={'15px'}
                                        color={isDarkTheme ? '#ffffff' : 'var(--purple)'}
                                    />
                                </Icon>
                                <p>{t('pages.invoices.upload_tooltip_header').text}</p>
                                <Tooltip
                                    text={tooltipUpload}
                                    isHtml={true}
                                    position={`${isDesktopLargeAndDown ? 'custom' : 'top'}`}
                                    customPositioning={{ top: '40%', right: '80%', transform: 'translateY(-50%)' }}
                                    zIndex={10}
                                    label={
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            fontSize={'15px'}
                                            style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                            color="var(--purple)"
                                        />
                                    }
                                />
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".pdf,.doc,.docx,.xlsx,.csv" // Specify accepted file types
                                />
                            </div>
                        </div>
                    )}
                </Header>
                {isAccountActive ? <></> : <AccountExpired />}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                    selectedUserView={display}
                />
                <FilterTrack
                    onChevronClick={(name: string, order: string) => {
                        setParams({ ...params, orderBy: name, sortedBy: order });
                    }}
                    t={t}
                />
                {invoiceData?.data?.length > 0 && isAccountActive && (
                    <SelectAllSection>
                        <div className="selectCheckbox">
                            <Checkbox
                                label={t('pages.agency.extra_services.select_all').text}
                                defaultChecked={isAllChecked}
                            />
                        </div>
                        {selectedInvoicesArray.length > 0 && (
                            <div className="userControlSection">
                                <div className="selectionDiv">
                                    <span>{t('pages.invoiceCard.dropdownItems.delete').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                </div>

                                <div className="selectionDiv">
                                    <span>{t('pages.invoiceCard.dropdownItems.charge').text}</span>
                                    <FontAwesomeIcon className="icon" color={colors.green} icon={faCircleCheck} />
                                </div>
                            </div>
                        )}
                    </SelectAllSection>
                )}
                {showSkeleton ? (
                    Array.from({ length: 10 }).map((_, index) => <InvoiceSkeleton key={index} />)
                ) : (
                    <>
                        <InvoicesList
                            className={invoiceData?.data?.length === 0 ? 'noItemsClass' : `display-${display}`}
                        >
                            {invoiceData?.data?.length > 0 ? (
                                invoiceData?.data?.map((invoicePrev: ObjectKeys, index: number) => {
                                    const invoice: ObjectKeys = {
                                        ...invoicePrev,
                                        services: invoicePrev.original_data.services,
                                        client: { data: invoicePrev.original_data.client },
                                    };
                                    // Get client updated emails
                                    const clientMails: Array<string> = (() => {
                                        if (invoicePrev.client?.data) {
                                            const emails =
                                                invoicePrev.client.data.clientEmails?.data?.map(
                                                    (item: ObjectKeys) => item.email,
                                                ) || [];
                                            return emails.length > 0
                                                ? emails
                                                : invoicePrev.client.data.email
                                                ? [invoicePrev.client.data.email]
                                                : [];
                                        }
                                        return [];
                                    })();
                                    return (
                                        <div key={invoice.id} className={`${display}`}>
                                            <InvoiceCard
                                                eInvoice={invoice?.is_einvoice}
                                                type={'invoice'}
                                                dropdownDirection={index > 1 ? 'up' : 'down'}
                                                cardData={invoice}
                                                cardDisplay={display}
                                                handleClick={() => {
                                                    console.log('click');
                                                }}
                                                handleSend={() => {
                                                    console.log('send');
                                                }}
                                                handleReminder={() => {
                                                    console.log('reminder');
                                                }}
                                                handleUpdatePayment={(e: string) => {
                                                    const specificPaymentValue = invoice?.payments?.data.filter(
                                                        (payment: ObjectKeys) => {
                                                            return payment.id === e;
                                                        },
                                                    );
                                                }}
                                                paymentInfo={() => void 0}
                                                cardIdArray={selectedInvoicesArray}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <>
                                    {hasInvoices ? (
                                        <NoItems text={t('pages.invoices.filters.noResults').text} />
                                    ) : (
                                        <NoItems text={t('pages.invoices.noItems.description').text} />
                                    )}
                                </>
                            )}
                        </InvoicesList>
                        {invoiceData?.data?.length > 0 && (
                            <Pagination
                                pageCount={invoiceData.meta?.pagination?.total_pages}
                                onPageChange={(e: ObjectKeys) => {
                                    setParams({ ...params, page: e.selected + 1 });
                                    useParamsStore.setState({ page: e.selected + 1 });
                                }}
                                nextLabel={`${
                                    invoiceData.meta?.pagination?.current_page ===
                                    invoiceData.meta?.pagination?.total_pages
                                        ? ''
                                        : '>'
                                }`}
                                previousLabel={`${invoiceData.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                                breakLabel="..."
                                initialPage={invoiceData.meta?.pagination?.current_page - 1}
                            />
                        )}
                    </>
                )}
            </PageWrapper>
        </>
    );
};
export default Invoices;

const PageWrapper = styled.div`
    min-height: 100vh; /* Default min-height */

    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileXS}) {
        min-height: 1400px;
    }

    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileS}) and (max-width: ${MQ_BREAKPOINTS.mobileM}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileM}) and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        min-height: 1300px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.mobileL}) and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        min-height: 1200px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        min-height: 1100px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptop}) and (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        min-height: 850px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 950px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopL}) {
        min-height: 1000px;
    }
`;

interface DarkThemeProps {
    isDarkTheme: boolean;
}

const Header = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }

    h1 {
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4c595f')};
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        margin-bottom: 0;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
        }
    }

    .userInteraction {
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .uploadInvoice,
    .downloadInvoice {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
            margin-right: 10px;
            font-size: 15px;
            line-height: 15px;
            color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : 'var(--purple)')};
            cursor: pointer;
        }

        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media screen and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: ${breakpoints.laptop - 1}px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: ${breakpoints.tablet - 1}px) {
            grid-template-columns: 1fr;
        }
    }
`;

const SelectAllSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;

    .selectCheckbox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }
    .userControlSection {
        display: flex;
        gap: 10px;

        .selectionDiv {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;
            gap: 5px;
            cursor: pointer;

            @media screen and (max-width: ${breakpoints.mobileS - 1}px) {
                flex-direction: column;
                margin-bottom: 14px;
            }
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
`;

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
