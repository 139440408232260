import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencil,
    faDownload,
    faSquare,
    faCheckSquare,
    faUserCheck,
    faPrint,
    faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import NoItems from '../../../components/noItems';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { generateQrCode } from '../../../communication/qr';
import { ObjectKeys } from '../../../types/objectKeys';
import TransferOrderTemplate from '../../../components/transferOrderTemplate';
import ConfirmModal from '../../../components/confirmModal';
import ManageOrdersModal from './manageOrdersModal';
import InfoModal from '../../../components/infoModal';
import { getAccessToken } from '../../../functions/auth';
import NoTransferOrderModal from './noTransferOrderModal';
import { useNavigate, useParams } from 'react-router-dom';
import Input, { InputComponentType } from '../../../components/input';
import { toast } from 'react-toastify';
import Success from './successModal';
import TransferOrderSkeleton from '../../../components/transferOrderSkeleton';
import TaxSolutionComponent from './taxSolution';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';
import useTheme from '../../../hooks/useTheme';
import ToggleSwitch from '../../../components/input/switch';
import Tooltip from '../../../components/tooltipMain';
import useResponsive from '../../../hooks/responsive/useResponsive';

interface SelectProps {
    label: string;
    value: string;
}
const TransferOrders: FunctionComponent = () => {
    const paramsYear = useParams().year;
    const open_manage_modal = useParams().generate;

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);

    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});

    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);

    const [transferTemplates, setTransferTemplates] = useState<ObjectKeys>([]);
    const [page, setPage] = useState<string>('1');

    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [transferModal, setTransferModal] = useState(false);

    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);

    const navigate = useNavigate();
    const [paymentDate, setPaymentDate] = useState<Date | null>(null);
    const [isChecked, setChecked] = useState(false);
    const [taxCalendarId, setTaxCalendarId] = useState<string>('');
    const [paidCheck, setPaidCheck] = useState<boolean>(false);
    const [showChekInput, setShowChekInput] = useState<boolean>(true);
    const [warningModal, setWarningModal] = useState(false);

    const [renderKey, setRenderKey] = useState<number>(0);
    const [renderKey2, setRenderKey2] = useState<number>(0);

    const isDarkTheme = useTheme();
    const [paymentSwitch, setPaymentSwitch] = useState<boolean>(false);
    const [orderType, setOrderType] = useState<'payment' | 'transfer'>('transfer');

    const { isTabletAndDown } = useResponsive();

    const tooltipText =
        orderType === 'transfer'
            ? t('pages.agency.extra_services.taxObligationTooltipOne').text
            : t('pages.agency.extra_services.taxObligationTooltipTwo').text;

    const getCurrentMonthIndex = (): number => {
        const currentDate = moment();
        const currentDayOfMonth = currentDate.date();
        if (currentDayOfMonth <= 15) {
            return currentDate.subtract(1, 'month').month();
        } else {
            return currentDate.month();
        }
    };
    const getInitialYear = (): string => {
        const currentDate = moment();
        const currentYear = currentDate.year();
        const currentMonth = currentDate.month();
        const currentDayOfMonth = currentDate.date();

        // If it's less than the 15th of January, return the previous year
        if (currentMonth === 0 && currentDayOfMonth <= 15) {
            return (currentYear - 1).toString();
        }
        return currentYear.toString();
    };

    const [selectedYear, setSelectedYear] = useState<string>(paramsYear || getInitialYear());
    const currentMonthIndex = getCurrentMonthIndex();

    const token = getAccessToken();

    useEffect(() => {
        if (open_manage_modal) {
            setIsManageOrdersModalActive(true);
        }
    }, [open_manage_modal]);

    const monthsOptions: Array<{ value: any; label: any }> = [
        { value: '1', label: t('pages.transfers.payment_lists.january').text },
        { value: '2', label: t('pages.transfers.payment_lists.february').text },
        { value: '3', label: t('pages.transfers.payment_lists.march').text },
        { value: '4', label: t('pages.transfers.payment_lists.april').text },
        { value: '5', label: t('pages.transfers.payment_lists.may').text },
        { value: '6', label: t('pages.transfers.payment_lists.june').text },
        { value: '7', label: t('pages.transfers.payment_lists.july').text },
        { value: '8', label: t('pages.transfers.payment_lists.august').text },
        { value: '9', label: t('pages.transfers.payment_lists.september').text },
        { value: '10', label: t('pages.transfers.payment_lists.october').text },
        { value: '11', label: t('pages.transfers.payment_lists.november').text },
        { value: '12', label: t('pages.transfers.payment_lists.december').text },
    ];

    const [currentMonth, setCurrentMonth] = useState<string>(
        open_manage_modal ? open_manage_modal : monthsOptions[currentMonthIndex].value,
    );

    const updateTaxCalendar = (date: Date | null): void => {
        const payload = {
            month: currentMonth ? currentMonth : monthsOptions[currentMonthIndex].value,
            year: parseInt(selectedYear),
            status: 'pending',
            paid_date: moment().format('YYYY-MM-DD'),
        };
        if (date) {
            setChecked(true);
            payload.status = 'paid';
            payload.paid_date = moment(date).format('YYYY-MM-DD');
        } else {
            setChecked(false);
            setPaymentDate(null);
        }
        try {
            communication
                .updateTaxCalendar(payload, taxCalendarId)
                .then(() => {
                    toast.success(
                        `${t('pages.transfers.payment_lists.success').text} ${selectedYear}. ${
                            t('pages.transfers.payment_lists._year').text
                        }`,
                    );
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message);
                })
                .then(() => {
                    getTaxCalendar();
                });
        } catch (error: any) {
            console.error('An error occurred:', error);
        }
    };

    useEffect(() => {
        if (paramsYear) {
            setSelectedYear(paramsYear);
            setRenderKey(renderKey + 1);
        }
    }, [paramsYear]);

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoader(true);
        const month = currentMonth ? currentMonth.toString() : monthsOptions[currentMonthIndex].value.toString();
        const res = await communication.getTransferTemplates(selectedYear, month);
        if (res.status === 200) {
            const transferTemplatesData = res.data.data;
            const sortedTransferTemplates = transferTemplatesData.sort((a: ObjectKeys, b: ObjectKeys) => {
                return a.orderTemplates - b.orderTemplates;
            });
            setTransferTemplates(sortedTransferTemplates);
            setIsLoader(false);
        }
    };

    const getTaxCalendar = async (): Promise<void> => {
        const params = {
            year: selectedYear,
            month: currentMonth ? currentMonth.toString() : monthsOptions[currentMonthIndex].value.toString(),
            status: '',
        };
        communication.getMonthlyTaxCalendar(params).then((res: ObjectKeys) => {
            if (res.data && res.data.data && !res.data.data[0]) {
                setShowChekInput(false);
                setPaymentDate(null);
                setChecked(false);
                setTaxCalendarId('');
                setPaidCheck(false);
            } else {
                setShowChekInput(true);
                // setPaidCheck(res.data.data[0].status === 'paid');
                if (res.data.data[0].status === 'paid') {
                    setPaidCheck(true);
                    const updated_at = res.data.data[0].paid_date;
                    const updatedAtDate = new Date(updated_at);
                    setPaymentDate(updatedAtDate);
                    setChecked(true);
                } else {
                    setPaymentDate(null);
                    setChecked(false);
                    setPaidCheck(false);
                }
                setTaxCalendarId(res.data.data[0].id);
            }
        });
    };

    useEffect(() => {
        getTaxCalendar();
        setRenderKey2(renderKey2 + 1);
    }, [transferTemplates]);

    const generateYearsForFilters = (): void => {
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        // eslint-disable-next-line for-direction
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
    };

    useEffect(() => {
        generateYearsForFilters();
        getTaxCalendar();
    }, []);

    useEffect(() => {
        setPage('1');
        getAllTransferTemplates();
        getTaxCalendar();
    }, [selectedYear, currentMonth]);

    useEffect(() => {
        getAllTransferTemplates();
    }, [page]);

    const getQrCode = async (transfer: ObjectKeys): Promise<string | undefined> => {
        try {
            const res: any = await generateQrCode(
                transfer?.recipient,
                transfer?.orderer,
                transfer.total_amount,
                transfer.purpose_of_payment,
                transfer.recipient_account,
                transfer.currency,
                true,
                `${
                    transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
                        ? transfer.model_number_recipient
                        : '00'
                }${transfer.call_number_recipient}`,
                transfer.payment_code,
            );
            setQrCode(res?.i);
            setIsQrCode(true);

            return res?.i;
        } catch (error) {
            setQrCode('');
            console.error('An error occurred:', error);
            return undefined;
        }
    };

    const getQrCodesForPrint = async (): Promise<{ [key: string]: string }> => {
        let qrCodes: { [key: string]: string } = {};
        const qrCodePromises: Promise<void>[] = [];

        for (const transfer of transferTemplates as any[]) {
            qrCodePromises.push(
                (async () => {
                    try {
                        qrCodes[transfer.id] = ''; // Initialize with an empty string
                        const res: any = await generateQrCode(
                            transfer?.recipient,
                            transfer?.orderer,
                            transfer.total_amount,
                            transfer.purpose_of_payment,
                            transfer.recipient_account,
                            transfer.currency,
                            true,
                            `${
                                transfer.model_number_recipient === '97' || transfer.model_number_recipient === '11'
                                    ? transfer.model_number_recipient
                                    : '00'
                            }${transfer.call_number_recipient}`,
                            transfer.payment_code,
                        );
                        if (res && res.i !== '') {
                            qrCodes[transfer.id] = res.i;
                        } else {
                            qrCodes = {};
                        }
                    } catch (err) {
                        console.error(err);
                        qrCodes = {}; // Set to empty string on error
                    }
                })(),
            );
        }
        await Promise.all(qrCodePromises);
        return qrCodes;
    };

    const downloadTaxes = async (): Promise<void> => {
        try {
            const qrCodes = await getQrCodesForPrint();
            if (Object.keys(qrCodes).length !== 0) {
                const hasInvalidValue = Object.values(qrCodes).some((value) => value === undefined || value === null);
                const qrCodesForDownload = hasInvalidValue ? '' : JSON.stringify(qrCodes);
                communication.downloadTaxes(selectedYear, currentMonth, token, qrCodesForDownload, orderType);
            } else {
                communication.downloadTaxes(selectedYear, currentMonth, token, '', orderType);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            communication.downloadTaxes(selectedYear, currentMonth, token, '', orderType);
        }
    };

    const downloadTax = async (transfer: ObjectKeys): Promise<void> => {
        try {
            const qrCodeR = await getQrCode(transfer);
            communication.downloadTax(
                transfer?.id,
                token,
                qrCodeR ? `data:image/jpeg;base64,${qrCodeR}` : '',
                orderType,
            );
        } catch (error) {
            console.error('An error occurred:', error);
            communication.downloadTaxes(selectedYear, currentMonth, token, '', orderType);
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoader(true);
        setIsDeleteModalActive(false);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAllTransferTemplates();
        }
        setIsLoader(false);
    };
    const getDayOf15th = (year: number, month: number): string => {
        if (month < 0 || month > 12) {
            throw new Error('Invalid month');
        }
        const fifteenthDate = new Date(year, month, 15);
        const dayOfWeekNumber = fifteenthDate.getDay();
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeek = dayNames[dayOfWeekNumber];
        return dayOfWeek;
    };

    const handleDateChange = (value: Date): void => {
        setPaymentDate(moment(value).toDate());
        updateTaxCalendar(moment(value).toDate());

        const currentMonthNumber = parseInt(currentMonth);
        const paymentMonth = moment(value).month() + 1;
        const paymentDay = moment(value).date();
        const paymentYear = moment(value).year();
        const day15th = getDayOf15th(parseInt(selectedYear), currentMonthNumber);
        if (paymentYear > parseInt(selectedYear)) {
            if (paymentYear - parseInt(selectedYear) > 1) {
                setWarningModal(true);
            } else if (paymentYear - parseInt(selectedYear) === 1) {
                if (paymentMonth === 1) {
                    if (paymentDay > 15) {
                        if (day15th === 'Saturday') {
                            if (paymentDay > 17) {
                                setWarningModal(true);
                            }
                        } else if (day15th === 'Sunday') {
                            if (paymentDay > 16) {
                                setWarningModal(true);
                            }
                        } else {
                            setWarningModal(true);
                        }
                    }
                } else {
                    setWarningModal(true);
                }
            }
        } else if (paymentYear === parseInt(selectedYear)) {
            if (paymentMonth - currentMonthNumber > 1) {
                setWarningModal(true);
            } else if (paymentMonth - currentMonthNumber === 1) {
                if (paymentDay > 15) {
                    if (day15th === 'Saturday') {
                        if (paymentDay > 17) {
                            setWarningModal(true);
                        }
                    } else if (day15th === 'Sunday') {
                        if (paymentDay > 16) {
                            setWarningModal(true);
                        }
                    } else {
                        setWarningModal(true);
                    }
                }
            }
        }
    };

    return (
        <PageWrapper>
            {warningModal && (
                <Modal modalVisible={true} closeModal={() => setWarningModal(false)}>
                    <Success close={() => setWarningModal(false)} />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-tax-modal'}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadTax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                        type={orderType}
                    />
                </Modal>
            )}

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal'}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => {
                        setIsManageOrdersModalActive(false);
                        setTransferModal(false);
                    }}
                >
                    {transferTemplates.length > 0 ? (
                        <ManageOrdersModal
                            closeModal={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            year={selectedYear}
                            setIsLoader={setIsLoader}
                            setOpenInfoModal={setIsInfoModalActive}
                            callNumber={transferTemplates[0].call_number_recipient}
                            refresh={() => {
                                getAllTransferTemplates();
                            }}
                            transferOrders={transferTemplates}
                            key={renderKey2}
                            currentMonth={open_manage_modal ? open_manage_modal : currentMonth}
                        />
                    ) : (
                        <NoTransferOrderModal
                            currentLangName={currentLangName}
                            t={t}
                            close={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            setIsLoader={setIsLoader}
                            setIsInfoModalActive={setIsInfoModalActive}
                            handleRefresh={() => getAllTransferTemplates()}
                            transferModalData={transferModal}
                            year={selectedYear}
                            currentMonth={open_manage_modal ? open_manage_modal : currentMonth}
                        />
                    )}
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.transfers.transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>

            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'Da li ste sigurni da želite da obrišete nalog za prenos?'}
                />
            </Modal>

            <Header transfers={transferTemplates.length > 0} isDarkTheme={isDarkTheme}>
                <Title>
                    <h1>{t('pages.transfers.title').text}</h1>
                    <div className="header-right">
                        <div className="header-options">
                            <div className="header-options-buttons">
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        navigate('/agency/authorization-transfer');
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faUserCheck} />
                                    {t('pages.agency.navbar.authorizationTransfer').text}
                                </Button>
                                <Button
                                    className={'button'}
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    onClick={() => {
                                        setIsManageOrdersModalActive(true);
                                    }}
                                    icon={true}
                                    height={35}
                                >
                                    <FontAwesomeIcon icon={faPencil} />
                                    {t('pages.transfers.manage_transfers').text}
                                </Button>

                                {transferTemplates.length > 0 && (
                                    <Button
                                        className={'button'}
                                        variant={ButtonVariant.outlined}
                                        color={colors.purple}
                                        onClick={() => {
                                            downloadTaxes();
                                        }}
                                        icon={true}
                                        height={35}
                                    >
                                        <FontAwesomeIcon icon={faPrint} />
                                        {t('pages.transfers.download_orders').text}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Title>

                <Filters isDarkTheme={isDarkTheme}>
                    <label className="second-title">{`${
                        t('pages.transfers.filter_first_description').text
                    } ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={monthsOptions}
                            defaultSelectedOption={monthsOptions[currentMonthIndex]}
                            className={'select-month'}
                            width={'15opx'}
                            handleSelectedValue={(value: string) => {
                                setCurrentMonth(value);
                            }}
                        />
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions.find((option) => option.value === selectedYear)}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => {
                                setSelectedYear(value);
                            }}
                            key={renderKey}
                        />
                    </div>
                </Filters>
            </Header>
            <div className="toggle-label">
                <ToggleRow>
                    <ToggleSwitch
                        isChecked={paymentSwitch}
                        onChange={() => {
                            setOrderType(paymentSwitch ? 'transfer' : 'payment');
                            setPaymentSwitch(!paymentSwitch);
                        }}
                        isDisabled={false}
                        offLabel={t('pages.transfers.payment_lists.payment').text}
                        onLabel={t('pages.transfers.payment_lists.transfer').text}
                    />
                    <div className="tooltip">
                        <Tooltip
                            text={tooltipText}
                            isHtml={true}
                            position="custom"
                            show={true}
                            customPositioning={
                                isTabletAndDown
                                    ? { top: '50%', right: '100%', transform: 'translateY(-50%)' }
                                    : { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
                            }
                            zIndex={10}
                            label={
                                <FontAwesomeIcon
                                    icon={faCircleQuestion}
                                    fontSize={'15px'}
                                    style={{ marginBottom: '0.05rem', marginLeft: '0.5rem' }}
                                    color={colors.purple}
                                />
                            }
                        />
                    </div>
                </ToggleRow>
                {showChekInput ? (
                    <DateWrapper isDarkTheme={isDarkTheme}>
                        {paidCheck ? (
                            <span className="text">{t('pages.transfers.payment_lists.paided').text}:</span>
                        ) : null}
                        <Input
                            type={InputComponentType.Date}
                            date={paymentDate}
                            onChange={(value: Date) => {
                                handleDateChange(value);
                            }}
                            inputClassName="payment-tax-date"
                            placeholder={t('pages.transfers.enter_date').text}
                        />
                        <div
                            onClick={() => {
                                if (!isChecked) return;
                                updateTaxCalendar(null);
                            }}
                            className="checkbox"
                        >
                            <FontAwesomeIcon
                                icon={isChecked ? faCheckSquare : faSquare}
                                className={`check-icon ${isChecked ? 'checked' : ''}`}
                            />
                        </div>
                    </DateWrapper>
                ) : null}
            </div>

            {isLoader ? (
                <SkeletonContainer>
                    <TransferOrderSkeleton />
                    <TransferOrderSkeleton />
                    <TransferOrderSkeleton />
                    <TransferOrderSkeleton />
                </SkeletonContainer>
            ) : (
                <>
                    {transferTemplates.length === 0 ? (
                        <NoItems
                            text={`${t('pages.transfers.no_tax_transfers').text} ${selectedYear}. ${
                                t('pages.transfers.payment_lists._year').text
                            }`}
                        />
                    ) : (
                        <>
                            <TransferOrderTemplateContainer>
                                {transferTemplates?.map((template: object, index: number) => {
                                    return (
                                        <TransferOrderTemplate
                                            key={index}
                                            template={template}
                                            onClick={() => {
                                                if (orderType === 'transfer') {
                                                    getQrCode(template);
                                                }
                                                setSelectedTransfer(template);
                                                setPreviewModal(true);
                                            }}
                                            isPaid={paidCheck}
                                        />
                                    );
                                })}
                            </TransferOrderTemplateContainer>
                        </>
                    )}
                </>
            )}
            <TaxSolutionContainer>
                <TaxSolutionComponent />
            </TaxSolutionContainer>
        </PageWrapper>
    );
};

interface DarkThemeProps {
    isDarkTheme?: boolean;
}

const PageWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 74vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow-y: auto;
        }
    }
    label {
        font-size: 14px;
        line-height: 34px;
    }
    .toggle-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            align-items: flex-start;
            flex-direction: column;
        }
    }
`;

const ToggleRow = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--gray);
        font-size: 15px;
    }
    .left {
        margin-right: 10px;
    }
    .right {
        margin-left: 10px;
    }
`;

const TaxSolutionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 100px 0px;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 0;
    }
    .tax-solution {
        margin: 10px;
    }
`;

const SkeletonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .skeleton {
        margin: 10px;
    }
`;

const Title = styled.div`
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    justify-content: flex-start;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const DateWrapper = styled.div<DarkThemeProps>`
    width: fit-content;
    margin-left: auto;
    display: flex;
    align-items: center;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
    .text {
        margin-right: 10px;
        margin-top: 5px;
        font-size: 18px;
    }

    .payment-tax-date::placeholder {
        color: ${({ isDarkTheme }) => (isDarkTheme ? 'white' : '#92A5AC')};
    }
    .checkbox {
        margin-left: 10px;
        cursor: pointer;
        .check-icon {
            border: 1px solid var(--purple);
            border-radius: 5px;
            font-size: 18px;
            color: var(--white);
        }
        .check-icon.checked {
            background-color: var(--purple);
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    margin: 1.5rem 0;
`;

interface DarkThemeProps {
    isDarkTheme?: boolean;
    transfers?: boolean;
}

const Header = styled.div<DarkThemeProps>`
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
        color: ${({ isDarkTheme }) => (isDarkTheme ? '#ffffff' : '#4C595F')};
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        column-gap: 1rem;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            flex-wrap: wrap;
            gap: 10px;
        }
        .button {
            width: ${({ transfers }) => (transfers ? '30%' : '45%')};
            @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                width: 45%;
            }
        }
    }
`;

const Filters = styled.div<DarkThemeProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--purple)')};
    color: white;
    vertical-align: middle;
    flex-wrap: wrap;
    height: 65px;
    gap: 1rem;
    @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
        height: auto;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.tablet}) and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.laptopM}) and (max-width: ${MQ_BREAKPOINTS.laptopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.desktop}) and (max-width: ${MQ_BREAKPOINTS.desktopL}) {
        height: 65px;
    }
    @media screen and (min-width: ${MQ_BREAKPOINTS.ultraWide}) {
        height: 65px;
    }
    .filter-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        .show-2 {
            padding: 0;
        }
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        // display: inline-block;
    }
    .filter {
        max-width: 100px;
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .filter-search {
        .show {
            margin-right: 10px;
        }
        display: flex;
        @media (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: flex-start;
        }
        align-items: center;
        margin-right: 10px;
        @media (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
        }
        label {
            display: inline-block;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }
    .search-filter {
        top: 78px;
        display: flex;
        gap: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
            gap: 10px;
        }
        @media (max-width: ${MQ_BREAKPOINTS.laptopM}) {
            margin-right: 0;
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
            width: auto;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
        .select-search {
            height: 44px;
        }
    }
    .select-year {
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
        color: var(--gray);
        width: fit-content;
    }
    .select-month {
        background-color: ${({ isDarkTheme }) => (isDarkTheme ? '#121212' : 'var(--white)')};
        color: var(--gray);
        width: 130px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select-items {
        background-color: var(--white);
        color: var(--gray);
        width: 80px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            float: none;
        }
    }
    .select__control {
        border-radius: 0px;
    }
    .second-title {
        font-size: 14px;
        line-height: 34px;
        min-width: 120px;
        text-transform: uppercase;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            font-size: 15px;
        }
    }
`;
export default TransferOrders;
