import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEye,
    faPrint,
    faSave,
    faTrashCan,
    faPlus,
    faA,
    faFile,
    faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonsBottom } from '../../style';
import Button, { ButtonVariant } from '../../../../../components/button';
import { ObjectKeys } from '../../../../../types/objectKeys';
import colors from '../../../../../global/colors';
import useTheme from '../../../../../hooks/useTheme';

interface Props {
    setPreviewModal: Function;
    setSendModal: Function;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
    handleCreateAdvanceFromProforma?: Function;
    name?: string;
    invoice?: ObjectKeys;
    handleViewInvoicePDF?: Function;
    issueInvoice?: Function;
}

const MobileFooter: FunctionComponent<Props> = ({
    setPreviewModal,
    setSendModal,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
    handleCreateAdvanceFromProforma,
    name,
    invoice,
    handleViewInvoicePDF,
    issueInvoice,
}) => {
    const role = localStorage.getItem('role');
    const isDarkTheme = useTheme();

    return (
        <ButtonsBottom>
            {invoice?.status === 'open' && invoice.object !== 'Quotation' && (
                <Button
                    onClick={() => issueInvoice && issueInvoice()}
                    variant={ButtonVariant.outlined}
                    color={isDarkTheme ? colors.purple : colors.white}
                    icon={true}
                    size={'auto'}
                    className="issuedAndDownload"
                >
                    <FontAwesomeIcon color={isDarkTheme ? colors.purple : colors.white} icon={faFile} />
                </Button>
            )}
            <Button
                onClick={() => handleDownloadInvoice()}
                variant={ButtonVariant.outlined}
                color={colors.lightBlue}
                icon={true}
                size={'auto'}
                className="issuedAndDownload"
            >
                <FontAwesomeIcon color={colors.lightBlue} icon={faDownload} />
            </Button>
            {role !== 'admin' ? (
                <>
                    <Button
                        onClick={() => handleViewInvoicePDF && handleViewInvoicePDF()}
                        variant={ButtonVariant.outlined}
                        color={colors.purple}
                        size={'auto'}
                        className="print-btn"
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    </Button>
                    <Button
                        onClick={() => setSendModal(true)}
                        variant={ButtonVariant.solid}
                        color={colors.blue}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    </Button>
                </>
            ) : (
                <></>
            )}
            <Button
                onClick={() => handleUpdateInvoice()}
                variant={ButtonVariant.solid}
                color={colors.green}
                size={'auto'}
            >
                <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
            </Button>

            {name === 'proforma' ? (
                <>
                    <Button
                        onClick={() => handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()}
                        variant={ButtonVariant.solid}
                        color={colors.green}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                    </Button>
                    <Button
                        onClick={() => handleCreateAdvanceFromProforma && handleCreateAdvanceFromProforma()}
                        variant={ButtonVariant.solid}
                        color={colors.brown}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faA} />
                    </Button>
                </>
            ) : (
                <></>
            )}
            {role !== 'admin' ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    size={'auto'}
                    onClick={() => setPreviewModal(true)}
                    className="preview-btn"
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                </Button>
            ) : (
                <></>
            )}
            {invoice?.id ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.danger}
                    size={'auto'}
                    onClick={() => setDeleteInvoiceModal(true)}
                    className="trash-btn"
                >
                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                </Button>
            ) : (
                <></>
            )}
        </ButtonsBottom>
    );
};

export default MobileFooter;
