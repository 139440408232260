import { Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useEmailsValidation from '../../../../hooks/useEmailsValidation';
import useVatNoValidation from '../../../../hooks/useVatNoValidation';
import useNumLengthValidation from '../../../../hooks/useNumLengthValidation';
import useCurrencyStore from '../../../../store/currency';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import SelectComponent from '../../../../components/select';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllClients from '../getAllClients';
import { toast } from 'react-toastify';

type Props = {
    t: Function;
    clickedClient: string;
    userId: string;
    setEditClientModal: Dispatch<SetStateAction<boolean>>;
    setClients: Dispatch<SetStateAction<ObjectKeys[]>>;
    tabIndex: number;
};

const EditClientModal: FunctionComponent<Props> = ({
    t,
    clickedClient,
    userId,
    setEditClientModal,
    setClients,
    tabIndex,
}) => {
    const { currentLang } = useLanguageStore();
    const { currency } = useCurrencyStore();
    const [formData, setFormData] = useState<any>({
        name: '',
        taxNumber: '',
        identificationNumber: '',
        phoneNumber: '',
        emails: [],
        address: '',
        city: '',
        state: '',
        clientCategory: '',
        personalId: null,
        vatNumber: '',
        jbkjs: null,
        currency: '',
    });
    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [clickedField, setClickedField] = useState('');
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [clientInfo, setClientInfo] = useState<any>({});

    const clientEmail = formData.email;
    const [showEmails, setShowEmails] = useState(true);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>(clientEmail ? [clientEmail] : []);
    const [showError, setShowError] = useState(false);

    const isNameValid = useCharLengthValidation(formData.name, 3, true);
    const isEmailValid = useEmailsValidation(formData.email, emails, false);
    const isAddressValid = useCharLengthValidation(
        formData.address,
        3,
        formData.clientCategory === 'civil' ? false : true,
    );
    const isCityValid = useCharLengthValidation(formData.city, 3, formData.clientCategory === 'civil' ? false : true);
    const isStateValid = useCharLengthValidation(formData.state, 3, true);
    const isVatNoValid = useVatNoValidation(formData.vatNumber);
    const isIdentificationNumValid = useNumLengthValidation(formData.identificationNumber, 8);
    const isJbkjsValid = useNumLengthValidation(formData.jbkjs, 5);
    const isPersonalIdValid = useNumLengthValidation(formData.personalId, 13);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    useEffect(() => {
        if (clickedClient) {
            communication.getClient(clickedClient).then((res: ObjectKeys) => {
                if (res) {
                    setClientInfo(res?.data?.data);
                    const clientData = res?.data?.data;
                    const client_emails = clientData?.clientEmails.data
                        ? clientData.clientEmails.data
                              .map((item: ObjectKeys) => item.email)
                              .filter((email: string) => email) // Collect emails
                        : [];
                    setEmails(client_emails);
                    setFormData({
                        name: clientData?.company_name ? clientData?.company_name : '',
                        taxNumber: clientData?.pib ? clientData?.pib : '',
                        identificationNumber: clientData?.identification_number
                            ? clientData?.identification_number
                            : '',
                        phoneNumber: clientData?.phone ? clientData?.phone : '',
                        email: '',
                        emails: client_emails,
                        address: clientData?.address ? clientData?.address : '',
                        city: clientData?.city ? clientData?.city : '',
                        state: clientData?.country ? clientData?.country : '',
                        personalId: clientData?.jmbg ? clientData?.jmbg : '',
                        vatNumber: clientData?.pib ? clientData?.pib : '',
                        jbkjs: clientData?.public_company_id ? clientData?.public_company_id : '',
                        currency: clientData?.currency ? clientData?.currency : 'EUR',
                        clientCategory: clientData?.type ? clientData?.type : 'civil',
                    });
                }
            });
        }
    }, [clickedClient]);

    const getValidation = (): any => {
        if (clientInfo.is_foreign !== 1) {
            if (clientInfo.type === 'civil') {
                const personalId = formData.personalId;
                if (
                    (isEmailValid === '' &&
                        isNameValid === '' &&
                        personalId.length > 0 &&
                        personalId.length === 13 &&
                        isAddressValid === '' &&
                        isCityValid === '') ||
                    (isEmailValid === '' &&
                        isNameValid === '' &&
                        personalId.length === 0 &&
                        isAddressValid === '' &&
                        isCityValid === '')
                ) {
                    return ''; // No validation errors
                }
            } else if (clientInfo.type === 'legal') {
                if (
                    isVatNoValid === '' &&
                    isIdentificationNumValid === '' &&
                    isNameValid === '' &&
                    isAddressValid === '' &&
                    isCityValid === '' &&
                    isEmailValid === ''
                ) {
                    return '';
                }
            } else if (clientInfo.type === 'public') {
                if (
                    isVatNoValid === '' &&
                    isJbkjsValid === '' &&
                    isIdentificationNumValid === '' &&
                    isNameValid === '' &&
                    isAddressValid === '' &&
                    isCityValid === '' &&
                    isEmailValid === ''
                ) {
                    return '';
                }
            }
        } else {
            if (
                isNameValid === '' &&
                isAddressValid === '' &&
                isCityValid === '' &&
                isStateValid === '' &&
                isEmailValid === ''
            ) {
                return '';
            }
        }
    };

    const changeClient = (): void => {
        setShowValidationMessage(true);

        if (getValidation() === '') {
            setEditClientModal(false);
            setShowValidationMessage(false);
            communication
                .editClient(clickedClient, {
                    address: formData.address,
                    city: formData.city,
                    company_name: formData.name,
                    country: clientInfo?.is_foreign === 1 ? formData?.state : 'RSD',
                    currency: clientInfo?.is_foreign === 1 ? formData?.currency : 'RSD',
                    // email: formData.email,
                    emails: emails,
                    identification_number: formData.identificationNumber,
                    is_foreign: clientInfo?.is_foreign ? true : false,
                    jmbg: formData.personalId,
                    phone: formData.phoneNumber,
                    pib: clientInfo?.is_foreign === 1 ? formData.taxNumber : formData.vatNumber,
                    public_company_id: formData.jbkjs,
                    type: clientInfo?.is_foreign === 1 ? null : formData.clientCategory,
                    user_id: userId,
                    created_at: clientInfo.created_at,
                    deleted_at: null,
                    object: clientInfo.object,
                    updated_at: new Date(),
                })
                .then((res: ObjectKeys) => {
                    if (res) {
                        toast.success(t('pages.clients.editSuccess').text);
                        setShowValidationMessage(false);
                        setEditClientModal(false);
                    }
                })
                .then(() => {
                    const is_foreign = clientInfo?.is_foreign ? true : false;
                    getAllClients(setClients, userId, tabIndex, is_foreign);
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        toast.error(t('pages.tryAgain.message').text);
                    }
                });
        }
    };

    return (
        <EditClientModalWrapper>
            <ModalText>
                <h4>
                    {t('pages.clients.editClientModal.title').text}{' '}
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: 'var(--gray)' }}
                        onClick={() => setEditClientModal(false)}
                    />
                </h4>
                <p>{t('pages.clients.editClientModal.description').text}</p>
            </ModalText>
            <Form>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.name').text}
                    validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                    value={formData.name ? formData.name : ''}
                    blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, name: value });
                    }}
                    maxLength={170}
                />
                {clientInfo?.is_foreign === 0 && (
                    <>
                        {clientInfo?.type !== 'civil' ? (
                            <Input
                                type={InputComponentType.Number}
                                label={t('pages.clients.modal.form.domestic.legal.vatNumber').text}
                                validation={showValidationMessage || clickedField === 'vatNumber' ? isVatNoValid : ''}
                                blurHandler={() => handleValidation('vatNumber')}
                                value={formData.vatNumber ? formData.vatNumber : ''}
                                onChange={(value: string) => {
                                    setFormData({ ...formData, vatNumber: value });
                                }}
                                maxLength={9}
                            />
                        ) : (
                            <Input
                                type={InputComponentType.Number}
                                label={t('pages.clients.modal.form.domestic.civil.personalId').text}
                                validation={
                                    formData.personalId.length < 13 && formData.personalId.length > 0
                                        ? isPersonalIdValid
                                        : ''
                                }
                                blurHandler={() => handleValidation('personalId')}
                                value={formData.personalId ? formData.personalId : ''}
                                onChange={(value: string) => {
                                    setFormData({ ...formData, personalId: value });
                                }}
                                maxLength={13}
                            />
                        )}
                    </>
                )}
                {clientInfo?.is_foreign === 1 && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.taxNumber').text}
                            value={formData.taxNumber ? formData.taxNumber : ''}
                            blurHandler={() => handleValidation('taxNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, taxNumber: value });
                            }}
                            maxLength={50}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                            value={formData.identificationNumber ? formData.identificationNumber : ''}
                            blurHandler={() => handleValidation('identificationNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, identificationNumber: value });
                            }}
                            maxLength={50}
                        />
                    </>
                )}
                {clientInfo?.type !== 'civil' && clientInfo?.is_foreign === 0 && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                        validation={
                            showValidationMessage || clickedField === 'identificationNumber'
                                ? isIdentificationNumValid
                                : ''
                        }
                        blurHandler={() => handleValidation('identificationNumber')}
                        value={formData.identificationNumber ? formData.identificationNumber : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, identificationNumber: value });
                        }}
                        maxLength={8}
                    />
                )}
                {clientInfo?.type === 'public' && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.public.jbkjs').text}
                        validation={showValidationMessage || clickedField === 'jbkjs' ? isJbkjsValid : ''}
                        blurHandler={() => handleValidation('jbkjs')}
                        value={formData.jbkjs ? formData.jbkjs : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, jbkjs: value });
                        }}
                        maxLength={5}
                    />
                )}
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.phoneNumber').text}
                    value={formData.phoneNumber ? formData.phoneNumber : ''}
                    blurHandler={() => handleValidation('phoneNumber')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, phoneNumber: value });
                    }}
                    maxLength={170}
                />
                <div>
                    <p className="label-class">{t('pages.clients.addClientModal.emails').text}</p>
                    {showEmails && emails.length > 0 ? (
                        <div className="emails">
                            {emails
                                .filter((email: string) => email !== '')
                                .map((email: string) => (
                                    <Email key={email}>
                                        {email}
                                        <span onClick={() => handleRemoveEmail(email)}>x</span>
                                    </Email>
                                ))}
                        </div>
                    ) : null}
                    <EmailFieldWrapper>
                        <Input
                            className="email"
                            type={InputComponentType.Text}
                            value={formData.email}
                            onChange={(value: string) => {
                                setFormData({ ...formData, email: value });
                            }}
                            onEnter={() => {
                                if (formData.email !== '' && isEmailValid === '') {
                                    setShowEmails(true);
                                    setEmails([...emails, formData.email]);
                                    setFormData({ ...formData, email: '' });
                                }
                            }}
                            validation={isEmailValid ? isEmailValid : ''}
                            handleBlurAction={() => {
                                setShowError(true);
                                if (emails.includes(formData.email)) {
                                    setFormData({ ...formData, email: '' });
                                    return;
                                }
                                if (formData.email !== '' && isEmailValid === '') {
                                    setShowEmails(true);
                                    setEmails([...emails, formData.email]);
                                    setFormData({ ...formData, email: '' });
                                }
                            }}
                            hideBlurOnChange
                            maxLength={170}
                        />
                    </EmailFieldWrapper>
                </div>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.address').text}
                    value={formData.address ? formData.address : ''}
                    validation={
                        showValidationMessage ||
                        clickedField === 'address' ||
                        ((showValidationMessage || clickedField === 'address') && clickedClient === 'foreign')
                            ? isAddressValid
                            : ''
                    }
                    blurHandler={() => handleValidation('address')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, address: value });
                    }}
                    maxLength={170}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.city').text}
                    value={formData.city ? formData.city : ''}
                    validation={
                        showValidationMessage ||
                        clickedField === 'city' ||
                        ((showValidationMessage || clickedField === 'city') && clickedClient === 'foreign')
                            ? isCityValid
                            : ''
                    }
                    blurHandler={() => handleValidation('city')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, city: value });
                    }}
                    maxLength={170}
                />
                {clientInfo?.is_foreign === 1 && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.state').text}
                            value={formData.state ? formData.state : ''}
                            validation={showValidationMessage || clickedField === 'state' ? isStateValid : ''}
                            blurHandler={() => handleValidation('state')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, state: value });
                            }}
                            maxLength={170}
                        />
                        <SelectComponent
                            className="select-container currency-select"
                            optionList={currencies}
                            value={formData.currency ? formData.currency : ''}
                            label={t('pages.clients.modal.form.foreign.currency').text}
                            handleSelectedValue={(value: string) => {
                                setFormData({ ...formData, currency: value });
                            }}
                        />
                    </>
                )}
            </Form>
            <ButtonWrapper>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'130px'}
                    onClick={() => {
                        changeClient();
                    }}
                >
                    {t('buttons.save').text}
                </Button>
            </ButtonWrapper>
        </EditClientModalWrapper>
    );
};

const EditClientModalWrapper = styled.div`
    .select-wrapper {
        width: 100%;
        margin-bottom: 20px;
        label {
            font-size: 15px;
            color: var(--gray);
            margin-bottom: 2px;
            display: flex;
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--black);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
        div {
            font-size: 15px;
            color: var(--gray);
            .select__menu-list {
                .select__option {
                    &:hover {
                        color: var(--white);
                        background: var(--purple);
                    }
                    &.select__option--is-selected {
                        color: var(--white);
                        background: var(--purple);
                        border-radius: 0;
                    }
                }
                .select__option:last-child {
                    border-bottom: none;
                }
            }
        }
    }
`;

const ModalText = styled.div`
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
    }
`;

const EmailFieldWrapper = styled.div`
    .validation-message {
        color: var(--danger);
        font-size: 12px;
    }
`;

const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;

const Form = styled.div`
    margin-top: 30px;
    .label-class {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
            cursor: default;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`;
export default EditClientModal;
