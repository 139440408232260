import { FunctionComponent, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import useTheme from '../../hooks/useTheme';

interface Props {
    close: Function;
    action?: Function;
    message?: string;
    seconds?: number;
    confirmText?: string;
    doneText?: string;
    className?: string;
}

const IntervalLoaderModal: FunctionComponent<Props> = ({
    close,
    action,
    message,
    seconds = 30,
    confirmText,
    doneText,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const isDarkTheme = useTheme();

    const [loadingDone, setLoadingDone] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingDone(true);
        }, seconds * 1000);
        return () => clearTimeout(timer);
    }, [seconds]);

    return (
        <>
            {message && !loadingDone ? (
                <TextContainer>
                    <h3>{message}</h3>
                </TextContainer>
            ) : (
                <TextContainer>
                    <h3>{doneText}</h3>
                </TextContainer>
            )}
            {!loadingDone && (
                <LoaderContainer>
                    <ProgressBar seconds={seconds} />
                </LoaderContainer>
            )}

            <ButtonContainer isDarkTheme={isDarkTheme}>
                {loadingDone ? (
                    <Button
                        variant={ButtonVariant.solid}
                        color={'var(--purple)'}
                        onClick={() => {
                            if (action) action();
                            close();
                        }}
                        size={'100%'}
                    >
                        {t('input.ok')?.text || 'OK'}
                    </Button>
                ) : (
                    <Button
                        variant={ButtonVariant.outlined}
                        color={'var(--gray)'}
                        onClick={() => close()}
                        size={'100%'}
                    >
                        {t('input.hide')?.text || 'Sakrij'}
                    </Button>
                )}
            </ButtonContainer>
        </>
    );
};

export default IntervalLoaderModal;

// Styles

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div<{ isDarkTheme?: boolean }>`
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: center;
    button {
        border: none;
        border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? 'var(--purple)' : 'var(--gray)')};
    }
    margin-bottom: 20px;
`;

const LoaderContainer = styled.div`
    width: 80%;
    height: 10px;
    margin: 20px auto 30px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
`;

const progressAnimation = (seconds: number) => keyframes`
    from { width: 0%; }
    to { width: 100%; }
`;

const ProgressBar = styled.div<{ seconds: number }>`
    height: 100%;
    background-color: var(--purple);
    animation: ${({ seconds }) => progressAnimation(seconds)} ease-in-out ${({ seconds }) => seconds}s forwards;
`;
