import { FunctionComponent, useState, MouseEvent, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import srLatn from 'date-fns/locale/sr-Latn';
import enUS from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import styled from 'styled-components';
import useLanguageStore from '../../store/language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { breakpoints, MQ_BREAKPOINTS } from '../../constants/breakpoints';
import useTranslations from '../../hooks/useTranslation';

export enum InputComponentType {
    Date = 'date',
    Password = 'password',
    Text = 'text',
    Number = 'number',
    TextArea = 'textarea',
    Amount = 'amount',
    eInvoiceNo = 'eInvoiceNo',
    Search = 'Search',
}
interface Props {
    type?: string;
    id?: string;
    className?: string;
    inputClassName?: string;
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: Function;
    onEnter?: Function;
    readOnly?: boolean;
    validation?: string;
    label?: string;
    isDisabled?: boolean;
    pattern?: string;
    max?: number;
    maxLength?: number;
    infoMessage?: string;
    minDate?: Date;
    maxDate?: Date;
    date?: Date | undefined | null;
    isClearable?: boolean;
    blurHandler?: Function;
    hideBlurOnChange?: boolean;
    children?: any;
    handleBlurAction?: Function;
    customOnBlur?: () => void;
    negative?: boolean;
    dataCy?: string;
    limitDecimal?: number;
    autoFill?: boolean;
    darkTheme?: boolean;
    isTextAreaSpellCheckAllowed?: boolean;
    ref?: React.Ref<HTMLInputElement>;
    datepickerPosition?: 'top' | 'bottom';
}

const Input: FunctionComponent<Props> = ({
    type,
    id,
    value,
    name,
    className,
    inputClassName,
    placeholder,
    onChange = () => {
        return;
    },
    onEnter = () => {
        return;
    },
    readOnly,
    validation,
    label,
    isDisabled,
    pattern,
    max,
    maxLength,
    infoMessage,
    minDate,
    maxDate,
    date,
    isClearable = false,
    blurHandler,
    hideBlurOnChange,
    children,
    handleBlurAction,
    customOnBlur,
    negative,
    dataCy,
    limitDecimal,
    darkTheme,
    autoFill,
    isTextAreaSpellCheckAllowed = false,
    ref,
    datepickerPosition = 'bottom',
}) => {
    const [isChange, setChange] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [val, setValue] = useState(value || '');

    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const keyStrokeHandler: React.KeyboardEventHandler<HTMLInputElement> = (
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (e.key === 'Enter') {
            setChange(!isChange);
            onEnter();
        }
    };

    const togglePassword = (e: MouseEvent<HTMLDivElement>): void => {
        if (value !== '') {
            setPasswordVisible(!passwordVisible);
        } else {
            e.preventDefault();
        }
    };

    const datePickerRef = useRef<any>(null);

    const setDateToToday = (): void => {
        const initialDate = new Date().toISOString().split('T')[0];
        const convertedDate = new Date(initialDate);

        onChange(convertedDate);
        if (blurHandler) blurHandler();

        if (datePickerRef.current) {
            datePickerRef.current.setOpen(false);
        }
    };

    const inputByType = (): JSX.Element => {
        switch (type) {
            case InputComponentType.Date:
                return (
                    <StyledDateContainer>
                        <div
                            className={`date-container ${
                                validation !== undefined && validation !== '' ? 'validation-error' : ''
                            }`.trim()}
                        >
                            {label ? (
                                <span className="date-label" ref={ref}>
                                    {label}
                                </span>
                            ) : (
                                <></>
                            )}
                            <div className="datepicker-input-wrapper">
                                <StyledDate
                                    ref={datePickerRef}
                                    className={`${inputClassName ?? ''}`}
                                    disabled={isDisabled}
                                    selected={date}
                                    isClearable={isClearable}
                                    readOnly={readOnly}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dateFormat="dd MMM yyyy"
                                    popperModifiers={[
                                        {
                                            name: 'flip',
                                            options: {
                                                fallbackPlacements: [],
                                            },
                                        },
                                    ]}
                                    locale={
                                        currentLangName === 'Srpski'
                                            ? srLatn
                                            : currentLangName === 'English'
                                            ? enUS
                                            : currentLangName === 'Русский'
                                            ? ru
                                            : ''
                                    }
                                    onChange={(dateToChange: Date) => {
                                        onChange(dateToChange);
                                        if (blurHandler) blurHandler();
                                    }}
                                    onFocus={() => {
                                        if (blurHandler) blurHandler();
                                    }}
                                    onKeyDown={(e) => {
                                        // Prevent any keyboard input
                                        e.preventDefault();
                                    }}
                                    shouldCloseOnSelect={true}
                                    placeholderText={placeholder}
                                    {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                                    popperPlacement={datepickerPosition}
                                >
                                    <button className="todayButton" onClick={setDateToToday}>
                                        {t('buttons.today').text}
                                    </button>
                                </StyledDate>
                                <FontAwesomeIcon icon={faCalendarDays} style={{ color: 'var(--gray)' }} />
                            </div>
                            {validation ? (
                                <p className="validation-message">{validation}</p>
                            ) : (
                                (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                            )}
                        </div>
                    </StyledDateContainer>
                );
            case InputComponentType.Password:
                return (
                    <StyledInputContainer className={`input-container ${validation ? 'validation-error' : ''}`.trim()}>
                        {label ? <label>{label}</label> : <></>}
                        <div className="input-with-icon">
                            <input
                                onChange={(e) => {
                                    setValue(e.target.value || val);
                                    onChange(e.target.value);
                                    if (blurHandler) blurHandler();
                                }}
                                className={`${inputClassName ?? ''}`}
                                value={value}
                                disabled={isDisabled}
                                id={id}
                                type={passwordVisible ? 'text' : 'password'}
                                name={name}
                                placeholder={placeholder}
                                onKeyUp={keyStrokeHandler}
                                readOnly={readOnly}
                                pattern={pattern}
                                max={max}
                                maxLength={maxLength}
                                onFocus={() => {
                                    if (blurHandler) blurHandler();
                                }}
                                // autoComplete={autoFill ? 'on' : 'new-password'}
                                {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                            />
                            <div onClick={(e) => togglePassword(e)} className="cursor-pointer eye-icon">
                                {passwordVisible ? (
                                    <FontAwesomeIcon icon={faEyeSlash} style={{ color: 'var(--black)' }} />
                                ) : (
                                    <FontAwesomeIcon icon={faEye} style={{ color: 'var(--black)' }} />
                                )}
                            </div>
                        </div>
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            case InputComponentType.TextArea:
                return (
                    <StyledInputContainer
                        ref={ref}
                        className={`input-container ${validation ? 'validation-error' : ''}`.trim()}
                    >
                        {label ? <label>{label}</label> : <></>}
                        <textarea
                            onChange={(e) => {
                                setValue(e.target.value || val);
                                onChange(e.target.value);
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            className={`${inputClassName ?? ''}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                            spellCheck={isTextAreaSpellCheckAllowed}
                        />
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            case InputComponentType.Amount:
                return (
                    <StyledInputContainer className={`input-container ${validation ? 'validation-error' : ''}`.trim()}>
                        {label ? <label>{label}</label> : <></>}
                        <input
                            onChange={(e) => {
                                let inputValue = e.target.value;
                                // Allow negative numbers if 'negative' is true
                                if (negative && inputValue.startsWith('-')) {
                                    // Remove any non-digit, non-comma, and non-minus sign characters except for the first comma and minus sign
                                    inputValue = inputValue.replace(/[^0-9,-]|(?<=,-.*),/g, '');
                                    if (inputValue.indexOf('-', 1) !== -1) {
                                        inputValue = '-' + inputValue.replace(/-/g, '');
                                    }
                                    // Ensure the comma is not at the beginning or end
                                    if (inputValue.endsWith(',')) {
                                        setValue(inputValue.slice(0, -1));
                                    } else {
                                        setValue(inputValue);
                                    }
                                } else {
                                    // Remove any non-digit and non-comma characters except for the first comma
                                    inputValue = inputValue.replace(/[^0-9,]|(?<=,.*),/g, '');
                                    if (inputValue.startsWith(',')) {
                                        return; // Don't update the value if a comma is at the beginning
                                    }
                                    // Ensure the comma is not at the beginning or end
                                    if (inputValue.endsWith(',')) {
                                        setValue(inputValue.slice(0, -1));
                                    } else {
                                        setValue(inputValue);
                                    }
                                }
                                // Limit decimal places based on limitDecimal variable
                                if (limitDecimal !== undefined && limitDecimal > 0) {
                                    const decimalMatch = inputValue.match(/,(\d*)$/);
                                    if (decimalMatch && decimalMatch[1].length > limitDecimal) {
                                        inputValue = inputValue.replace(
                                            new RegExp(`,(\\d{${limitDecimal}})\\d+$`),
                                            ',$1',
                                        );
                                    }
                                }

                                onChange(inputValue);
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            className={`${inputClassName ?? ''}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                        />
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            case InputComponentType.eInvoiceNo:
                return (
                    <StyledInputContainer
                        ref={ref}
                        className={`input-container ${validation ? 'validation-error' : ''}`.trim()}
                    >
                        {label ? <label>{label}</label> : <></>}
                        <input
                            onChange={(e) => {
                                let inputValue = e.target.value;
                                // Allow only SEF-approved characters: letters, numbers, and minus (-), excluding '/' and '.'
                                inputValue = inputValue.replace(/[^a-zA-Z0-9-]/g, '');

                                // Ensure the value is updated only if valid characters are entered
                                setValue(inputValue);

                                onChange(inputValue);
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            className={`${inputClassName ?? ''}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            readOnly={readOnly}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                        />
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            case InputComponentType.Number:
                return (
                    <StyledInputContainer
                        ref={ref}
                        className={`input-container ${validation ? 'validation-error' : ''}`.trim()}
                    >
                        {label ? <label className="font15">{label}</label> : <></>}
                        <input
                            onChange={(e) => {
                                if (type === 'number') {
                                    setValue(e.target.value.slice(0, maxLength));
                                    onChange(e.target.value.slice(0, maxLength));
                                } else {
                                    setValue(e.target.value || val);
                                    onChange(e.target.value);
                                }
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            onKeyDown={(e) => {
                                if (type === 'number') {
                                    // Check if the key pressed is not a number key or a valid control key
                                    if (
                                        !/^\d$/.test(e.key) &&
                                        !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'v'].includes(
                                            e.key,
                                        ) &&
                                        !(e.ctrlKey || e.metaKey)
                                    ) {
                                        e.preventDefault();
                                    }
                                }
                            }}
                            onPaste={(e) => {
                                const pastedData = e.clipboardData.getData('Text');
                                if (!/^\d+$/.test(pastedData)) {
                                    e.preventDefault();
                                }
                            }}
                            onWheel={(event) => event.currentTarget.blur()}
                            className={`${inputClassName ?? ''}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            onKeyUp={keyStrokeHandler}
                            readOnly={readOnly}
                            pattern={pattern}
                            max={max}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            onBlur={() => {
                                if (customOnBlur) {
                                    customOnBlur();
                                }
                                handleBlurAction && handleBlurAction();
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                        />
                        {children ? children : ''}
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            case InputComponentType.Search:
                return (
                    <StyledInputContainer
                        className={`input-container ${validation ? 'validation-error' : ''}`.trim()}
                        darkTheme={darkTheme}
                        ref={ref}
                    >
                        {label ? <label>{label}</label> : <></>}
                        <input
                            type="text" // Use search type
                            onChange={(e) => {
                                setValue(e.target.value || val);
                                onChange(e.target.value);
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            className={`searchInput ${!passwordVisible && 'searchStyles'}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            name="dsadasdsa"
                            placeholder={placeholder}
                            readOnly={readOnly}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            autoComplete="off"
                            autoCorrect="off"
                            spellCheck="false"
                            inputMode="text"
                            style={{
                                appearance: 'none', // Removes the search icon
                                WebkitAppearance: 'none', // Removes the search icon for Webkit-based browsers
                                MozAppearance: 'none', // Removes the search icon for Firefox
                                paddingRight: '0', // Removes extra padding added for the clear button
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                        />
                        <div onClick={(e) => togglePassword(e)} className="eye-icon-searchField">
                            {passwordVisible ? (
                                <FontAwesomeIcon icon={faEyeSlash} style={{ color: 'var(--black)' }} />
                            ) : (
                                <FontAwesomeIcon icon={faEye} style={{ color: 'var(--black)' }} />
                            )}
                        </div>
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
            default:
                return (
                    <StyledInputContainer
                        className={`input-container ${validation ? 'validation-error' : ''}`.trim()}
                        darkTheme={darkTheme}
                        ref={ref}
                    >
                        {label ? <label className="font15">{label}</label> : <></>}
                        <input
                            onChange={(e) => {
                                if (type === 'number') {
                                    setValue(e.target.value.slice(0, maxLength));
                                    onChange(e.target.value.slice(0, maxLength));
                                } else {
                                    setValue(e.target.value || val);
                                    onChange(e.target.value);
                                }
                                blurHandler && !hideBlurOnChange && blurHandler();
                            }}
                            onKeyDown={(e) => {
                                if (type === 'number') {
                                    // Check if the key pressed is not a number key or a valid control key
                                    if (
                                        !/^\d$/.test(e.key) &&
                                        !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'v'].includes(
                                            e.key,
                                        ) &&
                                        !(e.ctrlKey || e.metaKey)
                                    ) {
                                        e.preventDefault();
                                    }
                                }
                            }}
                            onWheel={(event) => event.currentTarget.blur()}
                            className={`${inputClassName ?? ''}`}
                            value={value}
                            disabled={isDisabled}
                            id={id}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            onKeyUp={keyStrokeHandler}
                            readOnly={readOnly}
                            pattern={pattern}
                            max={max}
                            maxLength={maxLength}
                            onFocus={() => {
                                if (blurHandler) blurHandler();
                            }}
                            onBlur={() => {
                                if (customOnBlur) {
                                    customOnBlur();
                                }
                                handleBlurAction && handleBlurAction();
                            }}
                            {...(dataCy && { 'data-cy': dataCy })} // Conditionally add data-cy
                        />
                        {children ? children : ''}
                        {validation ? (
                            <p className="validation-message">{validation}</p>
                        ) : (
                            (infoMessage && <p className="info-message">{infoMessage}</p>) || <></>
                        )}
                    </StyledInputContainer>
                );
        }
    };

    return <StyledWrapper className={`input ${className ?? ''}`}>{inputByType()}</StyledWrapper>;
};

const StyledWrapper = styled.div<Props>`
    display: flex;
    justify-content: space-between;
    &.bordered {
        border: 1px solid var(--border-color);
        transition: opacity 0.4s ease-out;
        border-radius: 0;
        line-height: 2em;
        outline: none;
        border-radius: 2px;
        height: 34px;
        font-size: 14px;
    }
    &.address-name {
        width: 80%;
        margin-right: 20px;
    }
    &.address-num {
        width: 20%;
    }
    &.bank-acc-num-1,
    &.bank-acc-num-3 {
        width: 20%;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 15%;
        }
    }
    &.bank-acc-num-2 {
        width: 60%;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            width: 55%;
        }
    }
`;

const StyledDate = styled(DatePicker)`
    border: none;
    font-family: inherit;
    font-size: 14px;
    position: relative;
`;

const StyledInputContainer = styled.div<Props>`
    position: relative;

    .searchInput {
        position: relative;
    }

    .eye-icon-searchField {
        position: absolute;
        right: 5px;
        bottom: 25px;
        cursor: pointer;

        @media screen and (min-width: ${breakpoints.tablet}px) {
            bottom: 5px;
        }
    }

    .searchStyles {
        -webkit-text-security: disc;
    }

    .font16 {
        font-size: 15px;
    }
    .font15 {
        color: ${({ darkTheme }) => (darkTheme ? 'white' : '#92A5AC')};
    }
    .payment-tax-date {
        margin-bottom: -8px;
    }
    .requests-datepicker {
        background-color: var(--white);
        padding-right: 10px;
        color: var(--black);
    }
    .nbs-datepicker {
        color: var(--black) !important;
        cursor: pointer;
        font-size: 14px;
    }
    .react-datepicker__close-icon::after {
        background-color: var(--gray);
    }
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
        }
    }
    input {
        .find {
            padding-left: 15px;
        }
        font-family: inherit;
        background: transparent;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        border-radius: 0;
        padding: 11px;
        border: none;
        border-bottom: 1px solid var(--border-color);
        height: 34px;
        padding: 5px;
        transition: all 0.5s ease-in-out;
        color: var(--black);
        &:focus {
            outline: none;
            border-bottom: 1px solid var(--purple);
        }
        &::placeholder {
            color: var(--gray);
            font-weight: 400;
        }
        &:hover {
            border-bottom: 1px solid var(--purple);
        }
        &.bordered {
            border: 1px solid var(--purple);
            transition: opacity 0.4s ease-out;
            border-radius: 0;
            line-height: 2em;
            outline: none;
            border-radius: 2px;
            height: 34px;
            font-size: 14px;
            &:hover {
                border-bottom: 1px solid var(--purple);
            }
        }
    }
    .input-with-icon {
        display: flex;
        justify-content: space-between;
        position: relative;
        input {
            width: 100%;
        }
        .eye-icon {
            position: absolute;
            right: 5px;
            top: 8px;
        }
    }
    .validation-message {
        color: var(--danger);
        font-size: 12px;
        margin-top: 2px;
    }
    .info-message {
        font-size: 12px;
        color: var(--gray);
    }
    &.validation-error {
        input {
            color: var(--danger);
            border-bottom: 1px solid var(--danger);
            &:focus {
                outline: none;
            }
        }
    }
    &.bordered {
        margin-bottom: unset;
        display: unset;
        input {
            color: var(--gray);
            border-bottom: unset;
            padding: 5px 5px 5px 10px;
            width: 100%;

            &:focus {
                border-bottom: unset;
            }
        }
    }
`;
const StyledDateContainer = styled(StyledInputContainer)<Props>`
    width: 100%;
    text-transform: capitalize;
    .date-container {
        span {
            &.date-label {
                font-size: 15px;
                color: var(--gray);
            }
        }
        &.validation-error {
            .datepicker-input-wrapper {
                border-bottom: 1px solid var(--danger);
            }
        }
        .datepicker-input-wrapper {
            .react-datepicker {
                height: 335px;
                background-color: var(--moonlight);
            }
        }

        .todayButton {
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            width: 50%;
            background-color: var(--purple);
            outline: none;
            padding: 5px;
            border: 1px solid var(--purple);
            cursor: pointer;
            color: #ffffff;
            transition: opacity 0.2s ease;
            border-radius: 0;
        }

        .todayButton:hover {
            opacity: 0.7;
        }
    }
    .datepicker-input-wrapper .react-datepicker-wrapper {
        width: 100%;
    }
    .datepicker-input-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid var(--border-color);
        border-radius: 0;
        align-items: center;
        cursor: pointer;
        input {
            outline: none;
            padding: 0;
            box-sizing: border-box;
            border-radius: 0;
            color: var(--black);
            padding: 5px;
            border: none;
            width: 100%;
        }
        .react-datepicker-popper {
            padding-top: 0;
        }
        .react-datepicker {
            border: 1px solid var(--border-color);
            border-radius: 0;
            height: 273px;
            .react-datepicker__triangle {
                &:before,
                &:after {
                    display: none;
                }
            }
            .react-datepicker__navigation {
                span {
                    &:before {
                        border-color: var(--black);
                    }
                }
            }
            .react-datepicker__header {
                background: var(--moonlight);
                border-bottom: 1px solid var(--border-color);
                border-radius: 0;
                .react-datepicker__current-month {
                    color: var(--adjust);
                    font-weight: normal;
                }
                .react-datepicker__day-name {
                    color: var(--adjust);
                }
            }
            .react-datepicker__header__dropdown {
                margin: 10px 0;
                .react-datepicker__month-dropdown-container {
                    color: var(--adjust);
                    .react-datepicker__month-read-view--down-arrow {
                        top: 3px;
                        border-color: var(--black);
                    }
                    .react-datepicker__month-option {
                        &:hover {
                            background: var(--light-gray);
                            color: var(--gray);
                        }
                    }
                }
                .react-datepicker__year-dropdown-container {
                    color: var(--adjust);
                    .react-datepicker__navigation--years {
                        &::before {
                            border-color: #666;
                            border-style: solid;
                            border-width: 3px 3px 0 0;
                            content: '';
                            display: block;
                            height: 9px;
                            left: 11px;
                            position: absolute;
                            width: 9px;
                        }
                    }
                    .react-datepicker__navigation--years-upcoming {
                        &::before {
                            top: 17px;
                            transform: rotate(315deg);
                        }
                    }

                    .react-datepicker__navigation--years-previous {
                        &::before {
                            top: 6px;
                            transform: rotate(135deg);
                        }
                    }
                    .react-datepicker__year-read-view--down-arrow {
                        top: 3px;
                        border-color: var(--black);
                    }
                    .react-datepicker__year-option {
                        &:hover {
                            background: var(--light-gray);
                            color: var(--gray);
                        }
                    }
                }
                .react-datepicker__year-dropdown,
                .react-datepicker__month-dropdown {
                    border: 1px solid var(--border-color);
                    background: var(--white);
                    color: var(--adjust);
                }
            }
            .react-datepicker__month {
                background-color: var(--moonlight);
                .react-datepicker__week {
                    background-color: var(--moonlight);
                    .react-datepicker__day {
                        color: var(--adjust);
                        font-weight: normal;
                        border-radius: 0;
                        &:hover {
                            background: var(--light-gray);
                            color: var(--gray);
                        }
                    }
                    .react-datepicker__day--selected,
                    .react-datepicker__day--keyboard-selected {
                        background: var(--purple);
                        color: var(--white);
                    }
                }
            }
        }
    }
`;
export default Input;
